<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Details</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <div class="wrapper" *ngIf="towDetails">
        <div class="icon">
            <ion-avatar *ngIf="!displayDefaultPhoto; else defaultDriverPhoto">
                <img [src]="towDetails.tow.user.photo" (error)="onPhotoError()" referrerpolicy="no-referrer">
            </ion-avatar>
            <ng-template #defaultDriverPhoto>
                <mat-icon>person</mat-icon>
            </ng-template>
            <div class="name">{{ towDetails.tow.user.firstName }} {{ towDetails.tow.user.lastName }}</div>
        </div>

        <div class="summary">
            <div class="icon-type">
                <i *ngIf="+towDetails.tow.trailer.icon === 7; else icon" class="material-symbols-outlined">weight</i>
                <ng-template #icon>
                    <ion-icon [src]="'/assets/svg/trailer-' + towDetails.tow.trailer.icon + '.svg'"></ion-icon>
                </ng-template>
                <div>{{ towDetails.tow.trailer.type }}</div>
            </div>

            @if (towDetails.tow.trailer.description) {
                <div class="description">{{ towDetails.tow.trailer.description }}</div>
            }

            <div class="weight-length">{{ towDetails.tow.trailer | trailerSummary }}</div>
            {{ towDetails.tow.datetime | date:'M/d/y h:mm a' }}

            <div class="link instructions" fill="clear" *ngIf="towDetails.tow.instructions" (click)="onShowInstructions()">
                Instructions
            </div>
        </div>

        <app-tow-locations [driverAddress]="towDetails.start.address" [start]="towDetails.distancesDurations.tow.start" [destination]="towDetails.destination"
            [isHome]="isDriverHomeAddress"></app-tow-locations>

        <app-tow-distances-durations class="distance-duration" [distancesDurations]="towDetails.distancesDurations"></app-tow-distances-durations>

        <div class="cost">
            <label>Total</label>
            <div class="success">
                <span>$</span>{{ towDetails.payment.payment | number:'1.2-2' }}
            </div>
        </div>

        <div class="status" [ngSwitch]="towDetails.towRequest?.status">
            <ng-container *ngSwitchCase="0"> <!-- Pending -->
                <ion-button expand="block" color="success" [disabled]="disableActionButton$ | async" (click)="onChangeTowRequestStatus(1)">
                    Accept
                </ion-button>
                <ion-button expand="block" color="danger" (click)="onChangeTowRequestStatus(2)">
                    Decline
                </ion-button>
            </ng-container>
            <ion-button expand="block" *ngSwitchCase="1" fill="outline" color="success"> <!-- Accepted -->
                Accepted
            </ion-button>
            <ion-button expand="block" *ngSwitchCase="2" fill="outline" color="danger"> <!-- Declined -->
                Declined
            </ion-button>
            <ion-button expand="block" *ngSwitchCase="3" fill="outline" color="success"> <!-- Approved -->
                Approved
            </ion-button>
            <ion-button expand="block" *ngSwitchCase="4" fill="outline" color="danger"> <!-- Rejected -->
                Rejected
            </ion-button>
            <ng-container *ngSwitchDefault>
                <ng-container [ngSwitch]="towDetails.tow.status">
                    <ion-button *ngSwitchCase="0" expand="block" color="primary" [disabled]="towDetails.towRequest?.status === 6 || (disableActionButton$ | async)" (click)="onAvailable()"> <!-- Pending -->
                        Available for Pickup
                    </ion-button>
                    <ion-button *ngSwitchCase="1" expand="block" fill="outline" color="danger"> <!-- Canceled -->
                        Canceled
                    </ion-button>
                    <ion-button *ngSwitchCase="[2, 3, 9, 4, 5].includes(towDetails.tow.status) ? towDetails.tow.status : -1" expand="block" fill="outline" color="medium"> <!-- Approved/DriverOnWay/PickedUp/TrailerOnWay/Delivered -->
                        In Progress
                    </ion-button>
                    <ion-button *ngSwitchDefault expand="block" fill="outline" color="success"> <!-- Completed/Rated/Archived -->
                        Completed
                    </ion-button>
                </ng-container>
            </ng-container>
            <div *ngIf="towDetails.towRequest?.status === 1" class="message">
                Waiting for {{ towDetails.tow.user.firstName }} {{ towDetails.tow.user.lastName }}
                to approve your {{ towDetails.tow.trailer.type === 'Cargo' ? 'cargo' : 'tow' }}...
            </div>
            <!-- Show "Cancel" link when status is Accepted, Approved, or Available and tow status is Pending or Started -->
            <div *ngIf="[1, 3, 6].includes(towDetails.towRequest?.status ?? 0) && [0, 2].includes(towDetails.tow.status ?? 0)" class="link" (click)="onCancelTowRequest()">
                Cancel
            </div>
        </div>
    </div>
</ion-content>
