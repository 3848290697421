<ion-item>
    <ion-avatar slot="start" *ngIf="!displayDefaultDriverPhoto; else defaultDriverPhoto" [class.active]="towRequest.driverDetails.user.driver?.active">
        <img [src]="towRequest.driverDetails.user.photo" (error)="onDriverPhotoError(towRequest.id)" referrerpolicy="no-referrer">
    </ion-avatar>
    <ng-template #defaultDriverPhoto>
        <mat-icon slot="start" [class.active]="towRequest.driverDetails.user.driver?.active">person</mat-icon>
    </ng-template>

    <ion-label>
        {{ towRequest.driverDetails.vehicle | vehicleSummary }}<br>
        <strong>TWA {{ towRequest.driverDetails.vehicle.towingCapacity | number }} lbs</strong>
        <div class="rating">
            <app-no-rating *ngIf="!towRequest.driverDetails.user.driver?.averageRating; else ratingComponent"></app-no-rating>
            <ng-template #ratingComponent>
                <app-rating *ngIf="towRequest.driverDetails.user.driver?.averageRating" [rating]="towRequest.driverDetails.user.driver?.averageRating ?? 0"></app-rating>
            </ng-template>
            <div class="link" (click)="onViewRatings()">{{ towRequest.driverDetails.user.driver?.averageRating | number:'1.0-2' }}</div>
        </div>
    </ion-label>

    <div slot="end" class="btns">
        <div class="cost success">
            <span>$</span>{{ towRequest.driverDetails.payment.cost | number:'1.2-2' }}
        </div>
        <div [ngSwitch]="towRequest.status">
            <div *ngSwitchCase="0" class="pending"> <!-- Pending -->
                <ion-button color="medium">
                    Pending
                </ion-button>
                <div class="link" (click)="onCancelTowRequest()">
                    Cancel
                </div>
            </div>
            <ng-container *ngSwitchCase="1"> <!-- Accepted -->
                <ion-button color="success" (click)="onApproveTowRequest()">
                    Approve
                </ion-button>
            </ng-container>
            <div *ngSwitchCase="2"> <!-- Declined -->
                <ion-button fill="outline" color="danger">
                    Declined
                </ion-button>
            </div>
            <div *ngSwitchCase="3"> <!-- Approved -->
                <ion-button fill="outline" color="success">
                    Approved
                </ion-button>
            </div>
            <div *ngSwitchCase="4"> <!-- Rejected -->
                <ion-button fill="outline" color="danger">
                    Rejected
                </ion-button>
            </div>
            <div *ngSwitchCase="6"> <!-- Available -->
                <ion-button color="success" (click)="onApproveTowRequest()">
                    Approve
                </ion-button>
            </div>
            <div *ngSwitchCase="7"> <!-- Canceled -->
                <ion-button fill="outline" color="danger">
                    Canceled
                </ion-button>
            </div>
        </div>
    </div>
</ion-item>
