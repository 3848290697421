import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ListHelper } from '@classes/list-helper';
import { ObjectHelper } from '@classes/object-helper';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { ModalController } from '@ionic/angular';
import { SortOption } from '@models/sort-option.model';
import { TowRequest } from '@models/tow-request.model';
import { Tow } from '@models/tow.model';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable } from 'rxjs';

const DEFAULT_TOW_REQUESTS_SORT: SortOption = {
    field: 'driverDetails.payment.payment',
    direction: 'desc',
    name: 'Sort by Pay'
};

@Component({
    selector: 'app-driver-map-tow-requests',
    templateUrl: './driver-map-tow-requests.component.html',
    styleUrls: ['./driver-map-tow-requests.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverMapTowRequestsComponent implements OnInit {
    @Input() towRequests$!: Observable<TowRequest[]>;
    @Input() activeTow$!: Observable<Tow | undefined>;
    @Input() viewTowRequest!: EventEmitter<TowRequest>;
    @Input() cancelTowRequest!: EventEmitter<TowRequest>;

    private towRequests!: TowRequest[];
    private towRequestsSort$ = new BehaviorSubject<SortOption>(DEFAULT_TOW_REQUESTS_SORT);

    sortedTowRequests$!: Observable<TowRequest[]>;
    displayDefaultPhoto = new Map<string, boolean>();
    towRequestsSortOptions: SortOption[] = [
        DEFAULT_TOW_REQUESTS_SORT,
        { field: 'status', direction: 'asc', name: 'Sort by Status' },
        { field: 'tow.datetime', direction: 'asc', name: 'Sort by Date/Time' },
        { field: 'tow.trailer.type', direction: 'desc', name: 'Sort by Type' },
        { field: 'tow.trailer.weight', direction: 'desc', name: 'Sort by Weight' },
        { field: 'tow.trailer.length', direction: 'desc', name: 'Sort by Length' },
        { field: 'distance', direction: 'desc', name: 'Sort by Distance' },
        { field: 'duration', direction: 'desc', name: 'Sort by Duration' }
    ];
    towRequestsSort = DEFAULT_TOW_REQUESTS_SORT;

    constructor(
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.sortedTowRequests$ = combineLatest([
            this.towRequests$,
            this.towRequestsSort$,
            this.activeTow$
        ]).pipe(
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            map(([towRequests, sort, tow]) => {
                this.towRequests = sort.field === 'status' ?
                    this.sortByStatus(towRequests) :
                    ListHelper.sortList(towRequests, sort);

                if (!tow) {
                    return this.towRequests;
                }

                // Updated active tow request with the latest tow data - needed to get the latest tow status
                // which is used to determine if the "cancel" link should be shown
                const activeTowRequest = this.towRequests.find(x => x.tow.id === tow.id);
                if (activeTowRequest) {
                    const index = this.towRequests.findIndex(x => x.id === activeTowRequest.id);
                    this.towRequests[index] = { ...activeTowRequest, tow };
                }

                return this.towRequests;
            })
        );
    }

    onPhotoError(id: string): void {
        this.displayDefaultPhoto.set(id, true);
    }

    onTowRequestsSort(sort: SortOption): void {
        this.towRequestsSort = sort;
        this.towRequestsSort$.next(sort);
    }

    onClose(): void {
        this.modalController.dismiss();
    }

    onView(towRequest: TowRequest): void {
        this.viewTowRequest.emit(towRequest);
    }

    async onCancelTowRequest(towRequest: TowRequest): Promise<void> {
        this.cancelTowRequest.emit(towRequest);
    }

    private sortByStatus(towRequests: TowRequest[]): TowRequest[] {
        let sortedTowRequests: TowRequest[] = [];
        const statuses = [
            TowRequestStatus.Pending,
            TowRequestStatus.Approved,
            TowRequestStatus.Accepted,
            TowRequestStatus.Declined,
            TowRequestStatus.Rejected
        ];
        statuses.forEach(status => {
            const filtered = towRequests.filter(x => x.status === status);
            const sorted = ListHelper.sortList(filtered, { field: 'tow.datetime', direction: 'asc' });
            sortedTowRequests = sortedTowRequests.concat(sorted);
        });
        return sortedTowRequests;
    }
}
