<button *ngIf="showToggle" type="button" (click)="onToggleTowDetails()" [class.hidden]="hide">
    <img class="up" src="/assets/svg/up-arrow.svg">
    <img class="down" src="/assets/svg/down-arrow.svg">
</button>

<div *ngIf="towDetails" class="cols">
    <div class="left">
        <ion-avatar *ngIf="!displayDefaultPhoto; else defaultDriverPhoto">
            <img [src]="towDetails.tow.user.photo" (error)="onPhotoError()" referrerpolicy="no-referrer">
        </ion-avatar>
        <ng-template #defaultDriverPhoto>
            <mat-icon>person</mat-icon>
        </ng-template>
        <div class="name">{{ towDetails.tow.user.firstName }} {{ towDetails.tow.user.lastName }}</div>
    </div>
    <div class="center">
        <div>
            <div class="icon-type">
                <i *ngIf="+towDetails.tow.trailer.icon === 7; else icon" class="material-symbols-outlined" matPrefix>weight</i>
                <ng-template #icon>
                    <ion-icon [src]="'/assets/svg/trailer-' + towDetails.tow.trailer.icon + '.svg'"></ion-icon>
                </ng-template>
                <div>{{ towDetails.tow.trailer.type }}</div>
            </div>
            <div class="weight-length">{{ towDetails.tow.trailer | trailerSummary }}</div>
            <div class="distance-duration">
                Distance | Duration
                <strong>{{ towDetails.distance | number }} mi | {{ towDetails.duration | duration }}</strong>
            </div>
            {{ towDetails.tow.datetime | date:'M/d/y h:mm a' }}
        </div>
    </div>
    <div class="right">
        <div class="status" [ngSwitch]="towDetails.towRequest?.status">
            <ion-button *ngSwitchCase="0" size="small" color="medium">
                Pending
            </ion-button>
            <ion-button *ngSwitchCase="1" size="small" fill="outline" color="success">
                Accepted
            </ion-button>
            <ion-button *ngSwitchCase="2" size="small" fill="outline" color="danger">
                Declined
            </ion-button>
            <ion-button *ngSwitchCase="3" size="small" fill="outline" color="success">
                Approved
            </ion-button>
            <ion-button *ngSwitchCase="4" size="small" fill="outline" color="danger">
                Rejected
            </ion-button>
            <ion-button *ngSwitchCase="6" size="small" fill="outline" color="medium">
                Available
            </ion-button>
            <ion-button *ngSwitchDefault size="small" fill="outline" color="medium">
                No Request
            </ion-button>
        </div>
        <div class="cost success">
            <span>$</span>{{ towDetails.payment.payment | number:'1.2-2' }}
        </div>
        <div class="btn">
            <ion-button color="primary" size="default" (click)="onViewTowDetails()">
                View
            </ion-button>
            <!-- Show "Cancel" link when status is Accepted, Approved, or Available and the tow status is Pending or Started -->
            <div *ngIf="[1, 3, 6].includes(towDetails.towRequest?.status ?? 0) && [0, 2].includes(towDetails.tow.status ?? 0)" class="link" (click)="onCancelTowRequest()">
                Cancel
            </div>
        </div>
    </div>
</div>
