import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ListHelper } from '@classes/list-helper';
import { ModalController } from '@ionic/angular';
import { SortOption } from '@models/sort-option.model';
import { TowDetails } from '@models/tow-details.model';
import { TowRequest } from '@models/tow-request.model';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { SubSink } from 'subsink';

const DEFAULT_TOWS_SORT: SortOption = {
    field: 'payment.payment',
    direction: 'desc',
    name: 'Sort by Pay'
};

@Component({
    selector: 'app-driver-map-tows',
    templateUrl: './driver-map-tows.component.html',
    styleUrls: ['./driver-map-tows.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverMapTowsComponent implements OnInit, OnDestroy {
    @Input() towDetailsList$!: Observable<TowDetails[]>;
    @Input() viewTowDetails!: EventEmitter<TowDetails>;
    @Input() cancelTowRequest!: EventEmitter<TowRequest>;

    private towsSort$ = new BehaviorSubject<SortOption>(DEFAULT_TOWS_SORT);
    private subs = new SubSink();

    towsSortOptions: SortOption[] = [
        DEFAULT_TOWS_SORT,
        { field: 'tow.datetime', direction: 'asc', name: 'Sort by Date/Time' },
        { field: 'tow.trailer.type', direction: 'desc', name: 'Sort by Type' },
        { field: 'tow.trailer.weight', direction: 'desc', name: 'Sort by Weight' },
        { field: 'tow.trailer.length', direction: 'desc', name: 'Sort by Length' },
        { field: 'distance', direction: 'desc', name: 'Sort by Distance' },
        { field: 'duration', direction: 'desc', name: 'Sort by Duration' }
    ];
    towsSort = DEFAULT_TOWS_SORT;
    tows$!: Observable<TowDetails[]>;

    constructor(
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.tows$ = combineLatest([
            this.towDetailsList$,
            this.towsSort$
        ]).pipe(
            map(([towDetailsList, sort]) => {
                const sortedTowDetailsList = ListHelper.sortList(towDetailsList, sort);
                return sortedTowDetailsList;
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onTowsSort(sort: SortOption): void {
        this.towsSort = sort;
        this.towsSort$.next(sort);
    }

    onViewTowDetails(towDetails: TowDetails): void {
        this.viewTowDetails.emit(towDetails);
    }

    onCancelTowRequest(towRequest: TowRequest): void {
        this.cancelTowRequest.emit(towRequest);
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
