<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Drivers</ion-title>
        <ion-buttons slot="end">
            <ion-button id="drivers-sort">
                <i class="material-symbols-outlined">sort</i>
            </ion-button>
            <ion-popover trigger="drivers-sort" [dismissOnSelect]="true">
                <ng-template>
                    <ion-content>
                        <ion-list class="drivers-sort">
                            <ion-item *ngFor="let sort of driversSortOptions; let last = last" [button]="true"
                                [class.active]="driversSort === sort" [lines]="last ? 'none' : 'full'"
                                (click)="onDriversSort(sort)">
                                <ion-label>{{ sort.name }}</ion-label>
                            </ion-item>
                        </ion-list>
                    </ion-content>
                </ng-template>
            </ion-popover>
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <ion-list>
        <ion-item *ngFor="let driverDetails of drivers$ | async">
            <ion-label>
                <app-trailer-map-driver-details [driverDetails]="driverDetails" [towRequest]="towRequests.get(driverDetails.user.id)" [showToggle]="false"
                    (sendTowRequest)="onSendTowRequest($event)" (cancelTowRequest)="onCancelTowRequest($event)" (viewRatings)="onViewRatings($event)"></app-trailer-map-driver-details>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>
