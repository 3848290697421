import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Email } from '@models/email.model';
import { SMS } from '@models/sms.model';
import { Observable, map, of } from 'rxjs';
import { DbService } from './db.service';

const COLLECTION = 'emails';

@Injectable({
    providedIn: 'root'
})
export class CommunicationService {
    constructor(
        private http: HttpClient,
        private dbService: DbService
    ) {}

    sendSMS(sms: SMS): Observable<SMS> {
        if (environment.disableSMS) {
            return of(sms);
        }

        const url = `${environment.apiBaseUrl}/communication/sms`;
        return this.http.post<void>(url, sms).pipe(
            map(() => sms)
        );
    }

    sendEmail(email: Email): Observable<Email> {
        return this.dbService.saveObj(COLLECTION, email);
    }
}
