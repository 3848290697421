<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Driver Ratings</ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <main>
        <section>
            <app-ratings [ratings]="(ratings$ | async) ?? []"></app-ratings>
        </section>
    </main>
</ion-content>
