import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ListHelper } from '@classes/list-helper';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { ModalController } from '@ionic/angular';
import { SortOption } from '@models/sort-option.model';
import { TowRequest } from '@models/tow-request.model';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

const DEFAULT_TOW_REQUESTS_SORT: SortOption = {
    field: 'driverDetails.payment.cost',
    direction: 'asc',
    name: 'Sort by Cost'
};

@Component({
    selector: 'app-trailer-map-tow-requests',
    templateUrl: './trailer-map-tow-requests.component.html',
    styleUrls: ['./trailer-map-tow-requests.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrailerMapTowRequestsComponent implements OnInit {
    @Input() towRequests$!: Observable<TowRequest[]>;
    @Input() approveTowRequest!: EventEmitter<TowRequest>;
    @Input() cancelTowRequest!: EventEmitter<TowRequest>;
    @Input() viewRatings!: EventEmitter<string>;

    private towRequestsSort$ = new BehaviorSubject<SortOption>(DEFAULT_TOW_REQUESTS_SORT);

    towRequestsSortOptions: SortOption[] = [
        DEFAULT_TOW_REQUESTS_SORT,
        { field: 'driverDetails.user.driver.completedTows', direction: 'desc', name: 'Sort by Completed Tows' },
        { field: 'driverDetails.user.driver.averageRating', direction: 'desc', name: 'Sort by Rating' },
        { field: 'driverDetails.vehicle.towingCapacity', direction: 'desc', name: 'Sort by Tow Capacity' }
    ];
    towRequestsSort = DEFAULT_TOW_REQUESTS_SORT;
    activeTowRequests$!: Observable<TowRequest[]>;
    availableTowRequests$!: Observable<TowRequest[]>;

    constructor(
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.activeTowRequests$ = combineLatest([
            this.towRequests$,
            this.towRequestsSort$
        ]).pipe(
            map(([towRequests, sort]) => {
                const activeTowRequests = towRequests.filter(x => x.status !== TowRequestStatus.Available);
                const sortedTowRequests = ListHelper.sortList(activeTowRequests, sort);
                return sortedTowRequests;
            })
        );
        this.availableTowRequests$ = combineLatest([
            this.towRequests$,
            this.towRequestsSort$
        ]).pipe(
            map(([towRequests, sort]) => {
                const availableTowRequests = towRequests.filter(x => x.status === TowRequestStatus.Available);
                const sortedTowRequests = ListHelper.sortList(availableTowRequests, sort);
                return sortedTowRequests;
            })
        );
    }

    onTowRequestsSort(sort: SortOption): void {
        this.towRequestsSort = sort;
        this.towRequestsSort$.next(sort);
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
