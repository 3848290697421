import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent {
    @Input() halfStar = true;
    @Input() disabled = true;
    @Input() rating = 0;
    @Output() ratingChange = new EventEmitter<number>();

    onRatingChanged(i: number): void {
        if (this.disabled) {
            return;
        }

        if (this.halfStar) {
            if (this.rating === i + 0.5) {
                // If the clicked star is already half-filled, make it full
                this.rating = i + 1;
            } else if (this.rating === i + 1) {
                // If the clicked star is already full, make it empty
                this.rating = i + 0.5;
            } else {
                // Otherwise, set it to half-filled
                this.rating = i + 0.5;
            }
        } else {
            this.rating = i + 1;
        }

        this.ratingChange.emit(this.rating);
    }

    getColor(index: number): string {
        return this.rating >= index + 1 || this.rating >= index + 0.5 ? environment.rating.activeColor :
            environment.rating.defaultColor;
    }
}
