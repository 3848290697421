import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TowHelper } from '@classes/tow-helper';
import { Experience } from '@custom-types/experience.type';
import { DistancesDurations } from '@models/distances-durations.model';
import { Tow } from '@models/tow.model';
import { User } from '@models/user.model';

@Component({
    selector: 'app-tow-details',
    templateUrl: './tow-details.component.html',
    styleUrls: ['./tow-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TowDetailsComponent implements OnInit {
    @Input() experience: Experience | null;
    @Input() tow!: Tow;
    @Input() userFormattedAddress?: string;

    driverAddress?: string;
    distancesDurations!: DistancesDurations;
    photo?: string;
    displayDefaultPhoto!: boolean;
    name!: string;
    isDriverHomeAddress!: boolean;
    payment: number;
    tip: number;
    total: number;

    ngOnInit(): void {
        const { payment, tip, total } = TowHelper.getPaymentTipAndTotal(this.tow, this.experience ?? 'driver');
        this.payment = payment;
        this.tip = tip;
        this.total = total;

        const commute = this.tow.driverOnWayRouteData ?? this.tow.approvedRequest?.commute;
        this.driverAddress = this.experience === 'driver' ? commute?.start.address : undefined;
        this.distancesDurations = {
            commute: this.experience === 'driver' ? {
                distance: commute?.distance ?? 0,
                duration: commute?.duration ?? 0
            } : undefined,
            tow: {
                distance: this.tow.routeData.distance,
                duration: this.tow.routeData.duration
            }
        } as DistancesDurations;

        this.photo = this.experience === 'trailer' ? this.tow.approvedRequest?.driverDetails.user.photo : this.tow.user.photo;
        this.displayDefaultPhoto = !this.photo;
        this.name = this.getUserName(this.experience === 'trailer' ? this.tow.approvedRequest!.driverDetails.user : this.tow.user);
        this.isDriverHomeAddress = this.userFormattedAddress === this.driverAddress;
    }

    onPhotoError(): void {
        this.displayDefaultPhoto = true;
    }

    private getUserName(user: User): string {
        return `${user.firstName} ${user.lastName}`;
    }
}
