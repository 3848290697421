import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { TowStatus } from '@enums/tow-status.enum';
import { environment } from '@environments/environment';
import { AlertController } from '@ionic/angular';
import { TowRequest } from '@models/tow-request.model';

@Component({
    selector: 'app-trailer-map-tow-request',
    templateUrl: './trailer-map-tow-request.component.html',
    styleUrls: ['./trailer-map-tow-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrailerMapTowRequestComponent {
    @Input() towRequest!: TowRequest;
    @Input() totalTowRequests = 1;
    @Input() approveTowRequest!: EventEmitter<TowRequest>;
    @Input() cancelTowRequest!: EventEmitter<TowRequest>;
    @Input() viewRatings!: EventEmitter<string>;

    displayDefaultDriverPhoto = false;
    rating = environment.rating;

    constructor(
        private alertController: AlertController
    ) {}

    onDriverPhotoError(id: string): void {
        this.displayDefaultDriverPhoto = true;
    }

    async onApproveTowRequest() {
        let message = '';
        if (this.totalTowRequests > 1) {
            message = 'All other requests will be rejected.';
        }

        const alert = await this.alertController.create({
            header: 'Confirm',
            subHeader: 'Are you sure?',
            message,
            buttons: [{
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary'
            }, {
                text: 'Yes',
                handler: () => {
                    const tow = { ...this.towRequest.tow, status: TowStatus.Started };
                    const towRequest = { ...this.towRequest, status: TowRequestStatus.Approved, tow };
                    this.approveTowRequest.emit(towRequest);
                }
            }]
        });
        await alert.present();
    }

    async onCancelTowRequest(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Confirm',
            subHeader: 'Are you sure?',
            message: 'This request will be canceled.',
            buttons: [{
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary'
            }, {
                text: 'Yes',
                handler: () => {
                    const towRequest = { ...this.towRequest, status: TowRequestStatus.Canceled };
                    this.cancelTowRequest.emit(towRequest);
                }
            }]
        });
        await alert.present();
    }

    onViewRatings(): void {
        this.viewRatings.emit(this.towRequest.driverDetails.user.id);
    }
}
