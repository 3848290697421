import { DistanceDuration } from '@models/distance-duration.model';
import { DistancesDurations } from '@models/distances-durations.model';
import { RouteData } from '@models/route-data.model';
import { v4 as uuidv4 } from 'uuid';
import { Camera, CameraResultType } from '@capacitor/camera';
import pack from '../../../../package.json';
import { BehaviorSubject } from 'rxjs';

const SM_MIN_WIDTH = 412; // Matches CSS variable $sm-min-width

export class UtilityHelper {
    static showBouncingArrow$ = new BehaviorSubject(false);
    static promptInstallEvent?: any;

    static removePunctuation(str: string): string {
        return str.replace(/["'@.,\/#!$%\^&\*;:{}=\_`~()]/g, '');
    }

    static removeExtraSpaces(str: string): string {
        return str.replace(/\s{2,}/g, ' ');
    }

    static compareValues(a: any, b: any): number {
        if (a < b) {
            return -1;
        }

        return a > b ? 1 : 0;
    }

    static removeNonDecimals(str: string): number {
        return +str.replace(/[^\d.-]/g, '');
    }

    static formatDuration(seconds: number): string {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.ceil((seconds - hours * 3600) / 60);

        const duration = [];
        if (hours > 0) {
            duration.push(`${hours} hr${hours === 1 ? '' : 's'}`);
        }

        if (minutes > 0) {
            duration.push(`${minutes} min${minutes === 1 ? '' : 's'}`);
        }

        if (duration.length === 0) {
            duration.push('1 min');
        }

        return duration.join(' ');
    }

    static getAverage(numbers: number[]): number {
        if (numbers.length === 0) {
            return 0;
        }

        const sum = numbers.reduce((a, b) => a + b);
        const average = sum / numbers.length;
        return average;
    }

    static getRoundedDecimal(num: number): number {
        const rounded = Math.round((num + Number.EPSILON) * 100) / 100;
        return rounded;
    }

    static getDistancesDurations(commute: RouteData, routeData: RouteData): DistancesDurations {
        return {
            commute: {
                start: commute.start,
                destination: commute.destination,
                distance: commute.distance,
                duration: commute.duration
            },
            tow: {
                start: routeData.start,
                destination: routeData.destination,
                distance: routeData.distance,
                duration: routeData.duration
            }
        };
    }

    static getTotalDistanceDuration(distancesDurations: DistancesDurations): DistanceDuration {
        const start = distancesDurations.commute.start;
        const destination = distancesDurations.tow.destination;
        const distance = distancesDurations.commute.distance + distancesDurations.tow.distance;
        const duration = distancesDurations.commute.duration + distancesDurations.tow.duration;
        return { start, destination, distance, duration };
    }

    static formatNumber(num: number): string {
        return Number(num).toLocaleString('en-GB');
    }

    static capitalize(str: string): string {
        if (!str) {
            return str;
        }

        return str[0].toUpperCase() + str.substring(1).toLowerCase();
    }

    static generateGuid(): string {
        return uuidv4();
    }

    static addScreenWidthSizeClassToElement(selector: string): void {
        // Is weird, but sometimes there is more than one of the same element. I guess Ionic just leaves some older ones in the DOM
        const elementents = document.querySelectorAll(selector);
        elementents.forEach(element => {
            const className = 'sm';
            const observer = new ResizeObserver(entries => {
                const entry = entries[0];
                const meetsSmMinWidth = entry.contentRect.width >= SM_MIN_WIDTH;
                if (meetsSmMinWidth) {
                    element.classList.add(className);
                } else {
                    element.classList.remove(className);
                }
            });
            observer.observe(document.body);
        });
    }

    static isIOS(): boolean {
        return document.querySelector('html')?.classList.contains('ios') ?? false;
    }

    static getVersion(): string {
        return pack.version;
    }

    static getQueryString(obj: Record<string, string>): string {
        const queryString = new URLSearchParams(obj).toString();
        return queryString;
    }

    static addStyleTag(key: string, css: string): void {
        const dataKey = `data-${key}`;

        // Remove style if it has have already been added
        const found = document.querySelector(`style[${dataKey}]`);
        if (found) {
            found.remove();
        }

        const style = document.createElement('style');
        style.setAttribute(dataKey, '');
        style.appendChild(document.createTextNode(css));
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    static isStandalone(): boolean {
        return window.matchMedia('(display-mode: standalone)').matches;
    }

    static preloadImages(imgs: string[]): void {
        imgs.forEach(src => {
            const img = new Image();
            img.src = src;
        });
    }

    static isMobile(): boolean {
        const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);
        return isMobile;
    }

    static async sleep(ms: number): Promise<void> {
        return new Promise(x => setTimeout(x, ms));
    }

    static async takePhoto(): Promise<{ url: string, file: File }> {
        const photo = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl
        });
        const url = photo.dataUrl ?? '';
        const file = this.dataUrlToFile(url);
        return { url, file };
    }

    static generateValidationCode(): number {
        const code = Math.floor(100000 + Math.random() * 900000);
        return code;
    }

    private static dataUrlToFile(dataUrl: string): File {
        const arr = dataUrl.split(',');
        const type = arr[0].match(/:(.*?);/)?.[1];
        const str = atob(arr[1]);
        let length = str.length;
        const blob = new Uint8Array(length);
        while (length--) {
            blob[length] = str.charCodeAt(length);
        }
        return new File([blob], '', { type });
    }
}
