/* eslint-disable max-params */
/* eslint-disable default-case */
/* eslint-disable dot-notation */
import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { GeoHelper } from '@classes/geo-helper';
import { GeoData } from '@models/geo-data.model';
import { Store } from '@ngrx/store';
import { GeoService } from '@services/geo.service';
import { BehaviorSubject, combineLatest, delay, distinctUntilChanged, distinctUntilKeyChanged, filter, interval, map, merge,
    Observable, of, shareReplay, Subject, switchMap, take, tap, timer } from 'rxjs';
import { SessionStoreSelectors, UserStoreActions, UserStoreSelectors, VehicleStoreSelectors, VehicleStoreActions, TowStoreActions,
    TowRequestStoreActions, TowRequestStoreSelectors, TrailerStoreSelectors, TowStoreSelectors, RatingStoreActions,
    RateStoreActions, RateStoreSelectors, NotificationStoreActions, TransactionStoreActions, PaymentStoreSelectors,
    TransactionStoreSelectors, NotificationStoreSelectors, GasPriceStoreActions, GasPriceStoreSelectors,
    DriverStoreActions, DriverStoreSelectors, SMSStoreActions, ChatStoreSelectors, ChatStoreActions } from 'src/app/root-store';
import { MapComponent } from 'src/app/shared/map/map.component';
import { SubSink } from 'subsink';
import { RouteData } from '@models/route-data.model';
import { Marker } from '@models/marker.model';
import { DriverDetails } from '@models/driver-details.model';
import { environment } from '@environments/environment';
import { User } from '@models/user.model';
import { Vehicle } from '@models/vehicle.model';
import { Actions, ofType } from '@ngrx/effects';
import { CostHelper } from '@classes/cost-helper';
import { Tow } from '@models/tow.model';
import { TowStatus } from '@enums/tow-status.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { TowRequest } from '@models/tow-request.model';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { AlertController, ModalController, ToastController, AlertButton } from '@ionic/angular';
import { UtilityHelper } from '@classes/utility-helper';
import { Trailer } from '@models/trailer.model';
import { Rating } from '@models/rating.model';
import { TrailerMapScheduleComponent } from './trailer-map-schedule/trailer-map-schedule.component';
import { TrailerMapTowRequestsComponent } from './trailer-map-tow-requests/trailer-map-tow-requests.component';
import { Distances } from '@models/distances.model';
import { TowPayment } from '@models/tow-payment.model';
import { ListHelper } from '@classes/list-helper';
import { DRIVER_MOVING_TOW_STATUSES, TOW_IN_PROGRESS_STATUSES } from 'src/app/root-store/tow-store/selectors';
import { RateType } from '@enums/rate-type.enum';
import { Rate } from '@models/rate.model';
import { Notification } from '@models/notification.model';
import { Payment } from '@models/payment.model';
import { TransactionType } from '@enums/transaction-type.enum';
import { TransactionStatus } from '@enums/transaction-status.enum';
import { Transaction } from '@models/transaction.model';
import { PhoneHelper } from '@classes/phone-helper';
import { PaymentType } from '@enums/payment-type.enum';
import { VehicleIcon } from '@enums/vehicle-icon.enum';
import { TrailerIcon } from '@enums/trailer-icon.enum';
import { ExpirationDateHelper } from '@classes/expiration-date-helper';
import { Expiration } from '@models/expiration.modal';
import { Disbursement } from '@models/disbursement.model';
import { DisbursementStoreActions } from 'src/app/root-store/disbursement-store';
import { NotificationService } from '@services/notification.service';
import { State } from '@models/state.model';
import { ObjectHelper } from '@classes/object-helper';
import { Driver } from '@models/driver.model';
import { DateHelper } from '@classes/date-helper';
import { DataService } from '@services/data.service';
import { ACTIVE_TOW_REQUEST_STATUSES, TOW_REQUEST_IN_PROGRESS_STATUSES } from 'src/app/root-store/tow-request-store/selectors';
import { SMS } from '@models/sms.model';
import { DriverBusyTimeStoreActions, DriverBusyTimeStoreSelectors } from 'src/app/root-store/driver-busy-time-store';
import { DriverBusyTime } from '@models/driver-busy-time.model';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { EstimatedDatetimes } from '@models/estimated-datetimes.model';
import { ReleaseOfLiabilityComponent } from 'src/app/shared/release-of-liability/release-of-liability.component';
import { DONT_SHOW_MAP_INSTRUCTIONS, LocalStorage } from '@classes/local-storage';
import { Route } from '@models/route.model';
import { TrailerMapFullTowDetailsComponent } from './trailer-map-full-tow-details/trailer-map-full-tow-details.component';
import { Chat } from '@models/chat.model';
import { ChatMessage } from '@models/chat-message.model';
import { VehicleSummaryPipe } from '@pipes/vehicle-summary.pipe';
import { TrailerMapChatComponent } from './trailer-map-chat/trailer-map-chat.component';
import { ChatUpdateRequest } from '@models/chat-update-request.model';
import { CurrentLocation } from '@models/current-location.model';
import { InsuranceCoverage } from '@models/insurance-coverage.model';
import { VehicleHelper } from '@classes/vehicle-helper';
import { TrailerMapDriversComponent } from './trailer-map-drivers/trailer-map-drivers.component';
import { GasPrice } from '@models/gas-price.model';
import { VehicleMileCost } from '@models/vehicle-mile-cost.model';
import { TimeSurgeCost } from '@models/time-surge-cost.model';
import { TowCost } from '@models/commute-cost.model';
import { TrailerMapDriverRatingsComponent } from './trailer-map-driver-ratings/trailer-map-driver-ratings.component';

export const trailerMapShowNotifications$ = new BehaviorSubject(false);
export const YOUR_LOCATION = 'Your Location';
export const HOME = 'Home';
const DELAY = 700; // Must match transition in the scss file
const LOADING_DELAY = 500;
const DRIVER_MARKER_PLOT_DELAY = 5000; // Needed because otherwise it doesn't show the driver marker

@Component({
    selector: 'app-trailer-map',
    templateUrl: './trailer-map.component.html',
    styleUrls: ['./trailer-map.component.scss']
})
export class TrailerMapComponent implements OnInit {
    @ViewChild('map') map!: MapComponent;
    @ViewChild('schedule') schedule!: TrailerMapScheduleComponent;

    private subs = new SubSink();
    private center!: GeoData;
    private datetime!: Date;
    private datetime$ = new Subject<Date>();
    private mapLoaded$ = new Subject<boolean>(); // The map only loads once - no need to reset this on ionViewWillLeave
    private selectedMarker$ = new Subject<Marker | null>();
    private delay = 0;
    private isReadyToShowDriverDetails$ = new Subject<boolean>();
    private refreshDriverDetails$ = new BehaviorSubject<boolean>(true);
    private driverDetailsMap = new Map<string, DriverDetails>();
    private user!: User;
    private driverDetails?: DriverDetails;
    private commuteMap = new Map<string, RouteData>();
    private showDriverDetails = false; // Used to denote the intention that the driver details should be open
    private routeData!: RouteData;
    private trailerId$!: Observable<string>;
    private tow?: Tow;
    private towRequests: TowRequest[] = [];
    private emittedTowRequestNotifications: string[] = [];
    private selectedTrailer$ = new Subject<Trailer | null>();
    private recalculateCost$ = new BehaviorSubject<boolean>(true);
    private trailer!: Trailer;
    private refreshTow$ = new BehaviorSubject<boolean>(true);
    private isScheduleValid = false;
    private approveTowRequest$ = new EventEmitter<TowRequest>();
    private cancelTowRequest$ = new EventEmitter<TowRequest>();
    private towChangesIds: string[] = [];
    private clearMarkers$ = new BehaviorSubject(false);
    private activeDriverDetails!: DriverDetails;
    private towRequestInProgress$!: Observable<TowRequest | undefined>;
    private routeSet$ = new Subject<boolean>();
    private approvedTowRequest?: TowRequest;
    private authorizedSale?: Transaction;
    private cancellationFee!: number;
    private rates!: Rate[];
    private trailers: Trailer[] = [];
    private showExpiredDatesMessage$ = new Subject<{ trailerId: string, expirations: Expiration[] }>();
    private defaultStart?: { name: string, geoData: GeoData };
    private towTruthy$!: Observable<Tow>;
    private towStatus$!: Observable<TowStatus>;
    private towsInProgress$!: Observable<Tow[]>;
    private action$!: Observable<'showNotifications' | 'status' | 'chat'>;
    private mapIsReady$ = new BehaviorSubject(false);
    private radius = environment.radius.default;
    private driversChangesIds: string[] = [];
    private drivers$!: Observable<Driver[]>;
    private expiredTowAlert?: HTMLIonAlertElement;
    private instructions?: string;
    private sentMessages = new Map<string, string>();
    private acceptReleaseOfLiability$ = new EventEmitter<void>();
    private route$ = new BehaviorSubject<Route | null>(null);
    private dontShowMapInstructions$ = new Subject<boolean>();
    private cancellationFee$!: Observable<number>;
    private chatChangesIds: string[] = [];
    private chatChangesTowIds: string[] = [];
    private updateChat = new EventEmitter<ChatUpdateRequest>();
    private isChatOpen$ = new BehaviorSubject(false);
    private isChatMessageAlertOpen$ = new BehaviorSubject(false);
    private displayedChatMessageDates: string[] = [];
    private sentChatMessages: string[] = [];
    private driverUserId$!: Observable<string>;
    private driverReadChatMessages: string[] = [];
    private insuranceCoverages: InsuranceCoverage[] = [];
    private currentTowDriverCurrentLocation$!: Observable<CurrentLocation>;
    private gasPrice$!: Observable<GasPrice>;
    private vehicleMileCosts$!: Observable<VehicleMileCost[]>;
    private timeSurgeCosts$!: Observable<TimeSurgeCost[]>;
    private towCosts$!: Observable<TowCost[]>;
    private sendTowRequest$ = new EventEmitter<DriverDetails>();
    private driversModal!: HTMLIonModalElement;
    private currentPosition$!: Observable<GeoData>;
    private driverSearchTriggered$ = new BehaviorSubject(false);
    private driverSearchComplete$ = new BehaviorSubject(false);
    private activeTow$ = new BehaviorSubject<Tow | undefined>(undefined);
    private markerCount$!: Observable<number>;
    private viewRatings$ = new EventEmitter<string>();

    user$!: Observable<User>;
    trailer$!: Observable<Trailer | null>;
    center$!: Observable<GeoData>;
    markers$!: Observable<Marker[]>;
    routeData$ = new BehaviorSubject<RouteData | null>(null);
    hideInputs = true;
    showDistanceDuration = false;
    driverDetails$!: Observable<DriverDetails>;
    hideDriverDetails = true; // Used to hide/show the driver details panel
    noDriverDetails = true;
    displayDefaultDriverPhoto = false;
    tow$!: Observable<Tow | undefined>;
    trailers$!: Observable<Trailer[]>;
    approvedTowRequestDriverDetails$ = new BehaviorSubject<DriverDetails | undefined>(undefined);
    towRequests$!: Observable<TowRequest[]>;
    activeTowRequests$!: Observable<TowRequest[]>;
    rates$!: Observable<Rate[]>;
    trailerExpiredErrors = new Map<string, boolean>();
    showSchedule = true; // Needed to solve the bug with the schedule sometimes not having the autocomplete working
    trailersWithTowInProgress = new Map<string, boolean>();
    state$!: Observable<State>;
    pin$!: Observable<string>;
    scheduleShown$ = new BehaviorSubject(false);
    towRequest$!: Observable<TowRequest | undefined>;
    chat$!: Observable<Chat>;
    chatUnreadMessagesCount$!: Observable<number>;

    constructor(
        private store: Store,
        private geoService: GeoService,
        private changeDetectorRef: ChangeDetectorRef,
        private actions: Actions,
        private route: ActivatedRoute,
        private toastController: ToastController,
        private alertController: AlertController,
        private modalController: ModalController,
        private router: Router,
        private notificationService: NotificationService,
        private dataService: DataService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private vehicleSummaryPipe: VehicleSummaryPipe
    ) {}

    async ngOnInit(): Promise<void> {
        this.getParams();
        this.getTrailers();
        this.getTowsInProgress();
        this.setTrailer();
        this.setMarkers();
        this.setDriverDetails();
        this.setTow();
        this.setNotifications();
        this.preloadIcons();
        await this.checkExpiredTow();
        this.setCurrentTowDriverUser();
    }

    ionViewWillEnter(): void {
        this.initSchedule();
        UtilityHelper.addScreenWidthSizeClassToElement('app-trailer-map section');

        this.subs.add(
            // Set mapIsReady$ so logic that needs the MapComponent to be ready can use this to ensure is ready
            combineLatest([
                this.mapIsReady$,
                interval(1000)
            ]).pipe(
                filter(([mapIsReady, _]) => !mapIsReady && !!this.map)
            ).subscribe(() => this.mapIsReady$.next(true)),

            // Just used here for loging. TODO: Remove once development is ready
            this.mapIsReady$.subscribe(x => console.log('mapIsReady', x)),

            // Get gas price for the user's current location state
            this.state$.subscribe(state => {
                this.store.dispatch(GasPriceStoreActions.getGasPriceRequest({ state: state.name }));
            }),

            // This will be used to show the notification icon next to trailers (in the list) that have a tow in progress
            this.towsInProgress$.subscribe(tows => this.setTrailersWithTowInProgress(tows)),

            // Trigger the tracking of tow/requests changes
            this.towTruthy$.subscribe(tow => {
                this.trackTowAndTowRequestsChanges(tow);
                this.trackChatChanges(tow);
            }),

            // Set current tow's approved tow request
            this.towTruthy$.pipe(
                switchMap(tow => this.store.select(TowRequestStoreSelectors.getApprovedTowRequest(tow.id))),
                filter(towRequest => !!towRequest),
                tap(x => console.log('approvedTowRequest', x)),
            ).subscribe(towRequest => {
                this.approvedTowRequest = towRequest;
            }),

            this.tow$.pipe( // Using tow$ instead towTruthy$ so it can be triggered when the tow status changes
                filter(tow => !!tow),
                map(tow => tow!),
                switchMap(tow => this.store.select(TowRequestStoreSelectors.getApprovedTowRequestDriverDetails(tow.id))),
                filter(driverDetails => !!driverDetails),
                tap(x => console.log('approvedTowRequestDriverDetails', x)),
            ).subscribe(driverDetails => {
                this.approvedTowRequestDriverDetails$.next(driverDetails);
            }),

            // Collapse driver details if we have completed the tow and showing "Rate and Tip"
            combineLatest([
                this.towStatus$,
                this.approvedTowRequestDriverDetails$
            ]).pipe(
                filter(([status, driverDetails]) => status === TowStatus.Completed && !!driverDetails),
                delay(1000)
                // driverDetails$ gets triggered so let's give time for that logic (that sets hideDriverDetails = false)
                // to finish before we hide it
            ).subscribe(() => {
                this.hideDriverDetails = true;
            }),

            // Get held payments associated with this tow
            this.towTruthy$.subscribe(tow => {
                this.store.dispatch(TransactionStoreActions.getTransactionsRequest({ userId: tow.user.id, towId: tow.id,
                    transactionType: TransactionType.Sale, status: TransactionStatus.Authorized }));
            }),

            // Get authorized transaction for this trip
            this.towTruthy$.pipe(
                switchMap(tow => this.store.select(TransactionStoreSelectors.getTransaction(tow.user.id, tow.id,
                    TransactionType.Sale, TransactionStatus.Authorized))),
                filter(transaction => !!transaction),
                distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
            ).subscribe(transaction => {
                this.authorizedSale = transaction;
            }),

            // Handle an action passed in the route
            this.action$.subscribe(async action => {
                switch (action) {
                    case 'showNotifications':
                        await this.onShowNotifications();
                        break;
                    case 'chat':
                        await this.onOpenChat();
                        break;
                }
            }),

            // If the action 'status' was sent in the route,
            // determince whether we need to show the notifications or not depending on the tow status
            this.action$.pipe(
                filter(action => action === 'status'),
                switchMap(() => this.towTruthy$),
                filter(tow => tow.status === TowStatus.Pending)
            ).subscribe(() => this.onShowNotifications()),

            // Show notifications (triggered from the notifications component when we are already in the page)
            trailerMapShowNotifications$.pipe(
                filter(show => !!show)
            ).subscribe(() => {
                this.onShowNotifications();
                trailerMapShowNotifications$.next(false);
            }),

            // Handle get users success and trigger the tracking of drivers changes
            combineLatest([
                this.actions.pipe(ofType(UserStoreActions.getUsersSuccess)),
                this.routeData$
            ]).pipe(
                filter(([_props, routeData]) => !!routeData)
            ).subscribe(([props]) => {
                this.handleUsers(props.users);

                const ids = props.users.map(x => x.id);
                this.trackDriversChanges(ids);
            }),

            // Handle get tow changes success
            this.actions.pipe(ofType(TowStoreActions.getTowChangesSuccess)).subscribe(async props => {
                const status = props.tow.status;
                const statusText = TowStatus[status].toLowerCase();
                const type = props.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Trailer';
                let message = `Tow ${statusText}!`;

                switch (status) {
                    case TowStatus.Pending:
                        message = 'Tow created and request sent!';

                        // Pending status also gets received when the driver cancels a tow
                        // When that happens the authorized sale gets canceled on the driver side
                        // So let's reset the authorized sale here so we don't cancel the same sale here as well
                        // Which will give an error
                        this.authorizedSale = undefined;
                        break;
                    case TowStatus.DriverOnWay:
                        message = 'Driver on the way!';
                        break;
                    case TowStatus.PickedUp:
                        message = `${type} has been ${type === 'Cargo' ? 'picked up' : statusText}!`;
                        break;
                    case TowStatus.TrailerOnWay:
                        message = `${type} on the way!`;
                        break;
                    case TowStatus.Delivered:
                        message = `${type} has been ${type === 'Cargo' ? 'delivered' : 'unhitched'}!`;
                        break;
                    case TowStatus.Rated:
                        message = 'Rating and tip submitted!';
                        break;
                    case TowStatus.Started:
                        return;
                }

                await this.presentToast(message);
            }),

            // Handle get tow request changes success
            this.actions.pipe(ofType(TowRequestStoreActions.getTowRequestsChangesSuccess)).subscribe(props => {
                this.handleTowRequestsUpdates(props.towRequests);
            }),

            // Handle save tow success
            this.actions.pipe(ofType(TowStoreActions.saveTowSuccess)).subscribe(props => {
                switch (props.tow.status) {
                    // If 'Pending', they are creating a new tow (by clicking on a driver) so let's also create the tow request
                    case TowStatus.Pending:
                        this.tow = props.tow;
                        this.saveTowRequest();
                        break;
                    // If they are canceling the tow:
                    // - Cancel its tow requests
                    // - Cancel the transaction
                    // - Charge the cancellation fee
                    // - Reset the route
                    case TowStatus.Canceled:
                        this.cancelTowRequests(props.tow);
                        this.cancelPayment();
                        this.captureCancellationFee(props.tow);
                        this.resetRoute();
                        this.driverDetails = undefined;

                        setTimeout(() => {
                            this.hideDriverDetails = true;
                            this.noDriverDetails = true;
                        }, DELAY);
                        break;
                    case TowStatus.Completed:
                        this.isReadyToShowDriverDetails$.next(false);
                        break;
                    case TowStatus.Rated:
                        this.saveDisbursements(props.tow.payment!);
                        break;
                    case TowStatus.Archived:
                        this.archiveTowRequests(props.tow);
                        this.reset();
                        break;
                }

                this.refreshTow$.next(true);
                this.sendTowNotificationAndSMS(props.tow);
            }),

            // Handle save tow request success
            this.actions.pipe(ofType(TowRequestStoreActions.saveTowRequestSuccess)).subscribe(async props => {
                this.refreshDriverDetails$.next(true);

                if (props.towRequest.status === TowRequestStatus.Archived) {
                    return;
                }

                let message = 'Request sent successfully!';
                if (props.towRequest.status !== TowRequestStatus.Pending) {
                    const status = TowRequestStatus[props.towRequest.status].toLowerCase();
                    message = `Request ${status}!`;
                }

                if (props.towRequest.status === TowRequestStatus.Approved) {
                    await this.handleApprovedTowRequest(props.towRequest);
                }

                await this.presentToast(message);

                this.sendTowRequestNotificationAndSMS(props.towRequest);
                this.promptToAcceptNotifications();
            }),

            // Initialize any active tow requests
            // Note: Needed in case they first arrive and there is a tow in progress and they 'Start Over'
            this.towRequests$.subscribe(),

            // Handle approval of tow request
            combineLatest([
                this.store.select(PaymentStoreSelectors.getPayments),
                this.approveTowRequest$
            ]).subscribe(async ([payments, towRequest]) => {
                await this.handleTowRequestApproval(payments, towRequest);
            }),

            // Handle cancellation of tow request
            this.cancelTowRequest$.subscribe(async towRequest => {
                await this.onCancelTowRequest(towRequest);
            }),

            // Handle payment hold success
            this.actions.pipe(ofType(TransactionStoreActions.authorizeSuccess)).subscribe(props => {
                this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest: props.towRequest }));
            }),

            // Handle payment hold failure
            this.actions.pipe(ofType(TransactionStoreActions.authorizeFailure)).subscribe(async () => {
                const alert = await this.alertController.create({
                    header: 'Payment Hold Failed',
                    message: 'We were not able to hold a payment for this trip. Please revise your credit cards and try again.',
                    buttons: ['OK']
                });
                await alert.present();
            }),

            // Handle payment complete success
            merge(
                this.actions.pipe(ofType(TransactionStoreActions.completeSaleSuccess)),
                this.actions.pipe(
                    ofType(TransactionStoreActions.captureSuccess),
                    filter(props => props.transaction.paymentType === PaymentType.Payment)
                )
            ).pipe(
                filter(() => !!this.tow && this.rates?.length > 0)
            ).subscribe(() => {
                this.handlePaymentComplete(this.tow!, this.rates);
            }),

            // Handle processing tip success
            this.actions.pipe(
                ofType(TransactionStoreActions.captureSuccess),
                filter(props => !!this.tow && props.transaction.paymentType === PaymentType.Tip && !!props.rating &&
                    !!props.payment)
            ).subscribe(props => {
                this.saveRatingAndTip(this.tow!, props.rating!, props.payment!);
            }),

            // Handle trailer cancellation success
            this.actions.pipe(
                ofType(TransactionStoreActions.captureSuccess),
                filter(props => props.transaction.paymentType === PaymentType.TrailerCancellation)
            ).subscribe(props => {
                // Split fee with driver
                const amount = UtilityHelper.getRoundedDecimal(props.transaction.amount / 2);
                this.saveDisbursement(amount, props.transaction.paymentType);
            }),

            // If the tow is in progress (except 'Pending' status), remove markers from the map
            // and set the driver details to the one that was approved
            combineLatest([
                this.towTruthy$,
                this.approvedTowRequestDriverDetails$
            ]).pipe(
                filter(([tow, approvedTowRequestDriverDetails]) => TOW_IN_PROGRESS_STATUSES.includes(tow.status) &&
                    tow.status !== TowStatus.Pending && !!approvedTowRequestDriverDetails),
                map(([_tow, approvedTowRequestDriverDetails]) => approvedTowRequestDriverDetails!.user),
                shareReplay()
            ).subscribe(user => {
                this.clearMarkers$.next(true);
                this.showActiveDriverDetails(user);
            }),

            // Show the route (A -> B)
            combineLatest([
                this.towTruthy$,
                this.mapIsReady$
            ]).pipe(
                filter(([tow, mapIsReady]) => !!tow?.routeData && mapIsReady),
                map(([tow, _]) => tow!.routeData),
                distinctUntilKeyChanged('distance')
            ).subscribe(routeData => {
                this.map.setRoute(routeData);
            }),

            // Show the complete route (A -> B -> C) when the tow is in progress (all statuses but 'Pending')
            combineLatest([
                this.towRequestInProgress$,
                this.mapIsReady$,
                this.routeSet$
            ]).pipe(
                filter(([towRequest, mapIsReady, routeSet]) => !!towRequest && mapIsReady && routeSet)
            ).subscribe(([towRequest]) => {
                const start = towRequest!.commute.start;
                const destination = towRequest!.tow.routeData.destination;
                const route: Route = { start, destination };
                const stops = [towRequest!.tow.routeData.start];
                this.map.setRoute(route, stops);
            }),

            // Handle show expired dates message
            this.showExpiredDatesMessage$.pipe(
                distinctUntilKeyChanged('trailerId'),
                filter(obj => !!obj.trailerId)
            ).subscribe(obj => {
                this.showExpiredDatesMessage(obj.trailerId, obj.expirations);
            }),

            // Get drivers busy times upon drivers being searched, route data and datetime being changed
            combineLatest([
                this.actions.pipe(ofType(UserStoreActions.getUsersSuccess)).pipe(
                    map(props => props.users.map(x => x.id))
                ),
                this.routeData$.pipe(
                    filter(routeData => !!routeData),
                    map(routeData => routeData!)
                ),
                this.datetime$
            ]).subscribe(([driverIds, routeData, datetime]) => {
                this.getDriversBusyTimes(driverIds, routeData, datetime);
            }),

            // Handle release of liability acceptance
            this.acceptReleaseOfLiability$.subscribe(() => this.sendTowRequest()),

            // Get the dontShowMapInstructions value from local storage
            LocalStorage.get<boolean>(DONT_SHOW_MAP_INSTRUCTIONS, 'boolean').subscribe(dontShowMapInstructions => {
                this.dontShowMapInstructions$.next(!!dontShowMapInstructions);
            }),

            // Get cancellation fee
            this.cancellationFee$.subscribe(fee => this.cancellationFee = fee),

            // Handle driver search complete
            combineLatest([
                this.driverSearchTriggered$,
                this.actions.pipe(ofType(UserStoreActions.getUsersSuccess)),
                this.actions.pipe(ofType(DriverStoreActions.getDriversChangesSuccess)),
                this.actions.pipe(ofType(VehicleStoreActions.getActiveVehiclesSuccess)),
                this.actions.pipe(ofType(DriverBusyTimeStoreActions.getDriversBusyTimesSuccess)),
            ]).pipe(
                filter(([driverSearchTriggered]) => driverSearchTriggered)
            ).subscribe(() => {
                this.driverSearchTriggered$.next(false); // Reset
                this.driverSearchComplete$.next(true);
            }),

            // Show map instructions after entering schedule details
            combineLatest([
                this.markerCount$,
                this.route$,
                this.dontShowMapInstructions$.pipe(
                    distinctUntilChanged()
                ),
                this.driverSearchComplete$.pipe(
                    distinctUntilChanged()
                )
            ]).pipe(
                filter(([markerCount, route, dontShowMapInstrutions, driverSearchComplete]) =>
                    markerCount > 0 && !!route && !dontShowMapInstrutions && driverSearchComplete)
            ).subscribe(async ([markerCount]) => {
                await this.showMapInstructionsAlert(markerCount);
            }),

            // Handle when there are no markers
            combineLatest([
                this.markerCount$,
                this.driverSearchComplete$.pipe(
                    distinctUntilChanged()
                ),
                this.activeTow$.pipe(
                    map(activeTow => !!activeTow),
                    distinctUntilChanged()
                ),
                this.clearMarkers$.pipe(
                    distinctUntilChanged()
                )
            ]).pipe(
                filter(([markerCount, driverSearchComplete, hasActiveTow, clearMarkers]) => markerCount === 0 &&
                    driverSearchComplete && !hasActiveTow && !clearMarkers)
            ).subscribe(() => this.handleNoMarkers()),

            // Reset driverSearchComplete so subsequent calls can trigger the code above
            this.driverSearchComplete$.pipe(
                filter(driverSearchComplete => driverSearchComplete),
                tap(x => console.log('driverSearchComplete', x)),
                delay(1000),
                tap(() => this.driverSearchComplete$.next(false)) // Reset
            ).subscribe(),

            // Handle update chat
            this.updateChat.subscribe(chatUpdateRequest => {
                if (!chatUpdateRequest.chat.towId) {
                    chatUpdateRequest = { ...chatUpdateRequest, chat: { ...chatUpdateRequest.chat, towId: this.tow?.id } };
                }

                this.store.dispatch(ChatStoreActions.saveChatRequest(chatUpdateRequest));
            }),

            // Handle save chat success - start tracking chat changes for newly created chat
            this.actions.pipe(ofType(ChatStoreActions.saveChatSuccess)).subscribe(props => {
                const id = props.chat.id;

                // Check if haven't already dispatch these 'changes' requests - we don't wanna create multiple instances
                if (this.chatChangesIds.includes(id)) {
                    return;
                }

                this.store.dispatch(ChatStoreActions.getChatChangesRequest({ id }));
                this.chatChangesIds.push(id);
            }),

            // Track the user associated with the driver performing the current tow in order to know their online status
            this.driverUserId$.subscribe(id => {
                this.store.dispatch(UserStoreActions.getUserChangesRequest({ id }));
            }),

            // Handle update chat success - send push notification and SMS for each chat message if driver is not online
            combineLatest([
                this.actions.pipe(ofType(ChatStoreActions.saveChatSuccess)),
                this.driverUserId$.pipe(
                    switchMap(userId => this.store.select(UserStoreSelectors.getUserOnline(userId))),
                    distinctUntilChanged()
                )
            ]).pipe(
                filter(([{ message }, online]) => !!message && !online)
            ).subscribe(([{ message }]) => this.sendChatNotificationAndSMS(message!)),

            // Keep track of the chat messages that have already been read by the driver
            // so we don't send push notification/SMS for those
            combineLatest([
                this.chat$,
                this.user$.pipe(
                    map(user => user.id),
                    distinctUntilChanged()
                )
            ]).pipe(
                map(([chat, userId]) => chat?.messages.filter(x => x.userId === userId && x.read) ?? [])
            ).subscribe(messages => this.setDriverReadChatMessages(messages)),

            // Display the last unread message from the chat
            combineLatest([
                this.user$,
                this.chat$
            ]).pipe(
                delay(500), // Delay needed to avoid showing this alert when the url param action is chat
                switchMap(([user, chat]) => combineLatest([
                    this.towTruthy$,
                    this.store.select(ChatStoreSelectors.getLastUnreadMessage(chat.id, user.id)),
                    this.isChatOpen$,
                    this.isChatMessageAlertOpen$
                ]))
            ).pipe(
                filter(([_, message, isChatOpen, isChatMessageAlertOpen]) => !!message && !isChatOpen && !isChatMessageAlertOpen),
                distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
            ).subscribe(async ([tow, message]) => await this.showChatLastUnreadMessage(tow, message!)),

            this.trailer$.pipe(
                filter(trailer => !trailer),
                delay(this.delay + 1000)
            ).subscribe(() => {
                // If the trailer is not set, hide driver details - There is some weird scenario when they arrive to this page
                // (with no trailer ID in the URL) and the user has multiple trailers and a tow in progress and the driver details
                // are showing even though no trailer has been selected
                // This takes care of hiding the driver details when that happens
                this.noDriverDetails = true;
                this.hideDriverDetails = true;
                this.changeDetectorRef.detectChanges();
            }),

            this.dataService.getVehicleInsuranceCoverages().subscribe(insuranceCoverages => {
                this.insuranceCoverages = insuranceCoverages;
            }),

            // Show the driver icon when the tow is in progress (all statuses but 'Pending')
            combineLatest([
                this.mapIsReady$,
                this.towStatus$,
                this.currentTowDriverCurrentLocation$
            ]).pipe(
                filter(([mapIsReady, status]) => mapIsReady && DRIVER_MOVING_TOW_STATUSES.includes(status)),
                delay(DRIVER_MARKER_PLOT_DELAY)
            ).subscribe(([_mapIsReady, _status, currentTowDriverCurrentLocation]) => {
                this.map.addDriverMarker(currentTowDriverCurrentLocation);
                this.map.updateDriverMarker(currentTowDriverCurrentLocation);
            }),

            // Update user's current location
            combineLatest([
                this.currentPosition$,
                this.user$
            ]).pipe(
                filter(([currentPosition, user]) => currentPosition.latitude !== user.currentLocation?.latitude &&
                    currentPosition.longitude !== user.currentLocation?.longitude
                ),
                switchMap(([center, user]) => {
                    return combineLatest([
                        of(center),
                        of(user),
                        this.geoService.getAddress(center.latitude, center.longitude)
                    ]);
                })
            ).subscribe(([geoData, user, address]) => {
                const currentLocation: CurrentLocation = { ...geoData,
                    address: address.formatted,
                    lastUpdateDate: new Date()
                };
                const properties: Partial<User> = { currentLocation };
                this.store.dispatch(UserStoreActions.updateUserPropertiesRequest({ id: user.id, properties }));
            }),

            // Handle events raised from the drivers modal
            this.sendTowRequest$.subscribe(async driverDetails => await this.onSendTowRequest(driverDetails)),

            // Handle event to view driver ratings
            this.viewRatings$.subscribe(async driverId => await this.onViewRatings(driverId))
        );
    }

    ionViewWillLeave(): void {
        this.reset();
        this.showSchedule = false;
        this.hideInputs = true;
        this.changeDetectorRef.detectChanges();
        this.map?.unsubscribe();

        // Clear tow requests
        this.store.dispatch(TowRequestStoreActions.clearTowRequests({ userId: this.user.id }));

        // Reset subjects
        this.selectedMarker$.next(null);
        this.refreshDriverDetails$.next(true);
        this.selectedTrailer$.next(null);
        this.recalculateCost$.next(true);
        this.refreshTow$.next(true);
        this.routeSet$.next(false);
        this.approvedTowRequestDriverDetails$.next(undefined);
        this.mapIsReady$.next(false);
        this.scheduleShown$.next(false);
        this.route$.next(null);
        this.driverSearchTriggered$.next(false);
        this.driverSearchComplete$.next(false);
        this.activeTow$.next(undefined);

        // Unsubscribe
        this.subs.unsubscribe();
        this.store.dispatch(TowRequestStoreActions.unsubscribeTowRequestsChangesRequest());
        this.store.dispatch(TowStoreActions.unsubscribeTowChangesRequest());
        this.store.dispatch(DriverStoreActions.unsubscribeDriversChangesRequest());
        // this.store.dispatch(ChatStoreActions.unsubscribeChatChangesRequest()); // TODO: Put back once keys are in place
        this.store.dispatch(UserStoreActions.unsubscribeUserChangesRequest());
    }

    onDatetimeChanged(datetime: Date): void {
        this.datetime = datetime;
        this.datetime$.next(datetime);
    }

    onScheduleValid(isValid: boolean): void {
        this.isScheduleValid = isValid;
    }

    onMapLoaded(): void {
        this.mapLoaded$.next(true);
    }

    onRouteSet(routeData: RouteData): void {
        this.routeSet$.next(!routeData.complete);
        this.routeData = routeData;
        this.routeData$.next(routeData);
        this.recalculateCost$.next(true);

        if (!this.tow || this.tow.status === TowStatus.Pending) {
            this.hideInputs = true;
        }

        setTimeout(() => {
            this.showDistanceDuration = true;
            this.changeDetectorRef.detectChanges();
        });
    }

    onCenterChanged(center: GeoData): void {
        this.center = center;
    }

    onToggleInputs(): void {
        this.hideInputs = !this.hideInputs;
        this.changeDetectorRef.detectChanges();
    }

    onSelectedMarker(marker: Marker): void {
        // If they clicked on the same marker and the details are already opened, collapse details and stop
        if (this.activeDriverDetails?.user.id === marker.id && !this.hideDriverDetails) {
            this.hideDriverDetails = true;
            this.changeDetectorRef.detectChanges();
            return;
        }

        this.delay = this.hideDriverDetails || this.noDriverDetails ? 0 : DELAY;
        this.hideDriverDetails = true;
        this.showDriverDetails = true;
        this.changeDetectorRef.detectChanges();

        setTimeout(() => {
            this.selectedMarker$.next(marker);
            this.isReadyToShowDriverDetails$.next(true);
            this.displayDefaultDriverPhoto = false;
        }, this.delay);
    }

    onGetDrivers(center: GeoData): void {
        this.center = center;
        this.getDrivers();
    }

    onToggleDriverDetails(): void {
        // If the details are already showing stop the active marker animation
        if (!this.hideDriverDetails) {
            this.map.stopActiveMarkerAnimation();
        } else { // Otherwise, start the animation
            this.map.startActiveMarkerAnimation();
        }

        this.hideDriverDetails = !this.hideDriverDetails;
        this.showDriverDetails = !this.showDriverDetails;
    }

    async onSetRoute(route: Route): Promise<void> {
        this.driverSearchTriggered$.next(true);
        this.clearMarkers$.next(false);
        this.map.setRoute(route);
        this.route$.next(route);
    }

    onRecalculateCost(): void {
        this.recalculateCost$.next(true);
    }

    async onSendTowRequest(driverDetails: DriverDetails): Promise<void> {
        this.driverDetails = driverDetails;

        const hasInsuranceCoverage = await this.checkVehicleInsuranceCoverages(driverDetails.vehicle);
        if (!hasInsuranceCoverage) {
            return;
        }

        // If the tow record has already been created, save the tow request
        if (this.tow) {
            this.saveTowRequest();
            return;
        }

        // If the tow record hasn't been created yet, prompt the user to accept our release of liability
        await this.showReleaseOfLiability();
    }

    async onCancelTowRequest(towRequest: TowRequest): Promise<void> {
        this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest }));
    }

    async onStartOver(): Promise<void> {
        const count = this.towRequests.filter(x => TOW_REQUEST_IN_PROGRESS_STATUSES.includes(x.status)).length;
        const type = this.tow?.trailer.type === 'Cargo' ? 'cargo' : 'tow';
        const message = count === 0 ?
            `Your ${type} request will be canceled.` :
            `Your ${count} ${type} request${count === 1 ? '' : 's'} will be canceled.`;
        const alert = await this.alertController.create({
            header: 'Confirm',
            subHeader: 'Are you sure?',
            message,
            buttons: [{
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary'
            }, {
                text: 'Yes',
                handler: () => this.cancelTow()
            }]
        });
        await alert.present();
    }

    async onShowNotifications(): Promise<void> {
        const modal = await this.modalController.create({
            component: TrailerMapTowRequestsComponent,
            componentProps: {
                towRequests$: this.activeTowRequests$,
                approveTowRequest: this.approveTowRequest$,
                cancelTowRequest: this.cancelTowRequest$,
                viewRatings: this.viewRatings$
            }
        });
        return await modal.present();
    }

    async onCancelTow(): Promise<void> {
        const tow = this.tow!;

        // Check if we are within the cancellation fee period
        const days = DateHelper.getDaysBetweenDates(new Date(), tow.datetime);
        if (days > environment.trailerCancellationFeePeriod) {
            this.cancelTow();
            return;
        }

        // Check if there are any approved tow requests for this tow
        const approvedTowRequests = this.towRequests.filter(x => x.tow.id === tow.id && x.status === TowRequestStatus.Approved);
        if (approvedTowRequests.length === 0) {
            this.cancelTow();
            return;
        }

        const fee = this.currencyPipe.transform(this.cancellationFee);
        const alert = await this.alertController.create({
            header: 'Confirm',
            subHeader: 'Are you sure?',
            message: `There will be a cancellation fee of <strong>${fee}</strong>.`,
            buttons: [{
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary'
            }, {
                text: 'Yes',
                handler: () => this.cancelTow()
            }]
        });
        await alert.present();
    }

    async onCompleteTow(): Promise<void> {
        if (this.authorizedSale) {
            this.store.dispatch(TransactionStoreActions.completeSaleRequest({ id: this.authorizedSale.id }));
            return;
        }

        await this.capturePayment();

        this.map?.removeDriverMarker();
    }

    onNewTow(): void {
        this.deleteDriverBusyTime();
        this.archiveTow();
    }

    onSelectTrailer(trailer: Trailer): void {
        this.selectedTrailer$.next(trailer);
    }

    onStartSet(geoData: GeoData): void {
        this.map?.updateCenterMarker(geoData);
    }

    async onSaveRatingAndTip(obj: { rating: Rating, payment: TowPayment }): Promise<void> {
        const paymentType = PaymentType.Tip;

        if (!this.approvedTowRequest) {
            await this.showSomethingWentWrong(paymentType);
            return;
        }

        const { rating, payment } = obj;
        const towRequest = this.approvedTowRequest;
        const amount = payment.tip;
        if (!amount) {
            this.saveRatingAndTip(towRequest.tow, rating, payment);
            return;
        }

        const towId = towRequest.tow.id;
        const userId = towRequest.tow.user.id;
        const description = 'Tip';
        const driverId = towRequest.driverDetails.user.id;
        this.store.dispatch(TransactionStoreActions.captureRequest({ amount, towId, userId, description, paymentType, driverId,
            rating, payment }));
    }

    onReCenterMap(): void {
        this.map?.reCenterMap();
    }

    onSetInstructions(instructions: string): void {
        this.instructions = instructions;
    }

    async onViewTowDetails(): Promise<void> {
        const modal = await this.modalController.create({
            component: TrailerMapFullTowDetailsComponent,
            componentProps: {
                tow: this.tow,
                viewRatings: this.viewRatings$
            }
        });
        await modal.present();
    }

    async onOpenDrivers(): Promise<void> {
        const driverDetailsList$ = this.markers$.pipe(
            switchMap(markers => {
                const list$: Observable<DriverDetails>[] = [];
                markers.forEach(marker => {
                    const marker$ = of(marker);
                    const driverDetails$ = this.getDriverDetails(marker$);
                    list$.push(driverDetails$);
                });
                return combineLatest(list$);
            }),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('driverDetailsList', x))
        );
        this.driversModal = await this.modalController.create({
            component: TrailerMapDriversComponent,
            componentProps: {
                driverDetailsList$,
                towRequests$: this.towRequests$,
                sendTowRequest: this.sendTowRequest$,
                cancelTowRequest: this.cancelTowRequest$,
                viewRatings: this.viewRatings$
            }
        });
        await this.driversModal.present();
    }

    async onOpenChat(): Promise<void> {
        const modal = await this.modalController.create({
            component: TrailerMapChatComponent,
            componentProps: {
                chat$: this.chat$,
                userId: this.user.id,
                updateChat: this.updateChat
            }
        });
        await modal.present();
        this.isChatOpen$.next(true);
        modal.onDidDismiss().then(() => this.isChatOpen$.next(false));
    }

    async onViewRatings(driverId: string): Promise<void> {
        const modal = await this.modalController.create({
            component: TrailerMapDriverRatingsComponent,
            componentProps: { driverId }
        });
        await modal.present();
    }

    private getParams(): void {
        const params$ = this.route.params.pipe(
            tap(x => console.log('params', x)),
            shareReplay()
        );
        this.trailerId$ = params$.pipe(
            map(params => params?.['trailerId']),
            distinctUntilChanged(),
            tap(x => console.log('trailerId', x)),
            shareReplay()
        );
        this.action$ = params$.pipe(
            map(params => params?.['action']),
            filter(action => !!action),
            distinctUntilChanged(),
            tap(x => console.log('action', x)),
            shareReplay()
        );
    }

    private getTrailers(): void {
        // If they didn't send a trailer ID in the route, fetch trailers
        this.trailers$ = this.trailerId$.pipe(
            filter(trailerId => !trailerId),
            switchMap(() => this.store.select(TrailerStoreSelectors.getNonCargoTrailers)),
            shareReplay(),
            tap(trailers => {
                console.log('trailers', trailers);
                this.trailers = trailers;

                // If they only have one trailer, default to it and stop
                if (trailers.length === 1) {
                    const trailer = trailers[0];
                    this.selectedTrailer$.next(trailer);
                    return;
                }

                this.checkExpirationDates(trailers);
            })
        );
    }

    private setTrailer(): void {
        this.trailer$ = merge(
            this.trailerId$.pipe(
                switchMap(id => this.store.select(TrailerStoreSelectors.getTrailer(id)))
            ),
            this.selectedTrailer$
        ).pipe(
            map(trailer => {
                if (!trailer) {
                    return null;
                }

                // Check if this trailer has an expired date
                const expirations = ExpirationDateHelper.checkExpirationDates(trailer).filter(x => x.type === 'error');
                if (expirations.length > 0) {
                    // Note: Using subject to circunvent multiple triggers
                    this.showExpiredDatesMessage$.next({ trailerId: trailer.id, expirations });
                    return null;
                }

                this.trailer = trailer;
                this.hideInputs = false;
                this.scheduleShown$.next(true);
                return trailer;
            }),
            tap(x => console.log('trailer', x)),
            shareReplay()
        );
        this.pin$ = this.trailer$.pipe(
            filter(trailer => !!trailer),
            map(trailer => `${trailer?.type === 'Cargo' ? 'cargo' : 'trailer'}-pin`),
            tap(x => console.log('pin', x)),
            distinctUntilChanged()
        );
    }

    private setMarkers(): void {
        this.user$ = this.store.select(SessionStoreSelectors.getUserId).pipe(
            tap(x => console.log('userId', x)),
            switchMap(userId => this.store.select(UserStoreSelectors.getUser(userId))),
            tap(x => console.log('user', x)),
            filter(user => !!user),
            tap(user => this.user = user),
            take(1),
            shareReplay()
        );
        const getCurrentPositionFn = (geoData: GeoData) => {
            this.defaultStart = { name: YOUR_LOCATION, geoData };
            this.schedule.setDefaultStart(YOUR_LOCATION, geoData);
        };
        const getAddressGeoDataFn = (geoData: GeoData) => {
            this.defaultStart = { name: HOME, geoData };
            this.schedule.setDefaultStart(HOME, geoData);
        };
        this.currentPosition$ = this.user$.pipe(
            switchMap(user => this.geoService.tryGetGeoData(user.address, getCurrentPositionFn, getAddressGeoDataFn))
        );
        this.center$ = combineLatest([
            this.currentPosition$,
            this.route$
        ]).pipe(
            map(([currentPosition, route]) => {
                this.center = route?.start ?? currentPosition;
                return this.center;
            }),
            tap(() => this.getDrivers()),
            tap(x => console.log('center', x)),
            shareReplay()
        );
        this.state$ = this.currentPosition$.pipe(
            switchMap(currentPosition => this.geoService.getState(currentPosition.latitude, currentPosition.longitude)),
            filter(x => !!x?.name),
            tap(x => console.log('state', x)),
            distinctUntilKeyChanged('name'),
            shareReplay()
        );
        const otherUsers$ = this.user$.pipe(
            switchMap(user => this.store.select(UserStoreSelectors.getOtherUsers(user.id))),
            map(users => users.filter(x => !!x.currentLocation)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('otherUsers', x))
        );
        this.drivers$ = this.store.select(SessionStoreSelectors.getUserId).pipe(
            switchMap(userId => this.store.select(DriverStoreSelectors.getOtherDrivers(userId))),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(drivers => this.updateMarkersIcon(drivers)),
            tap(x => console.log('drivers', x)),
            shareReplay()
        );
        const driversBusyTimes$ = this.drivers$.pipe(
            switchMap(drivers => {
                const driverIds = drivers.map(x => x.id);
                return this.store.select(DriverBusyTimeStoreSelectors.getDriversBusyTimes(driverIds));
            }),
            tap(x => console.log('driversBusyTimes', x))
        );
        this.markers$ = combineLatest([
            otherUsers$,
            this.drivers$,
            this.store.select(VehicleStoreSelectors.getActiveVehicles()),
            this.trailer$,
            this.mapLoaded$,
            this.clearMarkers$,
            driversBusyTimes$
        ]).pipe(
            filter(([_otherUsers, _drivers, _vehicles, trailer, mapLoaded]) => mapLoaded && !!trailer),
            tap(x => console.log('markers - incoming data', x)),
            map(([users, drivers, vehicles, trailer, _mapLoaded, clearMarkers, driversBusyTimes]) => {
                if (clearMarkers) {
                    return [];
                }

                const capableVehicles = this.getCapableVehicles(vehicles, trailer!);
                console.log('markers - capableVehicles', capableVehicles);
                const userIds = capableVehicles.map(x => x.userId);
                console.log('markers - userIds', userIds);
                const capableUsers = users.filter(x => userIds.includes(x.id));
                console.log('markers - capableUsers', capableUsers);
                const availableUsers = this.getAvailableUsers(capableUsers, driversBusyTimes);
                console.log('markers - availableUsers', availableUsers);
                this.preloadUserPhotos(availableUsers);
                const updatedUsers = this.updateUsersDriverProperty(availableUsers, drivers);
                const markers = updatedUsers.map(x => this.getMarker(x));
                return markers;
            }),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('markers - done', x)),
            shareReplay()
        );
        this.markerCount$ = this.markers$.pipe(
            map(markers => markers.length),
            distinctUntilChanged(),
            tap(x => console.log('markerCount', x)),
            shareReplay()
        );
    }

    private updateUsersDriverProperty(users: User[], drivers: Driver[]): User[] {
        const updatedUsers: User[] = [];
        users.forEach(user => {
            const driver = drivers.find(x => x.id === user.id);
            updatedUsers.push({ ...user, driver });
        });
        return updatedUsers;
    }

    private getCapableVehicles(vehicles: Vehicle[], trailer: Trailer): Vehicle[] {
        // Only display the users who's active vehicle can handle the trailer's towing capacity
        let capableVehicles = vehicles.filter(x => +x.towingCapacity >= +trailer!.weight);

        // If trailer type is 'Cargo' only display the users who's vehicle's bed length (+ 2') can handle the cargo's length
        if (trailer.type === 'Cargo') {
            capableVehicles = capableVehicles.filter(x => +x.bedLength + 2 >= +trailer.dimensions!.length);
        }

        return capableVehicles;
    }

    private getMarker(user: User): Marker {
        return {
            id: user.id,
            geoData: user.currentLocation,
            icon: this.getDriverIcon(user.driver),
            zIndex: user.driver?.active ? 3 : 2 // Make the active pins show on top
        } as Marker;
    }

    private setDriverDetails(): void {
        this.rates$ = this.store.select(RateStoreSelectors.getRates).pipe(
            filter(rates => rates?.length > 0),
            tap(rates => this.rates = rates),
            tap(x => console.log('rates', x)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay()
        );
        this.gasPrice$ = this.state$.pipe(
            switchMap(state => this.store.select(GasPriceStoreSelectors.getGasPrice(state.name))),
            filter(gasPrice => !!gasPrice),
            map(gasPrice => gasPrice!),
            tap(x => console.log('gasPrice', x)),
            distinctUntilKeyChanged('name'),
            shareReplay()
        );
        this.vehicleMileCosts$ = this.dataService.getVehicleMileCosts();
        this.timeSurgeCosts$ = this.dataService.getTimeSurgeCosts();
        this.towCosts$ = this.dataService.getTowCosts();
        this.isReadyToShowDriverDetails$.pipe(
            filter(isReadyToShowDriverDetails => isReadyToShowDriverDetails),
            tap(x => {
                console.log('isReadyToShowDriverDetails', x);
                this.refreshDriverDetails$.next(true);
                this.recalculateCost$.next(true);
            }),
            delay(1000),
            tap(() => this.isReadyToShowDriverDetails$.next(false)) // Reset
        );
        this.recalculateCost$.pipe(
            filter(recalculateCost => recalculateCost),
            tap(x => {
                console.log('recalculateCost', x);
                this.refreshDriverDetails$.next(true);
                this.isReadyToShowDriverDetails$.next(true);
            }),
            delay(1000),
            tap(() => this.recalculateCost$.next(false)) // Reset
        );
        this.refreshDriverDetails$.pipe(
            filter(refreshDriverDetails => refreshDriverDetails),
            tap(x => {
                console.log('refreshDriverDetails', x);
                this.isReadyToShowDriverDetails$.next(true);
                this.recalculateCost$.next(true);
            }),
            delay(1000),
            tap(() => this.refreshDriverDetails$.next(false)) // Reset
        );
        const marker$ = this.selectedMarker$.asObservable().pipe(
            filter(x => !!x),
            map(x => x!)
        );
        const driverDetails$ = combineLatest([
            this.getDriverDetails(marker$),
            this.isReadyToShowDriverDetails$,
            this.refreshDriverDetails$
        ]).pipe(
            filter(([_, isReadyToShowDriverDetails, refreshDriverDetails]) => isReadyToShowDriverDetails && refreshDriverDetails),
            map(([driverDetails]) => driverDetails)
        );
        this.driverDetails$ = merge(
            this.approvedTowRequestDriverDetails$.pipe(
                filter(driverDetails => !!driverDetails),
                map(driverDetails => driverDetails!)
            ),
            driverDetails$
        ).pipe(
            map(driverDetails => {
                // If we are already showing the approved tow request (we are in a tow in progress), show it
                // Otherwise, show the one they clicked on
                return this.driverDetails?.approved ? this.driverDetails : driverDetails;
            }),
            tap(x => console.log('driverDetails', x)),
            tap(driverDetails => {
                this.driverDetails = driverDetails;

                setTimeout(() => {
                    if (this.showDriverDetails) {
                        this.noDriverDetails = false;
                        this.hideDriverDetails = false;
                    }

                    this.changeDetectorRef.detectChanges();
                }, this.delay);
            }),
            shareReplay()
        );
        const driverUser$ = driverDetails$.pipe(
            map(driverDetails => driverDetails.user)
        );
        this.towRequest$ = driverUser$.pipe(
            switchMap(user => this.store.select(TowRequestStoreSelectors.getTowRequestByUserId(user.id))),
            map(towRequest => towRequest?.status !== TowRequestStatus.Archived ? towRequest : undefined),
            tap(x => console.log('towRequest', x)),
            distinctUntilChanged(),
            shareReplay()
        );
        const vehicle$ = driverDetails$.pipe(
            map(driverDetails => driverDetails.vehicle)
        );
        const activeVehicle$ = merge(
            vehicle$,
            this.driverDetails$.pipe(
                map(driverDetails => driverDetails.vehicle)
            )
        );
        this.cancellationFee$ = combineLatest([
            this.rates$,
            activeVehicle$
        ]).pipe(
            map(([rates, vehicle]) => {
                const rate = rates.find(x => x.category === vehicle.rateCategory && x.type === RateType.TrailerCancellation);
                return rate?.cost ?? 0;
            }),
            distinctUntilChanged()
        );
    }

    private setTow(): void {
        this.tow$ = combineLatest([
            this.towsInProgress$,
            this.trailer$,
            this.refreshTow$
        ]).pipe(
            map(([tows, trailer]) => {
                if (!trailer) {
                    return undefined;
                }

                // Ensure we are looking at the tows associated with the selected trailer
                const matchedTows = tows.filter(x => x.trailer.id === trailer!.id);
                if (matchedTows.length === 0) {
                    return undefined;
                }

                if (matchedTows.length === 1) {
                    return matchedTows[0];
                }

                const sortedTows = ListHelper.sortList(matchedTows, { field: 'creationDate', direction: 'desc' });
                return sortedTows[0];
            }),
            tap(tow => {
                console.log('tow', tow);
                this.activeTow$.next(tow);

                if (tow) {
                    this.tow = tow;
                    this.trailer = tow.trailer;
                    this.datetime = tow.datetime;
                    this.datetime$.next(tow.datetime);
                    this.showDistanceDuration = true;
                    return;
                }

                this.tow = undefined;
            }),
            shareReplay()
        );
        this.towTruthy$ = this.tow$.pipe(
            filter(tow => !!tow),
            map(tow => tow!),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('towTruthy', x)),
            shareReplay()
        );
        this.towStatus$ = this.tow$.pipe(
            filter(tow => !!tow),
            map(tow => tow!.status),
            distinctUntilChanged(),
            tap(x => console.log('towStatus', x)),
            shareReplay()
        );
        this.towRequests$ = combineLatest([
            this.towTruthy$.pipe(
                switchMap(tow => this.store.select(TowRequestStoreSelectors.getTowRequests(tow.id))),
            ),
            this.drivers$
        ]).pipe(
            map(([towRequests, drivers]) => this.updateTowRequestsDrivers(towRequests, drivers)),
            tap(x => console.log('towRequests', x)),
            tap(towRequests => this.towRequests = towRequests),
            shareReplay()
        );
        this.activeTowRequests$ = this.towRequests$.pipe(
            map(towRequests => towRequests.filter(x => ACTIVE_TOW_REQUEST_STATUSES.includes(x.status))),
            tap(x => console.log('activeTowRequests', x)),
            shareReplay()
        );
        this.refreshTow$.pipe(
            filter(refreshTow => refreshTow),
            tap(x => console.log('refreshTow', x)),
            delay(1000),
            tap(() => this.refreshTow$.next(false)) // Reset
        );
        this.towRequestInProgress$ = combineLatest([
            this.towTruthy$,
            this.towRequests$
        ]).pipe(
            map(([tow, towRequests]) => {
                if (towRequests?.length > 0 && tow.status !== TowStatus.Pending) {
                    const towRequestInProgress = towRequests.find(x => x.tow.id === tow.id);
                    return towRequestInProgress;
                }

                return undefined;
            }),
            shareReplay()
        );
        this.chat$ = this.towTruthy$.pipe(
            switchMap(tow => this.store.select(ChatStoreSelectors.getChatByTowId(tow.id))),
            map(chat => chat ?? { messages: [] as ChatMessage[] } as Chat), // If we don't have a chat yet, create an empty one
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('chat', x)),
            delay(100),
            shareReplay()
        );
        // Note: delay needed to avoid issue:
        // NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
        this.chatUnreadMessagesCount$ = combineLatest([
            this.chat$,
            this.user$
        ]).pipe(
            switchMap(([chat, user]) => this.store.select(ChatStoreSelectors.getUnreadMessagesCount(chat.id, user.id))),
            distinctUntilChanged()
        );
        this.driverUserId$ = this.approvedTowRequestDriverDetails$.pipe(
            filter(driverDetails => !!driverDetails),
            map(driverDetails => driverDetails!.user.id),
            distinctUntilChanged()
        );
    }

    private updateTowRequestsDrivers(towRequests: TowRequest[], drivers: Driver[]): TowRequest[] {
        const updatedTowRequests: TowRequest[] = [];
        towRequests.forEach(towRequest => {
            const driver = drivers.find(x => x.id === towRequest.driverDetails.user.id) ?? towRequest.driverDetails.user.driver;
            const user: User = { ...towRequest.driverDetails.user, driver };
            const driverDetails: DriverDetails = { ...towRequest.driverDetails, user };
            const updatedTowRequest: TowRequest = { ...towRequest, driverDetails };
            updatedTowRequests.push(updatedTowRequest);
        });
        return updatedTowRequests;
    }

    private setNotifications(): void {
        combineLatest([
            this.store.select(NotificationStoreSelectors.getToken),
            this.user$
        ]).pipe(
            filter(([token, user]) => !!token && !!user),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(([notificationToken, user]) => {
            user = { ...user, notificationToken };
            this.store.dispatch(UserStoreActions.saveUserRequest({ user }));
        });
    }

    private getDrivers(): void {
        this.store.dispatch(UserStoreActions.getUsersRequest(
            { center: this.center, radius: this.radius, excludeId: this.user.id, includeDriverProfile: true }
        ));
        this.map?.setZoom(this.radius);
    }

    private isDriverDetailsDataReady(user: User, vehicle: Vehicle | undefined, costPerMile: number, payment: TowPayment,
        vehicleLoading: boolean): boolean {
        console.log('isDriverDetailsDataReady', !!user && !!vehicle && (!!costPerMile || !!payment) && !vehicleLoading,
            !!user, !!vehicle, (!!costPerMile || !!payment), !vehicleLoading);
        return !!user && !!vehicle && (!!costPerMile || !!payment) && !vehicleLoading;
    }

    private handleUsers(users: User[]): void {
        // If no route has been set yet, stop
        if (!this.routeData) {
            return;
        }

        // If we have a tow in progress (Started or highter), stop
        const statuses = TOW_IN_PROGRESS_STATUSES.filter(x => x !== TowStatus.Pending);
        if (this.tow && statuses.includes(this.tow.status)) {
            return;
        }

        this.getActiveVehicles(users);
    }

    private getActiveVehicles(users: User[]): void {
        const userIds = users.map(x => x.id);
        this.store.dispatch(VehicleStoreActions.getActiveVehiclesRequest({ userIds }));
    }

    private async handleNoMarkers(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'No Drivers Found',
            message: 'Unfortunately, there are no drivers currently servicing your area.',
            buttons: ['OK']
        });
        await alert.present();
    }

    private saveTowRequest(): void {
        const tow = this.tow!;
        const commute = this.commuteMap.get(this.driverDetails!.user.id)!;
        const estimatedDatetimes = this.getEstimatedDatetimes(tow.datetime, tow.routeData.duration, commute.duration);
        const towRequest = {
            tow,
            driverDetails: this.driverDetails,
            commute,
            status: TowRequestStatus.Pending,
            estimatedDatetimes
        } as TowRequest;
        this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest }));
    }

    private async presentToast(message: string) {
        const toast = await this.toastController.create({
            message,
            icon: 'checkmark',
            duration: 3000,
            buttons: ['OK']
        });
        await toast.present();
    }

    private preloadIcons(): void {
        const vehicleIcons = ListHelper.getEnumList(VehicleIcon);
        const vehicleImgs = vehicleIcons.map(x => `/assets/svg/vehicle-${x}.svg`);

        const trailerIcons = ListHelper.getEnumList(TrailerIcon);
        const trailerImgs = trailerIcons.map(x => `/assets/svg/trailer-${x}.svg`);

        const imgs = [...vehicleImgs, ...trailerImgs];
        UtilityHelper.preloadImages(imgs);
    }

    private preloadUserPhotos(users: User[]): void {
        const imgs = users.filter(x => !!x.photo).map(x => x.photo!);
        UtilityHelper.preloadImages(imgs);
    }

    private rejectOtherTowRequests(towRequest: TowRequest): void {
        const towRequests = this.towRequests.filter(x => x.id !== towRequest.id);
        this.rejectTowRequests(towRequests);
    }

    private rejectTowRequests(towRequests: TowRequest[], tow?: Tow, clearStore = false): void {
        towRequests = towRequests.map(x => {
            tow ??= x.tow;
            return { ...x, tow, status: TowRequestStatus.Rejected };
        });
        this.store.dispatch(TowRequestStoreActions.updateTowRequestsRequest({ towRequests, clearStore, userId: this.user.id }));
        towRequests.forEach(x => this.sendTowRequestNotificationAndSMS(x));
    }

    private archiveTowRequests(tow: Tow): void {
        const towRequests = this.towRequests.map(x => {
            return { ...x, status: TowRequestStatus.Archived, tow };
        });
        this.store.dispatch(TowRequestStoreActions.updateTowRequestsRequest({ towRequests, clearStore: true,
            userId: this.user.id }));
    }

    private cancelTow(): void {
        const tow = { ...this.tow!, status: TowStatus.Canceled, cancellationDate: new Date() };
        this.store.dispatch(TowStoreActions.saveTowRequest({ tow }));
    }

    private cancelTowRequests(tow: Tow): void {
        const towRequests = this.towRequests.filter(x => TOW_REQUEST_IN_PROGRESS_STATUSES.includes(x.status)).map(x => {
            return { ...x, status: TowRequestStatus.Canceled, tow };
        });
        this.store.dispatch(TowRequestStoreActions.updateTowRequestsRequest({ towRequests, clearStore: true,
            userId: this.user.id }));

        towRequests.forEach(towRequest => {
            this.store.dispatch(DriverBusyTimeStoreActions.deleteDriverBusyTimeRequest({ towRequestId: towRequest.id }));
        });
    }

    private handleTowRequestsUpdates(towRequests: TowRequest[]): void {
        const statuses = [TowRequestStatus.Accepted, TowRequestStatus.Declined, TowRequestStatus.Available];
        towRequests = towRequests.filter(x => statuses.includes(x.status));
        towRequests.forEach(async towRequest => {
            const key = `${towRequest.id}|${towRequest.status}`;

            // Check if we have already shown this notification. if so, skip
            if (this.emittedTowRequestNotifications.includes(key)) {
                return;
            }

            const user = towRequest.driverDetails.user;
            const status = TowRequestStatus[towRequest.status].toLowerCase();
            const verb = towRequest.status === TowRequestStatus.Available ? 'is' : 'has';
            const message = `${user.firstName} ${user.lastName} ${verb} ${status}!`;
            await this.presentToast(message);
            this.emittedTowRequestNotifications.push(key);
        });
    }

    private reset(): void {
        this.towChangesIds = [];
        this.isReadyToShowDriverDetails$.next(false);
        this.noDriverDetails = true;
        this.hideDriverDetails = true;
        this.routeData$.next(null);
        this.showDistanceDuration = false;
        this.schedule.reset();
        this.isScheduleValid = false;
        this.clearMarkers$.next(false);
        this.map.clearRoute();
        this.map.stopActiveMarkerAnimation();
        this.driverDetails = undefined;
    }

    private resetRoute(): void {
        // Clear tows from store (needed to start over)
        this.store.dispatch(TowStoreActions.clearTows());

        this.schedule.reset();
        this.clearMarkers$.next(false);
        this.map.clearRoute();
        this.routeData$.next(null);
        this.showDistanceDuration = false;
    }

    private sendTowRequestNotificationAndSMS(towRequest: TowRequest): void {
        switch (towRequest.status) {
            case TowRequestStatus.Pending:
            case TowRequestStatus.Approved:
            case TowRequestStatus.Rejected:
                // Send these status notifications
                break;
            default:
                // Skip the rest
                return;
        }

        const statusText = TowRequestStatus[towRequest.status].toLowerCase();
        const type = towRequest.tow.trailer.type === 'Cargo' ? 'cargo' : 'tow';
        const date = `${this.datePipe.transform(towRequest.tow.datetime, 'M/d/y h:mm a')}`;
        const message = towRequest.status === TowRequestStatus.Pending ?
            `You have received a new ${type} request!` :
            `${UtilityHelper.capitalize(type)} request on ${date} has been ${statusText}!`;

        // Check if we already sent this message
        const towId = towRequest.tow.id;
        const messages = this.sentMessages.get(towId);
        if (messages?.includes(message)) {
            return;
        }

        this.sendTowRequestNotification(towRequest, message);
        this.sendTowRequestSMS(towRequest, message);
        this.sentMessages.set(towId, message);
    }

    private sendTowRequestNotification(towRequest: TowRequest, message: string): void {
        const userId = towRequest.driverDetails.user.id;
        const type = towRequest.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow';
        const title = `${type} Request`;
        const notification: Notification = {
            userId,
            title,
            message,
            route: `/driver/map/${towRequest.driverDetails.vehicle.id}`
        };
        this.store.dispatch(NotificationStoreActions.pushNotificationRequest({ notification }));
    }

    private sendTowRequestSMS(towRequest: TowRequest, message: string): void {
        if (!towRequest.driverDetails.user.smsOptIn) {
            return;
        }

        const sms: SMS = { message, phone: towRequest.driverDetails.user.phone };
        this.store.dispatch(SMSStoreActions.sendSMSRequest({ sms }));
    }

    private sendTowNotificationAndSMS(tow: Tow): void {
        switch (tow.status) {
            case TowStatus.Completed:
            case TowStatus.Rated:
                // Send these status messages
                break;
            default:
                // Skip the rest
                return;
        }

        const statusText = TowStatus[tow.status].toLowerCase();
        const type = tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow';
        const date = `${this.datePipe.transform(tow.datetime, 'M/d/y h:mm a')}`;
        const typeAndDate = `${type} on ${date}`;
        const message = tow.status === TowStatus.Rated ?
            `${typeAndDate} rated and tipped!` :
            `${typeAndDate} has ${tow.status === TowStatus.Completed ? 'been ' : ''}${statusText}!`;

        // Check if we already sent this message
        const towId = this.approvedTowRequest?.tow.id ?? '';
        const messages = this.sentMessages.get(towId);
        if (messages?.includes(message)) {
            return;
        }

        this.sendNotification(type, message);
        this.sendSMS(message);
        this.sentMessages.set(towId, message);
    }

    private sendNotification(title: string, message: string): void {
        const userId = this.approvedTowRequest?.driverDetails.user.id;
        if (!userId) {
            return;
        }

        const notification: Notification = {
            userId,
            title,
            message,
            route: `/driver/map/${this.approvedTowRequest?.driverDetails.vehicle.id}`
        };
        this.store.dispatch(NotificationStoreActions.pushNotificationRequest({ notification }));
    }

    private sendSMS(message: string): void {
        const phone = this.approvedTowRequest?.driverDetails.user.phone;
        if (!phone || !this.approvedTowRequest?.driverDetails.user.smsOptIn) {
            return;
        }

        const sms: SMS = { message, phone };
        this.store.dispatch(SMSStoreActions.sendSMSRequest({ sms }));
    }

    private async handleTowRequestApproval(payments: Payment[], towRequest: TowRequest): Promise<void> {
        // If they don't have any payment method, prompt to add one
        if (payments.length === 0) {
            const message = 'You don\'t have a payment method on file with us yet. Please add one and try again.';
            await this.promptMissingPaymentMethod(message);
            return;
        }

        // Let's filter out the expired payment methods
        const nonExpiredPayments = payments.filter(x => !x.expired);

        // If they don't have a non-expired payment method, prompt to add one
        if (nonExpiredPayments.length === 0) {
            const message = 'You don\'t have a valid payment method on file with us. Please add one and try again.';
            await this.promptMissingPaymentMethod(message);
            return;
        }

        // Check they have a default payment method. If they don't, prompt to set one
        const defaultPayment = nonExpiredPayments.find(x => x.default);
        if (!defaultPayment) {
            const message = 'You don\'t have a default payment method on file with us. Please select or add one and try again.';
            await this.promptMissingPaymentMethod(message, 'Select/Add Payment Method', '/payment');
            return;
        }

        // If we reach this point, they have a valid default payment method so let's hold the payment
        this.holdPayment(towRequest);
    }

    private async promptMissingPaymentMethod(message: string, btnText = 'Add Payment Method', route = '/payment/new'):
        Promise<void> {
        const alert = await this.alertController.create({
            header: 'Missing Payment Method',
            message,
            buttons: [{
                text: btnText,
                handler: async () => {
                    await this.modalController?.dismiss();
                    const redirectRoute = encodeURI(`/trailer/map/${this.trailer.id}/showNotifications`);
                    this.router.navigate([route, { redirectRoute }]);
                }
            }]
        });
        await alert.present();
    }

    private holdPayment(towRequest: TowRequest): void {
        const amount = towRequest.driverDetails.payment.cost;
        const towId = towRequest.tow.id;
        const userId = towRequest.tow.user.id;
        const description = 'Towing';
        const paymentType = PaymentType.Payment;
        this.store.dispatch(TransactionStoreActions.authorizeRequest({ amount, towId, userId, towRequest, description,
            paymentType }));
    }

    private cancelPayment(): void {
        if (!this.authorizedSale) {
            return;
        }

        this.store.dispatch(TransactionStoreActions.cancelRequest({ id: this.authorizedSale.id }));
    }

    private captureCancellationFee(tow: Tow): void {
        if (!this.authorizedSale) {
            return;
        }

        // Check if we are within the cancellation fee period
        const days = DateHelper.getDaysBetweenDates(new Date(), tow.datetime);
        if (days > environment.trailerCancellationFeePeriod) {
            return;
        }

        // Check if there are any approved tow requests for this tow
        const approvedTowRequests = this.towRequests.filter(x => x.tow.id === tow.id && x.status === TowRequestStatus.Approved);
        if (approvedTowRequests.length === 0) {
            return;
        }

        const amount = this.cancellationFee;
        const towId = tow.id;
        const userId = tow.user.id;
        const description = 'Trailer Cancellation';
        const paymentType = PaymentType.TrailerCancellation;
        this.store.dispatch(TransactionStoreActions.captureRequest({ amount, towId, userId, description, paymentType }));
    }

    private async capturePayment(): Promise<void> {
        if (!this.approvedTowRequest) {
            await this.showSomethingWentWrong(PaymentType.Payment);
            return;
        }

        const amount = this.approvedTowRequest.driverDetails.payment.cost;
        const towId = this.approvedTowRequest.tow.id;
        const userId = this.approvedTowRequest.tow.user.id;
        const description = 'Towing';
        const paymentType = PaymentType.Payment;
        this.store.dispatch(TransactionStoreActions.captureRequest({ amount, towId, userId, description, paymentType }));
    }

    private handlePaymentComplete(tow: Tow, rates: Rate[]): void {
        let payment = this.approvedTowRequest?.driverDetails.payment ?? { cost: 0 } as TowPayment;
        payment = CostHelper.calculateTotal(payment, rates);
        tow = { ...tow!, payment, status: TowStatus.Completed };
        this.store.dispatch(TowStoreActions.saveTowRequest({ tow }));
    }

    private saveRatingAndTip(tow: Tow, rating: Rating, payment: TowPayment): void {
        tow = { ...tow, payment, status: TowStatus.Rated };
        rating = { ...rating, tow };

        this.store.dispatch(RatingStoreActions.saveRatingRequest({ rating }));
        this.store.dispatch(TowStoreActions.saveTowRequest({ tow }));

        const towRequest = { ...this.approvedTowRequest!, tow, status: TowRequestStatus.Archived };
        this.approvedTowRequest = towRequest;
        this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest }));
    }

    private async showSomethingWentWrong(paymentType: PaymentType): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Something Went Wrong',
            message: `We could not process your ${paymentType}.
                Please contact us at ${PhoneHelper.formatPhone(environment.support.phone)}
                and provide your tow ID: ${this.tow?.id}.`,
            buttons: [{
                text: 'Call Support',
                handler: () => {
                    window.open(`tel:${environment.support.phone}`);
                }
            }]
        });
        await alert.present();
    }

    private async handleApprovedTowRequest(towRequest: TowRequest): Promise<void> {
        // Change the other requests to 'Rejected' and set the tow status to 'Started'
        this.rejectOtherTowRequests(towRequest);

        const estimatedDatetimes = this.getEstimatedDatetimes(towRequest.tow.datetime, towRequest.tow.routeData.duration,
            towRequest.commute.duration);
        const tow = { ...this.tow!, status: TowStatus.Started, approvedRequest: towRequest, estimatedDatetimes };
        this.store.dispatch(TowStoreActions.saveTowRequest({ tow }));

        // Hide the notifications panel (if showing)
        await this.modalController?.dismiss();

        // Hide the drivers panel (if showing)
        await this.driversModal?.dismiss();

        this.showActiveDriverDetails(towRequest.driverDetails.user);

        // Expand inputs
        this.hideInputs = false;

        this.saveDriverBusyTime(towRequest);
    }

    private showActiveDriverDetails(user: User): void {
        // If the active driver details is not already showing, show it
        if (this.activeDriverDetails?.user.id !== user.id) {
            this.noDriverDetails = false;
            const marker = this.getMarker(user);
            this.selectedMarker$.next(marker);
            this.isReadyToShowDriverDetails$.next(true);
        }
    }

    private promptToAcceptNotifications(): void {
        const message = 'We would like to send you notifications with updates about your tow.';
        const acceptFn = () => this.store.dispatch(NotificationStoreActions.requestPermissionRequest());
        this.notificationService.promptToAcceptNotifications(message, acceptFn);
    }

    private async showExpiredDatesMessage(trailerId: string, expirations: Expiration[]): Promise<void> {
        const errors = expirations.map(expiration => {
            const name = expiration.property.replace('ExpDate', '');
            return `${UtilityHelper.capitalize(name)} has expired.`;
        });
        const errorCount = errors.length;
        const header = `Expired Date${errorCount === 1 ? '' : 's'}`;
        const message = errorCount === 1 ? errors[0] : `<ul><li>${errors.join('</li><li>')}</li></ul>`;
        const buttons: AlertButton[] = [{
            text: 'Update Trailer',
            handler: () => {
                const redirectRoute = encodeURI(`/trailer/map/${trailerId}`);
                this.router.navigate([`/trailer/payload/${trailerId}`, { redirectRoute }]);
            }
        }];

        const trailerCount = this.trailers.length;
        if (trailerCount > 1) {
            buttons.splice(0, 0, {
                text: 'Select Other',
                role: 'cancel',
                cssClass: 'secondary'
            });
        }

        const alert = await this.alertController.create({
            header,
            message,
            buttons,
            backdropDismiss: trailerCount > 1
        });
        alert.onDidDismiss().then(() => this.showExpiredDatesMessage$.next({ trailerId: '', expirations: [] }));
        await alert.present();
    }

    private checkExpirationDates(trailers: Trailer[]): void {
        this.trailerExpiredErrors.clear();

        trailers.forEach(trailer => {
            const expirations = ExpirationDateHelper.checkExpirationDates(trailer).filter(x => x.type === 'error');
            expirations.forEach(expiration => {
                const key = `${trailer.id}|${expiration.property}`;
                this.trailerExpiredErrors.set(key, !!expiration.message);
            });
        });
    }

    private initSchedule(): void {
        this.showSchedule = true;

        if (this.defaultStart) {
            this.changeDetectorRef.detectChanges(); // Needed to make schedule not be undefined (it happens cause of the *ngIf)
            this.schedule.setDefaultStart(this.defaultStart.name, this.defaultStart.geoData);
        }
    }

    private saveDisbursements(payment: TowPayment): void {
        this.saveDisbursement(payment.payment, PaymentType.Payment);

        const tip = UtilityHelper.getRoundedDecimal(payment.tip - payment.tipCreditCardFee);
        this.saveDisbursement(tip, PaymentType.Tip);
    }

    private saveDisbursement(amount: number, type: PaymentType): void {
        const disbursement = {
            amount,
            type,
            towRequest: this.approvedTowRequest,
            paid: false
        } as Disbursement;
        this.store.dispatch(DisbursementStoreActions.saveDisbursementRequest({ disbursement }));
    }

    private getTowsInProgress(): void {
        this.towsInProgress$ = this.store.select(SessionStoreSelectors.getUserId).pipe(
            switchMap(userId => this.store.select(TowStoreSelectors.getTowsInProgress(undefined, undefined, userId))),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay()
        );
    }

    private setTrailersWithTowInProgress(tows: Tow[]): void {
        this.trailersWithTowInProgress.clear();

        tows.forEach(tow => {
            this.trailersWithTowInProgress.set(tow.trailer.id, true);
        });
    }

    private async handleMissingTowDatetime(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Something Went Wrong',
            message: 'Please try again',
            buttons: [{
                text: 'Retry'
            }]
        });
        alert.onDidDismiss().then(() => {
            this.reset();
            this.showSchedule = true;
            this.hideInputs = false;
            this.clearMarkers$.next(true);
        });
        await alert.present();
    }

    private updateMarkersIcon(drivers: Driver[]): void {
        const markers = new Map<string, string>();
        drivers.forEach(driver => {
            const icon = this.getDriverIcon(driver);
            markers.set(driver.id, icon);
        });
        this.map?.updateMarkersIcon(markers);
    }

    private getDriverIcon(driver?: Driver): string {
        const icon = `/assets/svg/vehicle-pin-${driver?.active ? 'active' : 'inactive'}.svg`;
        return icon;
    }

    private trackTowAndTowRequestsChanges(tow: Tow): void {
        // Check if haven't already dispatch these 'changes' requests - we don't wanna create multiple instances of the same
        if (this.towChangesIds.includes(tow.id)) {
            return;
        }

        this.store.dispatch(TowStoreActions.getTowChangesRequest({ id: tow.id }));
        this.store.dispatch(TowRequestStoreActions.getTowRequestsChangesRequest({ towId: tow.id }));
        this.towChangesIds.push(tow.id);
    }

    private trackChatChanges(tow: Tow): void {
        // Check if haven't already dispatch these 'changes' requests - we don't wanna create multiple instances
        if (this.chatChangesTowIds.includes(tow.id)) {
            return;
        }

        this.store.dispatch(ChatStoreActions.getChatsChangesByTowIdRequest({ towId: tow.id }));
        this.chatChangesTowIds.push(tow.id);
    }

    private trackDriversChanges(ids: string[]): void {
        // Check if haven't already dispatch these 'changes' requests - we don't wanna create multiple instances of the same
        if (ObjectHelper.areObjectsEqual(this.driversChangesIds, ids)) {
            return;
        }

        this.store.dispatch(DriverStoreActions.getDriversChangesRequest({ ids }));
        this.driversChangesIds = ids;
    }

    private async checkExpiredTow(): Promise<void> {
        const time = 1000 * 60; // Check every minute

        this.subs.add(
            timer(0, time).subscribe(async () => {
                if (!this.tow || this.tow.status !== TowStatus.Pending) {
                    return;
                }

                const now = new Date();
                const isExpired = DateHelper.compareDates(this.tow.datetime, '<', now);
                if (isExpired) {
                    await this.showExpiredTowAlert();
                }
            })
        );
    }

    private async showExpiredTowAlert(): Promise<void> {
        await this.expiredTowAlert?.dismiss();
        const type = this.tow?.trailer.type === 'Cargo' ? 'Cargo' : 'Tow';
        const message = `Your ${type.toLowerCase()} has expired.`;
        this.expiredTowAlert = await this.alertController.create({
            header: `${type} Expired`,
            message,
            buttons: [{
                text: 'OK',
                handler: () => this.archiveTow()
            }]
        });
        await this.expiredTowAlert.present();
    }

    private archiveTow(): void {
        const tow = { ...this.tow!, status: TowStatus.Archived };
        this.store.dispatch(TowStoreActions.saveTowRequest({ tow }));
        this.archiveTowRequests(tow);
    }

    private getDriversBusyTimes(driverIds: string[], routeData: RouteData, datetime: Date): void {
        const start = datetime;
        const end = DateHelper.addSeconds(datetime, routeData.duration);
        this.store.dispatch(DriverBusyTimeStoreActions.getDriversBusyTimesRequest({ driverIds, start, end }));
    }

    private getAvailableUsers(users: User[], driversBusyTimes: DriverBusyTime[]): User[] {
        const busyDriverIds = driversBusyTimes.map(x => x.driverId);
        const availableUsers = users.filter(x => !busyDriverIds.includes(x.id));
        return availableUsers;
    }

    private saveDriverBusyTime(towRequest: TowRequest): void {
        const commuteDuration = DateHelper.convertSecondsToMinutes(towRequest.commute.duration);
        const startPadding = commuteDuration + environment.driverBusyTimePadding;
        const start = DateHelper.subtractMinutes(towRequest.tow.datetime, startPadding);
        const towDuration = DateHelper.convertSecondsToMinutes(towRequest.tow.routeData.duration);
        const endPadding = towDuration + environment.driverBusyTimePadding;
        const end = DateHelper.addMinutes(towRequest.tow.datetime, endPadding);
        const driverBusyTime = {
            driverId: towRequest.driverDetails.user.driver!.id,
            start,
            end,
            towRequest
        } as DriverBusyTime;
        this.store.dispatch(DriverBusyTimeStoreActions.saveDriverBusyTimeRequest({ driverBusyTime }));
    }

    private sendTowRequest(): void {
        const tow = {
            user: this.user,
            trailer: this.trailer,
            datetime: this.datetime,
            routeData: this.routeData,
            status: TowStatus.Pending,
            instructions: this.instructions
        } as Tow;

        // Sometimes the datetime is undefined (probably when starting over). If that's the case let's alert the user and reload
        if (!tow.datetime) {
            this.handleMissingTowDatetime();
            return;
        }

        this.store.dispatch(TowStoreActions.saveTowRequest({ tow }));
    }

    private async showMapInstructionsAlert(count: number): Promise<void> {
        const message = `
            <div class="map-instructions ${UtilityHelper.isIOS() ? 'ios' : ''}">
                <p>
                    There ${count === 1 ? 'is' : 'are'} ${count} driver${count === 1 ? '' : 's'} available in your area.
                    Tap the driver icon for driver details and send requests.
                </p>
                <div class="pins">
                    <div><img src="/assets/svg/vehicle-pin-active.svg"> Online</div>
                    <div><img src="/assets/svg/vehicle-pin-inactive.svg"> Offline</div>
                </div>
                <p>
                    Or tap the <img class="drivers-icon" src="/assets/svg/drivers.svg"> icon in the header
                    for driver list, details, and send requests.
                </p>
            </div>`;
        this.expiredTowAlert = await this.alertController.create({
            header: 'Instructions',
            message,
            inputs: [{
                type: 'checkbox',
                label: 'Don\'t show this again.',
                value: true
            }],
            buttons: [{
                text: 'OK',
                handler: ([dontShowAgain]) => {
                    LocalStorage.set(DONT_SHOW_MAP_INSTRUCTIONS, !!dontShowAgain);
                    this.dontShowMapInstructions$.next(!!dontShowAgain);
                }
            }]
        });
        await this.expiredTowAlert.present();
    }

    private async showChatLastUnreadMessage(tow: Tow, message: ChatMessage): Promise<void> {
        const date = message.creationDate.toString();
        if (this.displayedChatMessageDates.includes(date)) {
            return;
        }

        const driverDetails = tow.approvedRequest?.driverDetails;
        const driver = driverDetails ? `${driverDetails.user.firstName} ${driverDetails.user.lastName} ` +
            `(${this.vehicleSummaryPipe.transform(driverDetails.vehicle)})` : '';
        const alert = await this.alertController.create({
            header: 'New Message',
            message: `You have a new message from ${driver}.`,
            buttons: ['Close', {
                text: 'View Message',
                handler: async () => await this.onOpenChat()
            }]
        });
        await alert.present();
        this.isChatMessageAlertOpen$.next(true);
        this.displayedChatMessageDates.push(date);
        alert.onDidDismiss().then(() => this.isChatMessageAlertOpen$.next(false));
    }

    private sendChatNotificationAndSMS(message: ChatMessage): void {
        // Check if we already sent/read this message
        const date = message.creationDate.toString();
        if (this.sentChatMessages.includes(date) || this.driverReadChatMessages.includes(date)) {
            return;
        }

        this.sendNotification('New Message', message.message);
        this.sendSMS(message.message);
        this.sentChatMessages.push(date);
    }

    private setDriverReadChatMessages(messages: ChatMessage[]): void {
        const dates = messages.map(x => x.creationDate.toString());
        this.driverReadChatMessages = ListHelper.removeDuplicatesFromList([...this.driverReadChatMessages, ...dates]);
    }

    private async checkVehicleInsuranceCoverages(vehicle: Vehicle): Promise<boolean> {
        if (VehicleHelper.vehicleHasTrailerAndCargoCoverage(this.insuranceCoverages, vehicle)) {
            return true;
        }

        const names = this.insuranceCoverages.filter(x => x.coversTrailerAndCargo).map(x => x.name);
        const subHeader = `No ${names.join(' or ')}`;
        const type = this.trailer.type;
        const alert = await this.alertController.create({
            header: 'Warning',
            subHeader,
            message: `<p>The driver's personal auto insurance does not cover damage to the
                ${type}${type === 'Cargo' ? ` (${this.trailer.description}).` : `. Therefore, it's highly recommended to
                have your own ${type} property damage insurance to protect your assets during transport.</p>
                <p>Do you accept the risks${type === 'Cargo' ? '' : ' of not having this coverage'}?</p>`}`,
            buttons: ['Decline', {
                text: 'Accept',
                handler: async () => await this.showReleaseOfLiability()
            }]
        });
        await alert.present();
        return false;
    }

    private async showReleaseOfLiability(): Promise<void> {
        // After accepting it will save the tow request
        const modal = await this.modalController.create({
            component: ReleaseOfLiabilityComponent,
            componentProps: {
                accept: this.acceptReleaseOfLiability$,
                experience: 'trailer',
                cancellationFee: this.cancellationFee,
                trailer: this.trailer
            }
        });
        await modal.present();
    }

    private setCurrentTowDriverUser(): void {
        this.currentTowDriverCurrentLocation$ = this.approvedTowRequestDriverDetails$.pipe(
            filter(towRequest => !!towRequest),
            switchMap(towRequest => this.store.select(UserStoreSelectors.getUserCurrentLocation(towRequest!.user.id))),
            filter(currentLocation => !!currentLocation),
            map(currentLocation => currentLocation!),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay()
        );
    }

    private getEstimatedDatetimes(datetime: Date, towDuration: number, commuteDuration: number): EstimatedDatetimes {
        const start = DateHelper.subtractSeconds(datetime, commuteDuration);
        const hitchDuration = environment.hitchDuration * 60;
        const hitchAndTowDuration = towDuration + hitchDuration;
        const end = DateHelper.addSeconds(datetime, hitchAndTowDuration);
        const estimatedDatetimes: EstimatedDatetimes = { start, end };
        return estimatedDatetimes;
    }

    private getDriverDetails(marker$: Observable<Marker>): Observable<DriverDetails> {
        const driverUser$ = combineLatest([
            marker$.pipe(
                filter(marker => !!marker),
                switchMap(marker => this.store.select(UserStoreSelectors.getUser(marker!.id))),
                filter(user => !!user)
            ),
            this.drivers$
        ]).pipe(
            map(([user, drivers]) => {
                const driver = drivers.find(x => x.id === user.id);
                const driverUser: User = { ...user, driver };
                return driverUser;
            }),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('driverUser', x)),
            shareReplay()
        );
        const vehicle$ = marker$.pipe(
            filter(marker => !!marker),
            switchMap(marker => this.store.select(VehicleStoreSelectors.getActiveVehicle(marker!.id))),
            filter(vehicle => !!vehicle),
            tap(x => console.log('vehicle', x)),
            tap(vehicle => {
                this.store.dispatch(RateStoreActions.getRatesRequest({ category: vehicle.rateCategory }));
            }),
            distinctUntilKeyChanged('id'),
            shareReplay()
        );
        const vehicleMileCost$ = combineLatest([
            vehicle$,
            this.vehicleMileCosts$
        ]).pipe(
            map(([vehicle, vehicleMileCosts]) => {
                const costs = vehicleMileCosts.filter(x => vehicle.towingCapacity > x.minTowingCapacity);
                const cost = ListHelper.getMaxValueFromPropertyInList(costs, 'cost');
                return cost;
            })
        );
        const costPerMile$ = combineLatest([
            this.rates$,
            this.gasPrice$,
            vehicleMileCost$
        ]).pipe(
            map(([rates, gasPrice, vehicleMileCost]) => CostHelper.getCostPerMile(rates, gasPrice, vehicleMileCost)),
            tap(x => console.log('costPerMile', x)),
            distinctUntilChanged(),
            shareReplay()
        );
        const noDistance$ = of({ distance: 0 } as RouteData);
        const distances$ = combineLatest([
            marker$,
            this.routeData$
        ]).pipe(
            filter(([marker]) => !!marker),
            switchMap(([marker, routeData]) => combineLatest([
                of(marker),
                routeData ? GeoHelper.getRouteData(marker!.geoData, routeData.start) : noDistance$,
                routeData ? of(routeData) : noDistance$
            ])),
            map(([marker, commute, tow]) => {
                this.commuteMap.set(marker!.id, commute);
                return { commuteDistance: commute.distance, towDistance: tow.distance } as Distances;
            }),
            tap(x => console.log('distances', x)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay()
        );
        const milesAway$ = distances$.pipe(
            map(distances => distances.commuteDistance)
        );
        const noDuration$ = of({ duration: 0 } as RouteData);
        const duration$ = combineLatest([
            marker$,
            this.routeData$
        ]).pipe(
            filter(([marker]) => !!marker),
            switchMap(([marker, routeData]) => combineLatest([
                of(marker),
                routeData ? GeoHelper.getRouteData(marker!.geoData, routeData.start) : noDuration$,
                routeData ? of(routeData) : noDuration$
            ])),
            tap(x => console.log('durations', x)),
            map(([_marker, commute, tow]) => commute.duration + tow.duration),
            tap(x => console.log('duration', x)),
            distinctUntilChanged()
        );
        const payment$ = combineLatest([
            this.recalculateCost$,
            distances$,
            this.rates$,
            this.gasPrice$,
            duration$,
            this.timeSurgeCosts$,
            this.towCosts$,
            this.trailer$
        ]).pipe(
            filter(([recalculateCost]) => recalculateCost),
            map(([_, { commuteDistance, towDistance }, rates, gasPrice, duration, timeSurgeCosts, towCosts, trailer]) => {
                this.refreshDriverDetails$.next(true);
                const payment = CostHelper.getPayment(commuteDistance, towDistance, rates, gasPrice, duration, this.datetime,
                    timeSurgeCosts, towCosts, trailer!);
                return this.isScheduleValid ? payment : { cost: 0 } as TowPayment;
            }),
            tap(x => console.log('payment', x)),
            distinctUntilKeyChanged('payment')
        );
        const vehicleLoading$ = this.store.select(VehicleStoreSelectors.getLoading).pipe(
            delay(LOADING_DELAY), // Allow some time for the spinner animation to finish
            tap(x => console.log('vehicleLoading', x))
        );
        const driverDetails$ = combineLatest([driverUser$, vehicle$, costPerMile$, milesAway$, payment$, vehicleLoading$]).pipe(
            filter(([user, vehicle, costPerMile, _milesAway, payment, vehicleLoading]) => {
                return this.isDriverDetailsDataReady(user, vehicle, costPerMile, payment, vehicleLoading);
            }),
            map(([user, vehicle, costPerMile, milesAway, payment]) => {
                const driverDetails: DriverDetails = { user, vehicle, costPerMile, milesAway, payment };
                this.driverDetailsMap.set(user.id, driverDetails);
                this.activeDriverDetails = driverDetails;
                return driverDetails;
            })
        );
        return driverDetails$;
    }

    private deleteDriverBusyTime(): void {
        const towRequestId = this.tow?.approvedRequest?.id;
        if (towRequestId) {
            this.store.dispatch(DriverBusyTimeStoreActions.deleteDriverBusyTimeRequest({ towRequestId }));
        }
    }
}
