<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Release of Liability</ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <main>
        <section>
            <h2>Risk Acknowledgment</h2>
            <p>By participating in a {{ trailer.type === 'Cargo' ? 'cargo' : 'towing' }} activity, I accept all risks, including those from negligence or travel conditions. I acknowledge that I do so entirely at my own risk, accepting both known and unknown risks.</p>

            <h2>Property Insurance</h2>
            <p>
                <ng-container *ngIf="experience === 'trailer'; else insuranceDriver">
                    I understand and acknowledge that the driver's personal auto insurance does not cover any damage incurred to my {{ trailer.type }}{{ trailer.type === 'Cargo' ? ' (' + trailer.description + ')' : '. Therefore, it is strongly advised that I carry my own ' + trailer.type + ' insurance to safeguard my assets during transportation activities' }}.
                </ng-container>
                <ng-template #insuranceDriver>
                    I understand and agree that I must have auto insurance that includes PIP (as required by state) and Liability Coverage. I also understand that my personal auto insurance won't cover damage to the towed {{ trailer.type }} unless I have proper commercial and cargo insurance.
                </ng-template>
            </p>

            <h2>Restricted Items</h2>
            <p>I understand and agree that I am not allowed to transport passengers, pets, animals, fireworks, drugs, weapons, fine art, or illegal items, and I will follow it at all times.</p>

            <h2>Medical Care</h2>
            <p>I understand and agree that I am responsible for my own medical expenses from injuries related to these activities. I acknowledge that Drive & Tow and its affiliates are not liable for any such expenses.</p>

            <h2>Cancellation Policy</h2>
            <p>By participating in a {{ trailer.type === 'Cargo' ? 'cargo' : 'towing' }} activity with Drive & Tow, I accept the cancellation policy. I must give at least {{ days }} days' notice to cancel after approval. If not, a {{ cancellationFee | currency }} cancellation fee will apply.</p>

            <h2>Duration of Agreement</h2>
            <p>This agreement is valid while I am participating in the {{ trailer.type === 'Cargo' ? 'cargo' : 'towing' }} activity.</p>

            <h2>Release and Discharge</h2>
            <form [formGroup]="form" (ngSubmit)="onAccept()">
                <mat-checkbox formControlName="accept" color="primary">
                    I have read and understand this release. I agree not to take legal action against Drive & Tow or its affiliates for any injury or property damage. By accepting, I consent and protect all parties from any legal claims related to the activities mentioned.
                </mat-checkbox>

                <ion-button type="submit" expand="block" [disabled]="form.invalid">Accept</ion-button>
                <div class="link" (click)="onClose()">Decline</div>
            </form>
        </section>
    </main>
</ion-content>
