<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Terms of Use</ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <main>
        <section>
            <p>
                You agree to receive recurring automated notification text (e.g., SMS and MMS) messages from Drive & Tow
                to the phone number in your account. All the different types of messages you may receive
                (whether on-demand reply messages, or otherwise) shall be known collectively as the "Service."
                Standard message and data rates may apply.
            </p>
            <p>
                Drive & Tow, its service providers, and supported mobile carriers are not responsible for delayed or undelivered messages.
            </p>
            <p>
                By using or participating in any of the "Service," you agree to these terms,
                including resolving disputes through individual arbitration without a jury trial
                and waiving the right to participate in class actions.
            </p>
        </section>
    </main>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-button type="button" expand="block" (click)="onClose()">
        Close
    </ion-button>
</ion-footer>
