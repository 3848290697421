import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RatingType } from '@enums/rating-type.enum';
import { ModalController } from '@ionic/angular';
import { Rating } from '@models/rating.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RatingStoreActions, RatingStoreSelectors } from 'src/app/root-store';

@Component({
    selector: 'app-trailer-map-driver-ratings',
    templateUrl: './trailer-map-driver-ratings.component.html',
    styleUrls: ['./trailer-map-driver-ratings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrailerMapDriverRatingsComponent implements OnInit {
    @Input() driverId: string;

    ratings$!: Observable<Rating[]>;

    constructor(
        private store: Store,
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.store.dispatch(RatingStoreActions.getRatingsRequest({ userId: this.driverId, ratingType: RatingType.Driver }));
        this.ratings$ = this.store.select(RatingStoreSelectors.getUserRatings(this.driverId, RatingType.Driver));
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
