import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { UtilityHelper } from '@classes/utility-helper';
import { Rating } from '@models/rating.model';

@Component({
    selector: 'app-ratings',
    templateUrl: './ratings.component.html',
    styleUrls: ['./ratings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingsComponent implements OnChanges {
    @Input() ratings: Rating[] = [];
    @Input() showDetails = false;
    @Input() userFormattedAddress?: string;

    avg!: number;
    viewDetails = false;

    ngOnChanges(): void {
        const numbers = this.ratings.map(x => x.rating);
        this.avg = UtilityHelper.getAverage(numbers);
    }
}
