<ng-container *ngIf="towDetails">
    <h2>{{ towDetails.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow' }} in Progress</h2>

    <div class="datetime-details">
        <span>{{ towDetails.tow.datetime | date:'M/d/y h:mm a' }}</span>
        <span class="link" (click)="onViewTowDetails()">Details</span>
    </div>

    <ion-spinner name="dots"></ion-spinner>

    <div class="chat-status">
        <div class="chat">
            <div class="count" *ngIf="chatUnreadMessagesCount ?? 0 > 0">{{ chatUnreadMessagesCount }}</div>
            <ion-button color="primary" shape="round" (click)="onOpenChat()">
                <i class="material-symbols-outlined">chat</i>
            </ion-button>
            <div class="link" (click)="onOpenChat()">Chat</div>
        </div>

        <ol>
            <li [class.done]="[3, 9, 4, 5, 6, 7, 8].includes(towDetails.tow.status)">
                <!-- DriverOnWay -->
                <div class="status">
                    <ion-icon [name]="[3, 9, 4, 5, 6, 7, 8].includes(towDetails.tow.status) ? 'checkmark-circle-outline' : 'ellipse-outline'"></ion-icon>
                    On the way
                </div>
            </li>
            <li [class.done]="[9, 4, 5, 6, 7, 8].includes(towDetails.tow.status)">
                <!-- PickedUp -->
                <div class="status">
                    <ion-icon [name]="[9, 4, 5, 6, 7, 8].includes(towDetails.tow.status) ? 'checkmark-circle-outline' : 'ellipse-outline'"></ion-icon>
                    {{ towDetails.tow.trailer.type === 'Cargo' ? 'Cargo picked up' : 'Trailer hitched' }}
                </div>
                <app-directions text="Directions" [geoData]="towDetails.distancesDurations.tow.start"
                    [color]="[1, 2, 3].includes(towDetails.tow.status) ? 'success' : 'primary'"></app-directions>
            </li>
            <li [class.done]="[4, 5, 6, 7, 8].includes(towDetails.tow.status)">
                <!-- TrailerOnWay -->
                <div class="status">
                    <ion-icon [name]="[4, 5, 6, 7, 8].includes(towDetails.tow.status) ? 'checkmark-circle-outline' : 'ellipse-outline'"></ion-icon>
                    {{ towDetails.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Trailer' }} on the way
                </div>
            </li>
            <li [class.done]="[5, 6, 7, 8].includes(towDetails.tow.status)">
                <!--- Delivered -->
                <div class="status">
                    <ion-icon [name]="[5, 6, 7, 8].includes(towDetails.tow.status) ? 'checkmark-circle-outline' : 'ellipse-outline'"></ion-icon>
                    {{ towDetails.tow.trailer.type === 'Cargo' ? 'Cargo delivered' : 'Trailer unhitched' }}
                </div>
                <app-directions text="Directions" [geoData]="towDetails.destination"
                    [color]="[4, 5, 6, 7, 8].includes(towDetails.tow.status) ? 'success' : 'primary'"></app-directions>
            </li>
        </ol>
    </div>

    <div class="btns">
        <ion-button *ngIf="[2, 3, 9, 4].includes(towDetails.tow.status)" expand="block" (click)="onChangeStatus(towDetails.tow.status)">
            <ng-container [ngSwitch]="towDetails.tow.status">
                <ng-container *ngSwitchCase="2"> <!-- Started -->
                    On my Way
                </ng-container>
                <ng-container *ngSwitchCase="3"> <!-- DriverOnWay -->
                    {{ towDetails.tow.trailer.type === 'Cargo' ? 'Cargo Picked Up' : 'Trailer Hitched' }}
                </ng-container>
                <ng-container *ngSwitchCase="9"> <!-- PickedUp -->
                    {{ towDetails.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Trailer' }} on the way
                </ng-container>
                <ng-container *ngSwitchCase="4"> <!-- TrailerOnWay -->
                    {{ towDetails.tow.trailer.type === 'Cargo' ? 'Cargo Delivered' : 'Trailer Unhitched' }}
                </ng-container>
            </ng-container>
        </ion-button>

        <div class="reminder" *ngIf="towDetails.tow.status === 5"> <!-- Delivered -->
            <strong>
                Wating for {{ towDetails.tow.trailer.type === 'Cargo' ? 'cargo delivery' : 'trailer unhitched' }} confirmation...
            </strong>
            <app-send-reminder [showTimer]="showTimer" (sendReminder)="onSendReminder()"></app-send-reminder>
        </div>

        <ion-button *ngIf="towDetails.tow.status === 2" expand="block" fill="outline" (click)="onCancel()">Cancel</ion-button> <!-- Started -->
    </div>
</ng-container>
