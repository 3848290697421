import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectHelper } from '@classes/object-helper';
import { AlertButton, AlertController, ModalController, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, firstValueFrom, interval, map, merge, Observable, switchMap,
    tap } from 'rxjs';
import { AllowedVehicleStoreSelectors, ChatStoreActions, ChatStoreSelectors, DataStoreSelectors, DisbursementStoreSelectors,
    DriverStoreSelectors, EarningStoreSelectors, EmailStoreSelectors, GasPriceStoreSelectors, NotificationStoreSelectors,
    PaymentStoreSelectors, PayoutStoreSelectors, RateStoreSelectors, RatingStoreSelectors, SMSStoreSelectors, SessionStoreSelectors,
    SupportStoreActions, SupportStoreSelectors, TowRequestStoreActions, TowRequestStoreSelectors, TowStoreActions,
    TowStoreSelectors, TrailerStoreSelectors, TransactionStoreSelectors, UserStoreActions, UserStoreSelectors,
    VehicleStoreSelectors } from 'src/app/root-store';
import { fade } from '@animations/fade.animation';
import { GeoHelper } from '@classes/geo-helper';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { Auth } from '@angular/fire/auth';
import { User } from '@models/user.model';
import { MissionComponent } from './shared/mission/mission.component';
import { LAST_ACCESS_DATE, LocalStorage, READ_MISSION } from '@classes/local-storage';
import { UtilityHelper } from '@classes/utility-helper';
import { CreditCardHelper } from '@classes/credit-card-helper';
import { TRAILER_EMITTED_TOW_REQUEST_STATUSES } from './root-store/tow-request-store/selectors';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { Actions, ofType } from '@ngrx/effects';
import { TowRequest } from '@models/tow-request.model';
import { DateHelper } from '@classes/date-helper';
import { DatePipe } from '@angular/common';
import { Support } from '@models/support.model';
import { VehicleSummaryPipe } from '@pipes/vehicle-summary.pipe';
import { App } from '@capacitor/app';
import { UtilityService } from '@services/utility.service';
import { TOW_IN_PROGRESS_STATUSES } from './root-store/tow-store/selectors';
import { ChatMessage } from '@models/chat-message.model';
import { Experience } from '@custom-types/experience.type';
import { UserService } from '@services/user.service';
import { TowService } from '@services/tow.service';
import { TowStatus } from '@enums/tow-status.enum';

const DESKTOP_MIN_WIDTH = 768; // Matches Ionic's "md" breakpoint and "$desktop-min-width" CSS variable

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fade]
})
export class AppComponent implements OnInit {
    private alerts: HTMLIonAlertElement[] = [];
    private displayedErrorMessages: string[] = [];
    private hideLoading$ = new BehaviorSubject(false);
    private emittedTowRequestNotifications: string[] = [];
    private emittedSupportNotifications: string[] = [];
    private emittedAcceptedTowRequestAlerts: string[] = [];
    private online$ = new BehaviorSubject(true);
    private userId$!: Observable<string>;
    private displayedChatMessageDates: string[] = [];

    loading$!: Observable<boolean>;

    // eslint-disable-next-line max-params
    constructor(
        private store: Store,
        private alertController: AlertController,
        private router: Router,
        private swUpdate: SwUpdate,
        private auth: Auth,
        private modalController: ModalController,
        private actions: Actions,
        private toastController: ToastController,
        private datePipe: DatePipe,
        private vehicleSummaryPipe: VehicleSummaryPipe,
        private utilityService: UtilityService,
        private userService: UserService,
        private towService: TowService
    ) {}

    async ngOnInit(): Promise<void> {
        this.userId$ = this.store.select(SessionStoreSelectors.getUserId).pipe(
            filter(userId => !!userId)
        );

        this.setOnline();
        this.desktopInit();
        this.setLoading();
        this.setErrorHandler();
        this.setLastActiveDate();
        this.showMission();
        this.preventLandscape();
        this.trackTowRequests();
        this.checkLastAccessDate();
        this.trackSupports();
        this.trackAcceptedTowRequests();
        this.setResume();
        this.setPause();
        this.trackTowChats();
        this.trackUserLocation();
        GeoHelper.loadGoogleMaps();
        await this.checkForUpdate();

        window.addEventListener('beforeinstallprompt', event => UtilityHelper.promptInstallEvent = event);
    }

    private setLoading(): void {
        this.loading$ = combineLatest([
            this.store.select(AllowedVehicleStoreSelectors.getLoading),
            this.store.select(ChatStoreSelectors.getLoading),
            this.store.select(DataStoreSelectors.getLoading),
            this.store.select(DisbursementStoreSelectors.getLoading),
            this.store.select(DriverStoreSelectors.getLoading),
            this.store.select(EarningStoreSelectors.getLoading),
            this.store.select(EmailStoreSelectors.getLoading),
            this.store.select(GasPriceStoreSelectors.getLoading),
            this.store.select(NotificationStoreSelectors.getLoading),
            this.store.select(PayoutStoreSelectors.getLoading),
            this.store.select(PaymentStoreSelectors.getLoading),
            this.store.select(RateStoreSelectors.getLoading),
            this.store.select(RatingStoreSelectors.getLoading),
            this.store.select(SessionStoreSelectors.getLoading),
            this.store.select(SMSStoreSelectors.getLoading),
            this.store.select(SupportStoreSelectors.getLoading),
            this.store.select(TowStoreSelectors.getLoading),
            this.store.select(TowRequestStoreSelectors.getLoading),
            this.store.select(TrailerStoreSelectors.getLoading),
            this.store.select(TransactionStoreSelectors.getLoading),
            this.store.select(UserStoreSelectors.getLoading),
            this.store.select(VehicleStoreSelectors.getLoading),
            CreditCardHelper.loading$,
            this.hideLoading$
        ]).pipe(
            map(([allowedVehicle, chat, data, disbursement, driver, earning, email, gasPrice, notification,
                payout, payment, rate, rating, session, sms, support, tow, towRequest, trailer, transaction,
                user, vehicle, creditCardFormLoading,
                hideLoading]) =>
                (allowedVehicle || chat || data || disbursement || driver || earning || email || gasPrice || notification ||
                payout || payment || rate || rating || session || sms || support || tow || towRequest || trailer || transaction ||
                user || vehicle || creditCardFormLoading) &&
                !hideLoading
            )
        );
    }

    private setErrorHandler(): void {
        merge(
            this.store.select(AllowedVehicleStoreSelectors.getError),
            this.store.select(ChatStoreSelectors.getError),
            this.store.select(DataStoreSelectors.getError),
            this.store.select(DisbursementStoreSelectors.getError),
            this.store.select(DriverStoreSelectors.getError),
            this.store.select(EarningStoreSelectors.getError),
            this.store.select(EmailStoreSelectors.getError),
            this.store.select(GasPriceStoreSelectors.getError),
            this.store.select(NotificationStoreSelectors.getError),
            this.store.select(PaymentStoreSelectors.getError),
            this.store.select(PayoutStoreSelectors.getError),
            this.store.select(RateStoreSelectors.getError),
            this.store.select(RatingStoreSelectors.getError),
            this.store.select(SessionStoreSelectors.getError),
            this.store.select(SMSStoreSelectors.getError),
            this.store.select(SupportStoreSelectors.getError),
            this.store.select(TowStoreSelectors.getError),
            this.store.select(TowRequestStoreSelectors.getError),
            this.store.select(TrailerStoreSelectors.getError),
            this.store.select(TransactionStoreSelectors.getError),
            this.store.select(UserStoreSelectors.getError),
            this.store.select(VehicleStoreSelectors.getError)
        ).pipe(
            filter((error: any) => !!error)
        ).subscribe(async message => await this.handleError(message));
    }

    private async handleError(error: any): Promise<void> {
        if (!error || ObjectHelper.isObjectEmpty(error)) {
            return;
        }

        if (error.code === 'permission-denied' || error.code === 'unavailable') {
            // If we get this error and we are in the home or login page, stop
            // Is cause we just logged out and something is still trying to pull some data
            if (this.router.url === '/' || this.router.url === '/login') {
                return;
            }

            // Otherwise, their session has expired
            setTimeout(async () => {
                await this.handleExpiredSession();
            }, 1000);
            return;
        }

        let message = error;

        if (error.stack && error.error) {
            message = error.error;
        }

        if (error.code) {
            message = error.code;
        }

        if (error.message) {
            message = error.message;
        }

        if (error.customData?.serverMessage) {
            message = error.customData.serverMessage;
        }

        // Skip error objects we don't know about
        if (typeof error === 'object') {
            return;
        }

        if (message === 'Missing or insufficient permissions.') {
            return;
        }

        if (!message) {
            return;
        }

        await this.showAlert('Error', message, 'OK');
    }

    private async handleExpiredSession(): Promise<void> {
        const message = 'Your session has expired. Please log back in.';
        const btn: AlertButton = {
            text: 'Login',
            handler: () => {
                this.router.navigate(['/login'], { replaceUrl: true });
                this.hideLoading$.next(false);
            }
        };
        await this.showAlert('Session Expired', message, btn, false);
        this.hideLoading$.next(true);
    }

    private async showAlert(header: string, message: string, btn: string | AlertButton, backdropDismiss = true): Promise<void> {
        if (this.displayedErrorMessages.includes(message)) {
            return;
        }

        this.displayedErrorMessages.push(message);

        await this.closeAlerts();

        const alert = await this.alertController.create({
            header,
            message,
            buttons: [btn],
            backdropDismiss
        });
        alert.onDidDismiss().then(() => this.displayedErrorMessages = []);
        this.alerts.push(alert);

        await alert.present();
    }

    private async closeAlerts(): Promise<void> {
        for (const alert of this.alerts) {
            await alert.dismiss();
        }

        this.alerts = [];
    }

    private desktopInit(): void {
        const observer = new ResizeObserver(entries => {
            const entry = entries[0];
            const isDesktop = entry.contentRect.width >= DESKTOP_MIN_WIDTH;
            if (isDesktop) {
                this.addDesktopDatePickerStyles();
            }
        });
        observer.observe(document.body);
    }

    private addDesktopDatePickerStyles(): void {
        const width = window.innerWidth;
        const datePickerWidth = 256;
        const datePickerMarginRight = 66;
        const adjustment = 71;
        const leftWidth = width - datePickerWidth - datePickerMarginRight - adjustment;
        const offset = leftWidth / 2;
        const css = `
            ion-popover {
                --offset-x: -${offset}px;
                --offset-y: -81px !important;
            }

            ion-popover[trigger$=-sort] {
                --offset-x: -${offset + 170}px;
                --offset-y: -11px !important;
            }
        `;
        UtilityHelper.addStyleTag('popover', css);
    }

    private async checkForUpdate(): Promise<void> {
        if (!environment.production) {
            return;
        }

        const update = await this.swUpdate.checkForUpdate();
        if (!update) {
            return;
        }

        const alert = await this.alertController.create({
            header: 'Update Available',
            message: 'A new version is available. Do you want to load it?',
            buttons: ['No', {
                text: 'Yes',
                handler: () => window.location.reload()
            }]
        });
        await alert.present();
    }

    private setLastActiveDate(): void {
        // The Google session lasts 1 hour. Let's run this every hour to see if the user is still active
        // If so, let's update the lastActiveDate property under user
        const time = 60 * 60 * 1000;
        combineLatest([
            this.userId$,
            interval(time)
        ]).subscribe(async ([id]) => {
            const token = await this.auth.currentUser?.getIdToken();
            if (!token) {
                return;
            }

            const properties: Partial<User> = {
                lastActiveDate: new Date()
            };
            this.store.dispatch(UserStoreActions.updateUserPropertiesRequest({ id, properties }));
        });
    }

    private showMission(): void {
        LocalStorage.get(READ_MISSION, 'boolean').pipe(
            filter(readMission => !readMission)
        ).subscribe(async () => {
            const modal = await this.modalController.create({
                component: MissionComponent
            });
            return await modal.present();
        });
    }

    private preventLandscape(): void {
        if (!UtilityHelper.isIOS()) {
            return;
        }

        this.fixOrientation();

        window.addEventListener('orientationchange', this.fixOrientation, true);
    }

    private fixOrientation(): void {
        document.body.classList.remove('rotate-left', 'rotate-right');

        switch (screen.orientation.type) {
            case 'landscape-primary':
                document.body.classList.add('rotate-right');
                break;
            case 'landscape-secondary':
                document.body.classList.add('rotate-left');
                break;
        }
    }

    private trackTowRequests(): void {
        // Trigger the tracking of tow request changes
        const vehicle$ = this.userId$.pipe(
            switchMap(userId => this.store.select(VehicleStoreSelectors.getActiveVehicle(userId)))
        );
        combineLatest([this.userId$, vehicle$]).pipe(
            filter(([userId, vehicle]) => !!userId && !!vehicle),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(([userId, vehicle]) => {
            this.store.dispatch(TowRequestStoreActions.getTowRequestsChangesRequest(
                { userId, vehicleId: vehicle!.id, statuses: TRAILER_EMITTED_TOW_REQUEST_STATUSES, notExpired: true }
            ));
        });

        // Handle get tow request changes success
        combineLatest([
            this.actions.pipe(ofType(TowRequestStoreActions.getTowRequestsChangesSuccess)),
            this.userId$
        ]).pipe(
            // Since this will trigger when we are creating/updating tow requests as a trailer
            // Let's filter so we only get the tow requests sent to us as a driver
            map(([props, userId]) => props.towRequests.filter(x => x.driverDetails.user.id === userId)),
            map(towRequests => this.removeExpiredTowRequests(towRequests)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(async towRequests => {
            await this.handleTowRequestsUpdates(towRequests);
        });
    }

    private removeExpiredTowRequests = (towRequests: TowRequest[]): TowRequest[] => {
        const activeTows = towRequests.filter(x => DateHelper.compareDates(x.tow.datetime, '>=', new Date()));
        return activeTows;
    };

    private async handleTowRequestsUpdates(towRequests: TowRequest[]): Promise<void> {
        towRequests.forEach(async towRequest => {
            const key = `${towRequest.id}|${towRequest.status}`;

            // Check if we have already shown this notification. if so, skip
            if (this.emittedTowRequestNotifications.includes(key)) {
                return;
            }

            const type = towRequest.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow';
            let header = '';
            const user = towRequest.tow.user;
            let message = `${user.firstName} ${user.lastName} has `;
            switch (towRequest.status) {
                case TowRequestStatus.Pending:
                    header = `New ${type} Request`;
                    message += 'sent you a request!';
                    break;
                case TowRequestStatus.Approved: {
                    header = `${type} Request Approved`;
                    message += 'approved the request!';
                    // If the tow status is passed "Started", skip
                    const tow$ = this.towService.getTow(towRequest.tow.id);
                    const tow = await firstValueFrom(tow$);
                    if (tow.status !== TowStatus.Started) {
                        return;
                    }
                    break;
                }
                case TowRequestStatus.Rejected:
                    message += 'rejected the request!';
                    break;
                default:
                    return; // Just in case (exclude other statuses)
            }

            if (towRequest.status === TowRequestStatus.Rejected) {
                await this.presentToast(message);
            } else {
                await this.showTowRequestAlert(header, message, towRequest);
            }

            this.emittedTowRequestNotifications.push(key);
        });
    }

    private async showTowRequestAlert(header: string, message: string, towRequest: TowRequest): Promise<void> {
        // Check if they have already seen this alert. If so, stop
        if (towRequest.statusAlertsSeen?.includes(towRequest.status)) {
            return;
        }

        const alert = await this.alertController.create({
            header,
            message,
            buttons: ['OK', {
                text: 'View Details',
                handler: () => {
                    const route = `/driver/map/${towRequest.driverDetails.vehicle.id}/${towRequest.id}`;
                    this.utilityService.navigate(route);
                }
            }]
        });
        await alert.present();
        await alert.onDidDismiss();

        // Record that they've seen this status alert
        const towRequests: TowRequest[] = [{ ...towRequest, statusAlertsSeen: [towRequest.status] }];
        this.store.dispatch(TowRequestStoreActions.updateTowRequestsRequest({ towRequests }));
    }

    private async presentToast(message: string) {
        const toast = await this.toastController.create({
            message,
            icon: 'checkmark',
            duration: 3000,
            buttons: ['OK']
        });
        await toast.present();
    }

    // Kill the session the first time the app opens each day in order to avoid issues with vehicles/trailer that may have expired
    private checkLastAccessDate(): void {
        LocalStorage.get(LAST_ACCESS_DATE, 'Date').pipe(
            filter(lastAccessDate => !!lastAccessDate && this.router.url !== '/' && this.router.url !== '/login'),
            map(lastAccessDate => lastAccessDate as Date)
        ).subscribe(async lastAccessDate => {
            const format = 'MM/dd/yyyy';
            const today = this.datePipe.transform(new Date(), format);
            const date = this.datePipe.transform(lastAccessDate, format);

            // If the dates are the same, keep them logged in
            if (date === today) {
                return;
            }

            // Otherwise, log them out
            await this.handleExpiredSession();
        });
    }

    private trackSupports(): void {
        // Trigger the tracking of supports changes
        combineLatest([
            this.store.select(SessionStoreSelectors.isAdmin),
            this.userId$
        ]).pipe(
            filter(([isAdmin]) => isAdmin)
        ).subscribe(([_, excludeUserId]) => {
            this.store.dispatch(SupportStoreActions.getSupportsChangesRequest({ excludeUserId, resolved: false }));
        });

        // Handle get supports changes success
        this.actions.pipe(ofType(SupportStoreActions.getSupportsChangesSuccess)).pipe(
            map(props => props.supports),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(supports => {
            this.handleSupportUpdates(supports);
        });
    }

    private handleSupportUpdates(supports: Support[]): void {
        const isSupportPage = this.router.url.includes('/admin/support/');

        supports.forEach(async support => {
            // Check if are already in the support page for this support request
            if (isSupportPage) {
                const supportId = this.router.url.split('/').at(-1);
                if (supportId === support.id) {
                    return;
                }
            }

            // Check if all messages have been read
            const unreadMessages = support.messages.filter(x => x.fromUser && !x.read);
            if (unreadMessages.length === 0) {
                return;
            }

            const message = unreadMessages[0].message;
            const key = `${support.id}|${message}`;

            // Check if we have already shown this notification. if so, skip
            if (this.emittedSupportNotifications.includes(key)) {
                return;
            }

            const header = 'New Support Message';
            const subHeader = `${support.user.firstName} ${support.user.lastName}`;
            await this.showSupportAlert(header, subHeader, message, support);
            this.emittedTowRequestNotifications.push(key);
        });
    }

    private async showSupportAlert(header: string, subHeader: string, message: string, support: Support): Promise<void> {
        const route = `/admin/support/${support.id}`;
        const alert = await this.alertController.create({
            header,
            subHeader,
            message,
            buttons: ['OK', {
                text: 'Respond',
                handler: () => this.router.navigate([route])
            }]
        });
        await alert.present();
    }

    private trackAcceptedTowRequests(): void {
        // Trigger the tracking of tow request changes for the tows in progress created by the logged-in user
        this.userId$.pipe(
            switchMap(userId => this.store.select(TowStoreSelectors.getTowsInProgress([], [], userId))),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(tows => {
            tows.forEach(tow => {
                this.store.dispatch(TowRequestStoreActions.getTowRequestsChangesRequest(
                    { towId: tow.id, statuses: [TowRequestStatus.Accepted], notExpired: true }
                ));
            });
        });

        // Handle get tow request changes success
        combineLatest([
            this.actions.pipe(ofType(TowRequestStoreActions.getTowRequestsChangesSuccess)),
            this.userId$
        ]).pipe(
            // Since this will trigger when we are accepting tow requests as a driver
            // Let's filter so we only get the tow requests sent to us as a trailer
            map(([props, userId]) => props.towRequests.filter(x => x.tow.user.id === userId &&
                x.status === TowRequestStatus.Accepted)),
            map(towRequests => this.removeExpiredTowRequests(towRequests)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(towRequests => {
            towRequests.forEach(async towRequest => await this.showAcceptedTowRequestAlert(towRequest));
        });
    }

    private async showAcceptedTowRequestAlert(towRequest: TowRequest): Promise<void> {
        // Check if we have already shown this alert. if so, skip
        if (this.emittedAcceptedTowRequestAlerts.includes(towRequest.id)) {
            return;
        }

        const driver = `${towRequest.driverDetails.user.firstName} ${towRequest.driverDetails.user.lastName} ` +
            `(${this.vehicleSummaryPipe.transform(towRequest.driverDetails.vehicle)})`;
        const type = towRequest.tow.trailer.type === 'Cargo' ? 'cargo' : 'tow';
        const message = `${driver} has accepted your ${type} request! Please approve.`;
        const alert = await this.alertController.create({
            header: 'Tow Request Accepted',
            message,
            buttons: [{
                text: 'View Requests',
                handler: async () => {
                    const route = `/trailer/map/${towRequest.tow?.trailer.id}/status`;
                    this.utilityService.navigate(route);
                }
            }]
        });
        await alert.present();
        this.emittedAcceptedTowRequestAlerts.push(towRequest.id);
    }

    private setResume(): void {
        App.addListener('resume', () => {
            // Only doing this in prod in order to not disrupt development process
            if (environment.production) {
                // If we are in the home, login, or register pages, stop. No need to refresh. Plus it causes issue with the
                // Google/Facebook login given those open a popup and when they close causes the refresh interrupting the login flow
                // Also, stop for pages where the camera could be used for it will cause the same issue
                let url = this.router.url;

                if (url.includes('/trailer/payload/') || url.includes('/driver/vehicle/')) {
                    url = url.substring(0, url.lastIndexOf('/'));
                }

                const exceptionRoutes = ['/', '/login', '/login/forgot-password', '/register', '/register/validate-email',
                    '/register/phone', '/register/validate-phone', '/register/address', '/account', '/driver/profile',
                    '/trailer/payload', '/driver/vehicle'];
                if (exceptionRoutes.includes(url)) {
                    return;
                }

                window.location.reload();
                return;
            };

            this.online$.next(true);
        });
    }

    private setPause(): void {
        App.addListener('pause', () => this.online$.next(false));
    }

    private setOnline(): void {
        combineLatest([
            this.userId$,
            this.online$
        ]).subscribe(([id, online]) => {
            const properties: Partial<User> = { online };
            this.store.dispatch(UserStoreActions.updateUserPropertiesRequest({ id, properties }));
        });
    }

    private trackTowChats(): void {
        // Get tows in progress
        this.userId$.subscribe(userId => {
            this.store.dispatch(TowStoreActions.getTowsRequest({ userId, statuses: TOW_IN_PROGRESS_STATUSES }));
        });
        const towsInProgress$ = this.userId$.pipe(
            switchMap(userId => this.store.select(TowStoreSelectors.getTowsInProgress(undefined, undefined, userId))),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        );
        const towsInProgressIds$ = towsInProgress$.pipe(
            map(tows => tows.map(x => x.id)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('towsInProgressIds', x))
        );

        // Get approved tow requests
        this.userId$.subscribe(userId => {
            this.store.dispatch(TowRequestStoreActions.getTowRequestsRequest({ userId, statuses: [TowRequestStatus.Approved] }));
        });
        const approvedTowRequests$ = this.userId$.pipe(
            switchMap(userId => this.store.select(TowRequestStoreSelectors.getApprovedTowRequests(userId))),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        );
        const approvedTowRequestsTowIds$ = approvedTowRequests$.pipe(
            map(towRequests => towRequests.map(x => x.tow.id)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('approvedTowRequestsTowIds', x))
        );

        // Trigger the tracking of chat changes
        merge(
            towsInProgressIds$,
            approvedTowRequestsTowIds$
        ).pipe(
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(towIds => {
            towIds.forEach(towId => {
                this.store.dispatch(ChatStoreActions.getChatsChangesByTowIdRequest({ towId }));
            });
        });

        const trailerApprovedRequests$ = combineLatest([
            towsInProgress$,
            this.userId$
        ]).pipe(
            filter(([tows, userId]) => tows.filter(x => x.user.id === userId && !!x.approvedRequest).length > 0),
            map(([tows]) => tows.map(x => x.approvedRequest!))
        );
        this.handleGetChatChangesSuccess(trailerApprovedRequests$, 'trailer');

        const driverTowRequests$ = combineLatest([
            approvedTowRequests$,
            this.userId$
        ]).pipe(
            filter(([towRequests, userId]) => towRequests.filter(x => x.driverDetails.user.id === userId).length > 0),
            map(([towRequests]) => towRequests)
        );
        this.handleGetChatChangesSuccess(driverTowRequests$, 'driver');
    }

    private handleGetChatChangesSuccess(towRequests$: Observable<TowRequest[]>, experience: Experience): void {
        combineLatest([
            towRequests$,
            this.userId$,
            this.actions.pipe(ofType(ChatStoreActions.getChatsChangesByTowIdSuccess))
        ]).pipe(
            map(([towRequests, userId, props]) => {
                const chat = props.chats[0];
                if (!chat) {
                    return null;
                }

                const towRequest = towRequests.find(x => x.tow.id === chat.towId);
                if (!towRequest) {
                    return null;
                }

                const message = chat.messages.filter(x => !x.read && x.userId !== userId).at(-1);
                if (!message) {
                    return null;
                }

                return { towRequest, message };
            }),
            filter(props => !!props),
            map(props => props!),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(async props => {
            await this.showChatLastUnreadMessage(props.towRequest, props.message, experience);
        });
    }

    private async showChatLastUnreadMessage(towRequest: TowRequest, message: ChatMessage, experience: Experience): Promise<void> {
        if (this.router.url.includes(`/${experience}/map`)) {
            return;
        }

        const date = message.creationDate.toString();
        if (this.displayedChatMessageDates.includes(date)) {
            return;
        }

        const path = experience === 'trailer' ?
            `${towRequest.tow.trailer.id}` :
            `${towRequest.driverDetails.vehicle.id}/${towRequest.id}`;
        const url = `/${experience}/map/${path}/chat`;
        let from = '';

        if (experience === 'trailer') {
            const driverDetails = towRequest.driverDetails;
            from = driverDetails ? ` from ${driverDetails.user.firstName} ${driverDetails.user.lastName} ` +
                `(${this.vehicleSummaryPipe.transform(driverDetails.vehicle)})` : '';
        } else {
            const user = towRequest.tow.user;
            from = ` from ${user.firstName} ${user.lastName}`;
        }
        const alert = await this.alertController.create({
            header: 'New Message',
            message: `You have a new message${from}.`,
            buttons: ['Close', {
                text: 'View Message',
                handler: () => this.router.navigate([url])
            }]
        });
        await alert.present();
        this.displayedChatMessageDates.push(date);
    }

    private trackUserLocation(): void {
        const user$ = this.userId$.pipe(
            switchMap(userId => this.store.select(UserStoreSelectors.getUser(userId))),
            filter(user => !!user)
        );
        this.userService.updateUserCurrentLocation(user$);
    }
}
