/* eslint-disable max-params */
/* eslint-disable default-case */
/* eslint-disable dot-notation */
import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { GeoHelper } from '@classes/geo-helper';
import { GeoData } from '@models/geo-data.model';
import { Store } from '@ngrx/store';
import { GeoService } from '@services/geo.service';
import { BehaviorSubject, combineLatest, delay, distinctUntilChanged, distinctUntilKeyChanged, filter, firstValueFrom,
    interval, map, merge, Observable, of, shareReplay, Subject, switchMap, take, tap, timer } from 'rxjs';
import { SessionStoreSelectors, UserStoreActions, UserStoreSelectors, TowStoreActions, TowRequestStoreSelectors,
    VehicleStoreSelectors, TowStoreSelectors, TowRequestStoreActions, VehicleStoreActions, RateStoreSelectors, RateStoreActions,
    NotificationStoreActions, NotificationStoreSelectors, TransactionStoreActions, TransactionStoreSelectors, PaymentStoreSelectors,
    PayoutStoreSelectors, GasPriceStoreSelectors, GasPriceStoreActions, SMSStoreActions, PayoutStoreActions, ChatStoreActions,
    ChatStoreSelectors, DriverStoreSelectors } from 'src/app/root-store';
import { MapComponent } from 'src/app/shared/map/map.component';
import { SubSink } from 'subsink';
import { RouteData } from '@models/route-data.model';
import { Marker } from '@models/marker.model';
import { TowDetails } from '@models/tow-details.model';
import { environment } from '@environments/environment';
import { User } from '@models/user.model';
import { Actions, ofType } from '@ngrx/effects';
import { Tow } from '@models/tow.model';
import { TowStatus } from '@enums/tow-status.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { TowRequest } from '@models/tow-request.model';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { AlertButton, AlertController, ModalController, ToastController } from '@ionic/angular';
import { Vehicle } from '@models/vehicle.model';
import { DriverMapTowRequestsComponent } from './driver-map-tow-requests/driver-map-tow-requests.component';
import { DistanceDuration } from '@models/distance-duration.model';
import { Distances } from '@models/distances.model';
import { DistancesDurations } from '@models/distances-durations.model';
import { CostHelper } from '@classes/cost-helper';
import { UtilityHelper } from '@classes/utility-helper';
import { ACTIVE_TOW_REQUEST_STATUSES, TRAILER_EMITTED_TOW_REQUEST_STATUSES } from 'src/app/root-store/tow-request-store/selectors';
import { DriverMapFullTowDetailsComponent } from './driver-map-full-tow-details/driver-map-full-tow-details.component';
import { ListHelper } from '@classes/list-helper';
import { RatingType } from '@enums/rating-type.enum';
import { DateHelper } from '@classes/date-helper';
import { VehicleSummaryPipe } from '@pipes/vehicle-summary.pipe';
import { RateType } from '@enums/rate-type.enum';
import { TowPayment } from '@models/tow-payment.model';
import { Notification } from '@models/notification.model';
import { VehicleIcon } from '@enums/vehicle-icon.enum';
import { TrailerIcon } from '@enums/trailer-icon.enum';
import { PaymentType } from '@enums/payment-type.enum';
import { TransactionType } from '@enums/transaction-type.enum';
import { TransactionStatus } from '@enums/transaction-status.enum';
import { Transaction } from '@models/transaction.model';
import { Payment } from '@models/payment.model';
import { CurrencyPipe } from '@angular/common';
import { ExpirationDateHelper } from '@classes/expiration-date-helper';
import { Expiration } from '@models/expiration.modal';
import { Payout } from '@models/payout.model';
import { NotificationService } from '@services/notification.service';
import { ObjectHelper } from '@classes/object-helper';
import { DataService } from '@services/data.service';
import { SMS } from '@models/sms.model';
import { DriverBusyTimeStoreActions, DriverBusyTimeStoreSelectors } from 'src/app/root-store/driver-busy-time-store';
import { DriverBusyTime } from '@models/driver-busy-time.model';
import { Filter } from '@models/filter.model';
import { DRIVER_BUSY_TIME_FILTER_SETS } from '@services/driver-busy-time.service';
import { Route } from '@models/route.model';
import { ReleaseOfLiabilityComponent } from 'src/app/shared/release-of-liability/release-of-liability.component';
import { DRIVER_MOVING_TOW_STATUSES, TOW_IN_PROGRESS_STATUSES } from 'src/app/root-store/tow-store/selectors';
import { Chat } from '@models/chat.model';
import { ChatMessage } from '@models/chat-message.model';
import { DriverMapChatComponent } from './driver-map-chat/driver-map-chat.component';
import { ChatUpdateRequest } from '@models/chat-update-request.model';
import { CurrentLocation } from '@models/current-location.model';
import { TrailerHelper } from '@classes/trailer-helper';
import { InsuranceCoverage } from '@models/insurance-coverage.model';
import { UserService } from '@services/user.service';
import { Rate } from '@models/rate.model';
import { DriverMapTowsComponent } from './driver-map-tows/driver-map-tows.component';

export const driverMapViewTowDetails$ = new BehaviorSubject(false);
export const YOUR_LOCATION = 'Your Location';
export const HOME = 'Home';
const DELAY = 700; // Must match transition in the scss file
const LOADING_DELAY = 500;
const RESET_DELAY = 1000;
const REOPEN_FULL_TOW_DETAILS_DELAY = 500;
const DRIVER_MARKER_PLOT_DELAY = 5000; // Needed because otherwise it doesn't show the driver marker
const ACTIVE_TOW_STATUSES = [
    TowStatus.Pending,
    TowStatus.Started,
    TowStatus.DriverOnWay,
    TowStatus.PickedUp,
    TowStatus.TrailerOnWay,
    TowStatus.Delivered
];

@Component({
    selector: 'app-driver-map',
    templateUrl: './driver-map.component.html',
    styleUrls: ['./driver-map.component.scss']
})
export class DriverMapComponent implements OnInit {
    @ViewChild('map') map!: MapComponent;

    private subs = new SubSink();
    private center!: GeoData;
    private mapLoaded$ = new Subject<boolean>(); // The map only loads once - no need to reset this on ionViewWillLeave
    private selectedMarker$ = new Subject<Marker | null>();
    private delay = 0;
    private isReadyToShowTowDetails$ = new Subject<boolean>();
    private refreshTowDetails$ = new BehaviorSubject<boolean>(true);
    private towDetailsMap = new Map<string, TowDetails>();
    private user!: User;
    private showTowDetails = false; // Used to denote the intention that the driver details should be open
    private vehicleId$!: Observable<string>;
    private vehicleIdTruthy$!: Observable<string>;
    private tow?: Tow;
    private towRequests: TowRequest[] = [];
    private emittedTowRequestNotifications: string[] = [];
    private selectedVehicle$ = new Subject<Vehicle | null>();
    private vehicle!: Vehicle;
    private refreshTow$ = new BehaviorSubject<boolean>(true);
    private towRequests$!: Observable<TowRequest[]>;
    private viewTowRequest$ = new EventEmitter<TowRequest>();
    private cancelTowRequest$ = new EventEmitter<TowRequest>();
    private towChangesIds: string[] = [];
    private changeTowRequestStatus$ = new EventEmitter<TowRequest>();
    private activeTowDetails?: TowDetails;
    private activeFullTowDetails?: TowDetails;
    private towDetailsModal?: HTMLIonModalElement;
    private approvedTowRequests$!: Observable<TowRequest[]>;
    private clearMarkers$ = new BehaviorSubject(false);
    private vehicles!: Vehicle[];
    private updateVehicle$ = new EventEmitter<Vehicle>();
    private changeVehicle$ = new EventEmitter<Vehicle>();
    private reopenFullTowDetails$ = new Subject<boolean>();
    private activeTowRequest?: TowRequest;
    private notificationsModal?: HTMLIonModalElement;
    private cancellationFee!: number;
    private trailerCancellationCompensation$!: Observable<number>;
    private trailerCancellationCompensation!: number;
    private authorizedSale?: Transaction;
    private towRequestsInProgress$!: Observable<TowRequest[]>;
    private towRequestId$!: Observable<string>;
    private payments: Payment[] = [];
    private showNoActiveVehicleAlert$ = new BehaviorSubject(true);
    private showExpiredDatesMessage$ = new Subject<{ vehicleId: string, expirations: Expiration[] }>();
    private payout?: Payout;
    private sentMessages = new Map<string, string>();
    private towRequest?: TowRequest;
    private acceptReleaseOfLiability$ = new EventEmitter<void>();
    private towRequest$ = new Subject<TowRequest>();
    private towDetailsTowRequest$!: Observable<TowRequest>;
    private towTruthy$!: Observable<Tow>;
    private chatChangesIds: string[] = [];
    private chatChangesTowIds: string[] = [];
    private updateChat = new EventEmitter<ChatUpdateRequest>();
    private isChatOpen$ = new BehaviorSubject(false);
    private isChatMessageAlertOpen$ = new BehaviorSubject(false);
    private displayedChatMessageDates: string[] = [];
    private sentChatMessages: string[] = [];
    private towUserId$!: Observable<string>;
    private trailerReadChatMessages: string[] = [];
    private action$!: Observable<'chat'>;
    private insuranceCoverages: InsuranceCoverage[] = [];
    private towStatus$!: Observable<TowStatus>;
    private userCurrentLocation$!: Observable<CurrentLocation>;
    private activeTow$ = new BehaviorSubject<Tow | undefined>(undefined);
    private towSearchComplete$ = new BehaviorSubject(false);
    private towMarkerCount$ = new Subject<number | null>();
    private rates$!: Observable<Rate[]>;
    private towsModal!: HTMLIonModalElement;
    private viewTowDetails$ = new EventEmitter<TowDetails>();

    user$!: Observable<User>;
    vehicle$!: Observable<Vehicle | null>;
    center$!: Observable<GeoData>;
    markers$!: Observable<Marker[]>;
    routeData$ = new BehaviorSubject<RouteData | null>(null);
    hideTow = true;
    showDistanceDuration = false;
    towDetails$!: Observable<TowDetails>;
    towRequestStatus$!: Observable<TowRequestStatus | undefined>;
    hideTowDetails = true; // Used to hide/show the driver details panel
    noTowDetails = true;
    radius = environment.radius.default;
    displayDefaultUserPhoto = false;
    tow$!: Observable<Tow | undefined>;
    vehicles$!: Observable<Vehicle[]>;
    approvedTowRequestTowDetails$!: Observable<TowDetails | undefined>;
    hasTowRequests = false;
    towRequestCount = 0;
    closeTow = false;
    cancellationFee$!: Observable<number>;
    showVehicleSelection = false;
    vehiclesWithTowRequestsInProgress = new Map<string, boolean>();
    vehicleExpiredErrors = new Map<string, boolean>();
    activeTowDetails$ = new Subject<TowDetails | null>();
    chat$!: Observable<Chat>;
    chatUnreadMessagesCount$!: Observable<number>;
    upcomingTowEstimatedStartDatetime$!: Observable<Date>;
    upcomingTowIsCargo$!: Observable<boolean>;
    pin$!: Observable<string>;
    towMarkers$ = new BehaviorSubject<Marker[]>([]);

    constructor(
        private store: Store,
        private geoService: GeoService,
        private changeDetectorRef: ChangeDetectorRef,
        private actions: Actions,
        private route: ActivatedRoute,
        private toastController: ToastController,
        private alertController: AlertController,
        private modalController: ModalController,
        private vehicleSummaryPipe: VehicleSummaryPipe,
        private notificationService: NotificationService,
        private router: Router,
        private currencyPipe: CurrencyPipe,
        private dataService: DataService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.getParams();
        this.getUser();
        this.getVehicles();
        this.getTowRequestsInProgress();
        this.setVehicle();
        this.setMarkers();
        this.setTow();
        this.setTowDetails();
        this.setNotifications();
        this.preloadIcons();
        this.setTimer();
    }

    ionViewWillEnter(): void {
        UtilityHelper.addScreenWidthSizeClassToElement('app-driver-map section');

        this.subs.add(
            // This will be used to show the notification icon next to vehicles (in the list) that have tow requests in progress
            this.towRequestsInProgress$.subscribe(towRequests => this.setVehiclesWithTowRequestsInProgress(towRequests)),

            // Get tows and other drivers in the area
            this.center$.subscribe(() => {
                this.getTows();
                this.getOtherDrivers();
            }),

            // If they pass a tow request ID in the URL, see if we should open the tow details
            combineLatest([
                this.towDetailsTowRequest$,
                this.userCurrentLocation$
            ]).pipe(
                // Only open if the status is 'Pending' or 'Accepted'
                filter(([towRequest]) => [TowRequestStatus.Pending, TowRequestStatus.Accepted].includes(towRequest.status)),
                // Delay needed to avoid multiple openings of the tow detail modal
                // Cause for some reason line 364 gets executed right after this does
                delay(REOPEN_FULL_TOW_DETAILS_DELAY * 2)
            ).subscribe(async ([towRequest, userCurrentLocation]) => await this.viewTowDetails(towRequest, userCurrentLocation)),

            // View tow details (triggered from the notifications component when we are already in the page)
            combineLatest([
                this.towDetailsTowRequest$,
                driverMapViewTowDetails$,
                this.userCurrentLocation$
            ]).pipe(
                filter(([_, view]) => !!view)
            ).subscribe(async ([towRequest, _view, userCurrentLocation]) => {
                await this.viewTowDetails(towRequest, userCurrentLocation);
                driverMapViewTowDetails$.next(false);
            }),

            // Trigger the tracking of tow request changes
            combineLatest([this.user$, this.vehicle$]).pipe(
                filter(([user, vehicle]) => !!user && !!vehicle)
            ).subscribe(([user, vehicle]) => {
                this.store.dispatch(TowRequestStoreActions.getTowRequestsChangesRequest(
                    { userId: user.id, vehicleId: vehicle!.id,
                        statuses: [...ACTIVE_TOW_REQUEST_STATUSES, TowRequestStatus.Archived, TowRequestStatus.Canceled] }
                ));
            }),

            // Get the rates for the current selected vehicle
            this.vehicle$.pipe(
                filter(vehicle => !!vehicle)
            ).subscribe(vehicle => {
                this.store.dispatch(RateStoreActions.getRatesRequest({ category: vehicle!.rateCategory }));
            }),

            // Trigger this here as well cause is needed in case they don't have a payment method on file
            this.cancellationFee$.subscribe(),

            this.trailerCancellationCompensation$.subscribe(),

            // Handle when there are no tow markers
            combineLatest([
                this.towMarkerCount$,
                this.towSearchComplete$,
                this.activeTow$,
                this.clearMarkers$
            ]).pipe(
                filter(([markerCount, towSearchComplete, activeTow, clearMarkers]) => markerCount !== null && markerCount === 0 &&
                    towSearchComplete && !activeTow && !clearMarkers),
                map(([markerCount]) => markerCount),
                distinctUntilChanged()
            ).subscribe(() => this.handleNoMarkers()),

            // Trigger the tracking of the approved tows
            this.approvedTowRequests$.pipe(
                map(approvedTowRequests => approvedTowRequests.map(x => x.tow))
            ).subscribe(tows => {
                console.log('approvedTows', tows);
                this.monitorTows(tows);
            }),

            // If they didn't send a vehicle ID in the route, fetch vehicles
            this.vehicleId$.pipe(
                filter(vehicleId => !vehicleId),
                switchMap(() => combineLatest([
                    this.vehicles$,
                    this.showNoActiveVehicleAlert$
                ])),
                filter(([_vehicles, showNoActiveVehicleAlert]) => showNoActiveVehicleAlert),
                map(([vehicles]) => vehicles)
            ).subscribe(vehicles => {
                console.log('vehicles', vehicles);

                // If they only have one vehicle, default to it (if is active)
                if (vehicles.length === 1) {
                    const vehicle = vehicles[0];
                    if (vehicle.active) {
                        this.selectedVehicle$.next(vehicle);
                        return;
                    }
                }

                const activeVehicle = vehicles.find(x => x.active);

                // Check if they have no active vehicle
                if (!activeVehicle) {
                    this.handleNoActiveVehicle();
                    return;
                }

                // If they do, auto select it - this eliminates the need for the vehicle list, but let's keep it just in case
                this.selectedVehicle$.next(activeVehicle);
            }),

            // Handle showing tow details
            this.isReadyToShowTowDetails$.pipe(
                filter(isReadyToShowTowDetails => isReadyToShowTowDetails)
            ).subscribe(() => {
                setTimeout(() => {
                    if (this.showTowDetails) {
                        this.noTowDetails = false;
                        this.hideTowDetails = false;
                    }

                    this.changeDetectorRef.detectChanges();
                }, this.delay);
            }),

            // Set the route for the tow in progress
            combineLatest([
                this.towTruthy$,
                this.userCurrentLocation$
            ]).pipe(
                distinctUntilChanged(([_prevTow, prevUserCurrentLocation], [_currTow, currUserCurrentLocation]) => {
                    return prevUserCurrentLocation.latitude === currUserCurrentLocation.latitude &&
                        prevUserCurrentLocation.longitude === currUserCurrentLocation.longitude;
                })
            ).subscribe(([tow, userCurrentLocation]) => {
                const start = userCurrentLocation;
                const destination = tow.routeData.destination;
                const route: Route = { start, destination };
                const stops = [tow.routeData.start];
                this.map?.setRoute(route, stops);
            }),

            // Get held payments associated with this tow
            this.towTruthy$.subscribe(tow => {
                this.store.dispatch(TransactionStoreActions.getTransactionsRequest({ userId: tow.user.id, towId: tow.id,
                    transactionType: TransactionType.Sale, status: TransactionStatus.Authorized }));
            }),

            // Get authorized transaction for this trip
            this.towTruthy$.pipe(
                switchMap(tow => this.store.select(TransactionStoreSelectors.getTransaction(tow.user.id, tow.id,
                    TransactionType.Sale, TransactionStatus.Authorized))),
                filter(transaction => !!transaction)
            ).subscribe(transaction => {
                this.authorizedSale = transaction;
            }),

            // Handle get tow changes success (for the tow in progress)
            combineLatest([
                this.actions.pipe(ofType(TowStoreActions.getTowChangesSuccess)),
                this.towTruthy$
            ]).pipe(
                filter(([props, tow]) => props.tow.id === tow.id)
            ).subscribe(async ([props]) => {
                const status = props.tow.status;
                const statusText = TowStatus[status].toLowerCase();
                const type = this.tow?.trailer.type === 'Cargo' ? 'Cargo' : 'Tow';
                let message = `${type} ${statusText}!`;

                this.sendTowNotificationAndSMS(props.tow);

                switch (status) {
                    // Skip these status notifications
                    case TowStatus.Pending:
                    case TowStatus.Started:
                    case TowStatus.DriverOnWay:
                    case TowStatus.PickedUp:
                    case TowStatus.TrailerOnWay:
                    case TowStatus.Delivered:
                    case TowStatus.Archived:
                        return;
                    case TowStatus.Rated:
                        message = 'Rating and tip submitted!';
                        break;
                }

                await this.presentToast(message);
            }),

            // Handle get tow requests changes success
            this.actions.pipe(ofType(TowRequestStoreActions.getTowRequestsChangesSuccess)).subscribe(props => {
                this.handleTowRequestsUpdates(props.towRequests);
            }),

            // Handle canceled tow
            combineLatest([
                this.towTruthy$,
                this.user$.pipe(
                    switchMap(user => this.store.select(TowRequestStoreSelectors.getCanceledTowRequests(user.id)))
                )
            ]).pipe(
                filter(([tow, towRequests]) => {
                    const canceledTowRequest = towRequests.find(x => x.tow.id === tow.id);
                    return !!canceledTowRequest;
                })
            ).subscribe(([_tow, towRequests]) => {
                this.unsetTow();
                this.deleteDriverBusyTimes(towRequests.map(x => x.id));
            }),

            // Handle save tow success
            this.actions.pipe(ofType(TowStoreActions.saveTowSuccess)).subscribe(props => {
                switch (props.tow.status) {
                    // If they are canceling the tow (it changes its status to 'Pending'),
                    // cancel the transaction, and charge cancellation fee
                    case TowStatus.Pending:
                        this.cancelPayment();
                        this.captureCancellationFee(props.tow);
                        break;
                }
            }),

            // Handle get tows success
            this.actions.pipe(ofType(TowStoreActions.getTowsNearBySuccess)).subscribe(props => {
                this.towSearchComplete$.next(true);
                this.monitorTows(props.tows);
            }),

            // Handle save tow request success
            this.actions.pipe(ofType(TowRequestStoreActions.saveTowRequestSuccess)).subscribe(async props => {
                this.refreshTowDetails$.next(true);
                const status = TowRequestStatus[props.towRequest.status].toLowerCase();
                let message = `Request ${status}!`;

                if (props.towRequest.status === TowRequestStatus.Available) {
                    const type = props.towRequest.tow.trailer.type === 'Cargo' ? 'cargo' : 'trailer';
                    message = `Message sent to ${type} owner!`;
                }

                await this.presentToast(message);

                if (props.towRequest.status === TowRequestStatus.Declined &&
                    (this.tow?.id === props.towRequest.tow.id || !this.tow)) {
                    this.unsetTow();
                }

                this.sendTowRequestNotificationAndSMS(props.towRequest);
                this.promptToAcceptNotifications();
            }),

            // Refresh the details modal if there is an active details and its tow, user, and vehicle matches a tow request
            this.actions.pipe(
                ofType(TowRequestStoreActions.getTowRequestsChangesSuccess),
                filter(() => !!this.activeFullTowDetails),
                map(props => {
                    const towRequest = props.towRequests.find(x => x.tow.id === this.activeFullTowDetails?.tow.id &&
                        x.driverDetails.user.id === this.user.id && x.driverDetails.vehicle.id === this.vehicle.id);
                    return towRequest;
                }),
                filter(towRequest => !!towRequest)
            ).subscribe(async towRequest => {
                // Stop and handle if the tow request has a status of 'Approved'
                if (towRequest?.status === TowRequestStatus.Approved) {
                    await this.handleApprovedTowRequest(towRequest);
                    return;
                }

                // Update the tow request associated with the active tow details and re-open it
                const towDetails = { ...this.activeFullTowDetails, towRequest } as TowDetails;
                await this.onViewTowDetails(towDetails);
            }),

            // Handle view tow request
            combineLatest([
                this.viewTowRequest$,
                this.userCurrentLocation$
            ]).subscribe(async ([towRequest, userCurrentLocation]) => await this.viewTowDetails(towRequest, userCurrentLocation)),

            // Handle cancel tow request
            this.cancelTowRequest$.subscribe(async towRequest => await this.cancelTowRequest(towRequest)),

            // Handle change tow request status
            this.changeTowRequestStatus$.subscribe(towRequest => {
                if ([TowRequestStatus.Accepted, TowRequestStatus.Available].includes(towRequest.status)) {
                    this.handleTowRequestAcceptanceOrAvailable(towRequest);
                    return;
                }

                this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest }));
            }),

            // Handle update vehicle emitter (triggered from the full tow details component)
            this.updateVehicle$.subscribe(vehicle => {
                this.store.dispatch(VehicleStoreActions.saveVehicleRequest({ vehicle }));
            }),

            // Handle save vehicle success (triggered from the full tow details component)
            this.actions.pipe(ofType(VehicleStoreActions.saveVehicleSuccess)).subscribe(async () => {
                await this.presentToast('Hitch size added to vehicle!');
            }),

            // Handle change vehicle emitter (triggered from the full tow details component)
            this.changeVehicle$.subscribe(async vehicle => {
                this.activeFullTowDetails = undefined; // Reset
                this.activeTowDetails$.next(null); // Reset
                this.selectedVehicle$.next(vehicle);
                await this.presentToast(`${this.vehicleSummaryPipe.transform(vehicle)} selected!`);

                // Delay needed to allow time for getTowRequestsChangesRequest to fetch a possible matching tow request
                setTimeout(() => this.reopenFullTowDetails$.next(true), REOPEN_FULL_TOW_DETAILS_DELAY);
            }),

            // Handle the reopening of the full tow details (triggered by changing the vehicle from the full tow details component)
            combineLatest([
                this.activeTowDetails$,
                this.reopenFullTowDetails$
            ]).pipe(
                filter(([towDetails, openFullTowDetails]) => !!towDetails && openFullTowDetails)
            ).subscribe(([towDetails]) => {
                this.onViewTowDetails(towDetails!);
                this.reopenFullTowDetails$.next(false); // Reset
            }),

            // Prompt to set the selected vehicle as the active vehicle
            combineLatest([
                this.vehicles$.pipe(
                    filter(vehicles => !!vehicles),
                    take(1)
                ),
                this.selectedVehicle$.asObservable().pipe(
                    filter(vehicle => !!vehicle && !vehicle.active) // Only do this if is not already active
                )
            ]).subscribe(([vehicles, vehicleToActivate]) => {
                this.promptToSetActiveVehicle(vehicles, vehicleToActivate!);
            }),

            // Handle the setting of the active vehicle
            this.actions.pipe(ofType(VehicleStoreActions.updateVehiclesSuccess)).subscribe(async props => {
                const vehicle = props.vehicles.find(x => x.active)!;
                await this.presentToast(`${this.vehicleSummaryPipe.transform(vehicle)} set as your active vehicle!`);
            }),

            // Trigger the search of tows once they have selected a vehicle
            this.selectedVehicle$.asObservable().pipe(
                filter(vehicle => !!vehicle)
            ).subscribe(() => this.getTows()),

            // Handle show expired dates message
            this.showExpiredDatesMessage$.pipe(
                distinctUntilKeyChanged('vehicleId'),
                filter(obj => !!obj.vehicleId)
            ).subscribe(obj => {
                this.showExpiredDatesMessage(obj.vehicleId, obj.expirations);
            }),

            // Get payment methods. Needed in case they try to cancel the tow request
            this.store.select(PaymentStoreSelectors.getPayments).subscribe(payments => this.payments = payments),

            // Get payout. Needed to notify the user they need a payout in order to get paid. Triggered when accepting a request
            this.user$.pipe(
                filter(user => !!user.payoutId),
                map(user => user.payoutId),
                switchMap(payoutId => this.store.select(PayoutStoreSelectors.getPayout(payoutId!)))
            ).subscribe(payout => {
                this.payout = payout;
            }),

            // Handle release of liability acceptance
            combineLatest([
                this.towRequest$,
                this.acceptReleaseOfLiability$
            ]).subscribe(([towRequest]) => {
                this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest }));
            }),

            // Handle create payout success
            this.actions.pipe(ofType(PayoutStoreActions.createPayoutSuccess)).subscribe(props => {
                window.location.href = props.payout.url;
            }),

            // Handle update chat
            this.updateChat.subscribe(chatUpdateRequest => {
                if (!chatUpdateRequest.chat.towId) {
                    chatUpdateRequest = { ...chatUpdateRequest, chat: { ...chatUpdateRequest.chat, towId: this.tow?.id } };
                }

                this.store.dispatch(ChatStoreActions.saveChatRequest(chatUpdateRequest));
            }),

            // Handle save chat success - start tracking chat changes for newly created chat
            this.actions.pipe(ofType(ChatStoreActions.saveChatSuccess)).subscribe(props => {
                const id = props.chat.id;

                // Check if haven't already dispatch these 'changes' requests - we don't wanna create multiple instances
                if (this.chatChangesIds.includes(id)) {
                    return;
                }

                this.store.dispatch(ChatStoreActions.getChatChangesRequest({ id }));
                this.chatChangesIds.push(id);
            }),

            // Track the user associated with the current tow in order to know their online status
            this.towUserId$.subscribe(id => {
                this.store.dispatch(UserStoreActions.getUserChangesRequest({ id }));
            }),

            // Handle update chat success - send push notification and SMS for each chat message if trailer owner is not online
            combineLatest([
                this.actions.pipe(ofType(ChatStoreActions.saveChatSuccess)),
                this.towUserId$.pipe(
                    switchMap(userId => this.store.select(UserStoreSelectors.getUserOnline(userId))),
                    distinctUntilChanged()
                )
            ]).pipe(
                filter(([{ message }, online]) => !!message && !online)
            ).subscribe(([{ message }]) => this.sendChatNotificationAndSMS(message!)),

            // Keep track of the chat messages that have already been read by the trailer
            // so we don't send push notification/SMS for those
            combineLatest([
                this.chat$,
                this.user$.pipe(
                    map(user => user.id),
                    distinctUntilChanged()
                )
            ]).pipe(
                map(([chat, userId]) => chat?.messages.filter(x => x.userId === userId && x.read) ?? [])
            ).subscribe(messages => this.setTrailerReadChatMessages(messages)),

            // Handle an action passed in the route
            this.action$.subscribe(async action => {
                switch (action) {
                    case 'chat':
                        await this.onOpenChat();
                        break;
                }
            }),

            // Display the last unread message from the chat
            combineLatest([
                this.user$,
                this.chat$
            ]).pipe(
                delay(500), // Delay needed to avoid showing this alert when the url param action is chat
                switchMap(([user, chat]) => combineLatest([
                    this.towTruthy$,
                    this.store.select(ChatStoreSelectors.getLastUnreadMessage(chat.id, user.id)),
                    this.isChatOpen$,
                    this.isChatMessageAlertOpen$
                ]))
            ).pipe(
                filter(([_, message, isChatOpen, isChatMessageAlertOpen]) => !!message && !isChatOpen && !isChatMessageAlertOpen),
                distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
            ).subscribe(async ([tow, message]) => await this.showChatLastUnreadMessage(tow, message!)),

            this.dataService.getTrailerInsuranceCoverages().subscribe(insuranceCoverages => {
                this.insuranceCoverages = insuranceCoverages;
            }),

            // Track logged-in user in order to get updates on their current location
            this.user$.subscribe(({ id }) => {
                this.store.dispatch(UserStoreActions.getUserChangesRequest({ id }));
            }),

            // Update user's current location if in the middle of an active tow
            combineLatest([
                this.towStatus$,
                interval(environment.towInProgressDriverLocationUpdateThreshold * 60 * 1000)
            ]).pipe(
                filter(([status]) => DRIVER_MOVING_TOW_STATUSES.includes(status))
            ).subscribe(() => {
                this.userService.updateUserCurrentLocation(this.user$);
            }),

            // Update user's current location based on the location deducted by the center logic
            combineLatest([
                this.center$,
                this.user$
            ]).pipe(
                filter(([center, user]) => center.latitude !== user.currentLocation?.latitude &&
                    center.longitude !== user.currentLocation?.longitude
                ),
                switchMap(([center, user]) => {
                    return combineLatest([
                        of(center),
                        of(user),
                        this.geoService.getAddress(center.latitude, center.longitude)
                    ]);
                })
            ).subscribe(([geoData, user, address]) => {
                const currentLocation: CurrentLocation = { ...geoData,
                    address: address.formatted,
                    lastUpdateDate: new Date()
                };
                const properties: Partial<User> = { currentLocation };
                this.store.dispatch(UserStoreActions.updateUserPropertiesRequest({ id: user.id, properties }));
            }),

            // Update the driver icon location when the tow is in progress (all statuses but 'Pending')
            combineLatest([
                this.towStatus$,
                this.userCurrentLocation$
            ]).pipe(
                filter(([status]) => DRIVER_MOVING_TOW_STATUSES.includes(status)),
                delay(DRIVER_MARKER_PLOT_DELAY)
            ).subscribe(([_, userCurrentLocation]) => {
                this.map.removeCenterMarker();
                this.map.addDriverMarker(userCurrentLocation!);
                this.map.updateDriverMarker(userCurrentLocation!);
            }),

            // Handle view tow details event (triggered by the tows modal)
            this.viewTowDetails$.subscribe(async towDetails => await this.onViewTowDetails(towDetails)),

            // Set the selected vehicle to the one associated to the id passed in the URL (if any)
            // Note: Doing this so it can trigger the prompt to set as the active vehicle
            // Note: For some very strange reason this needs to be the last subcription
            // Otherwise this.selectedVehicle$ won't trigger in other subscriptions
            this.vehicleIdTruthy$.pipe(
                distinctUntilChanged(),
                switchMap(id => this.store.select(VehicleStoreSelectors.getVehicle(id))),
                distinctUntilKeyChanged('id')
            ).subscribe(vehicle => {
                this.selectedVehicle$.next(vehicle);
            })
        );
    }

    ionViewWillLeave(): void {
        // Unsubscribe
        this.subs.unsubscribe();
        this.store.dispatch(TowRequestStoreActions.unsubscribeTowRequestsChangesRequest());
        this.store.dispatch(TowStoreActions.unsubscribeTowChangesRequest());
        // this.store.dispatch(ChatStoreActions.unsubscribeChatChangesRequest()); // TODO: Put back once keys are in place
        this.store.dispatch(UserStoreActions.unsubscribeUserChangesRequest());
        this.map?.unsubscribe();

        // Reset subjects
        this.selectedMarker$.next(null);
        this.isReadyToShowTowDetails$.next(false);
        this.refreshTowDetails$.next(true);
        this.selectedVehicle$.next(null);
        this.refreshTow$.next(true);
        this.clearMarkers$.next(false);
        this.activeTowDetails$.next(null);
        this.reopenFullTowDetails$.next(false);
        this.routeData$.next(null);
        this.showNoActiveVehicleAlert$.next(true);
        this.towSearchComplete$.next(false);
        this.towMarkerCount$.next(null);
        this.activeTow$.next(undefined);
        this.towMarkers$.next([]);
    }

    onMapLoaded(): void {
        this.mapLoaded$.next(true);
    }

    onRouteSet(routeData: RouteData): void {
        this.routeData$.next(routeData);

        if (!this.tow || this.tow.status === TowStatus.Pending) {
            this.hideTow = true;
        }

        setTimeout(() => {
            this.showDistanceDuration = true;
            this.changeDetectorRef.detectChanges();
        });
    }

    onCenterChanged(center: GeoData): void {
        this.center = center;
    }

    onToggleTow(): void {
        this.hideTow = !this.hideTow;
        this.changeDetectorRef.detectChanges();
    }

    onSelectedMarker(marker: Marker): void {
        // If they clicked on the same marker and the details are already opened, collapse details and stop
        if (this.activeTowDetails?.tow.id === marker.id && !this.hideTowDetails) {
            this.hideTowDetails = true;
            this.changeDetectorRef.detectChanges();
            return;
        }

        this.delay = this.hideTowDetails || this.noTowDetails ? 0 : DELAY;
        this.hideTowDetails = true;
        this.showTowDetails = true;

        setTimeout(() => {
            console.log('marker', marker);
            this.selectedMarker$.next(marker);
            this.isReadyToShowTowDetails$.next(true);
            this.displayDefaultUserPhoto = false;
        }, this.delay);
    }

    onToggleTowDetails(): void {
        // If the details are already showing stop the active marker animation
        if (!this.hideTowDetails) {
            this.map.stopActiveMarkerAnimation();
        } else { // Otherwise, start the animation
            this.map.startActiveMarkerAnimation();
        }

        this.hideTowDetails = !this.hideTowDetails;
        this.showTowDetails = !this.showTowDetails;
    }

    async onViewTowDetails(towDetails: TowDetails) {
        await this.towDetailsModal?.dismiss();
        this.towDetailsModal = await this.modalController.create({
            component: DriverMapFullTowDetailsComponent,
            componentProps: {
                towDetails,
                user: this.user,
                vehicle: this.vehicle,
                vehicles: this.vehicles,
                changeTowRequestStatus: this.changeTowRequestStatus$,
                updateVehicle: this.updateVehicle$,
                changeVehicle: this.changeVehicle$,
                cancelTowRequest: this.cancelTowRequest$
            }
        });

        // Reset activeTowDetails when the modal closes
        this.towDetailsModal.onDidDismiss().then(() => this.activeFullTowDetails = undefined);

        await this.towDetailsModal.present();
        this.activeFullTowDetails = towDetails;
    }

    async onShowNotifications() {
        this.notificationsModal = await this.modalController.create({
            component: DriverMapTowRequestsComponent,
            componentProps: {
                towRequests$: this.towRequests$,
                activeTow$: this.activeTow$,
                viewTowRequest: this.viewTowRequest$,
                cancelTowRequest: this.cancelTowRequest$
            }
        });
        return await this.notificationsModal.present();
    }

    onSelectVehicle(vehicle: Vehicle): void {
        this.selectedVehicle$.next(vehicle);
    }

    async onCancelTow(): Promise<void> {
        const towRequest = this.getActiveTowRequest(this.towRequests, this.tow);
        if (!towRequest) {
            // This shouldn't really happen, but in case it does just change the tow status
            await this.changeStatus(TowStatus.Pending);
            return;
        }

        await this.cancelTowRequest(towRequest);
    }

    async onChangeStatus(status: TowStatus): Promise<void> {
        await this.changeStatus(status);
    }

    onUnsetTow(): void {
        this.unsetTow();
        this.archiveTowRequest();

        const towRequestId = this.activeTowRequest?.id;
        if (towRequestId) {
            this.deleteDriverBusyTimes([towRequestId]);
        }
    }

    async onSendReminder(status: TowStatus): Promise<void> {
        const towRequest = this.towRequest;
        if (!towRequest) {
            return;
        }

        const type = towRequest.tow.trailer.type === 'Cargo' ? 'cargo' : 'tow';
        const driver = `${towRequest.driverDetails.user.firstName} ${towRequest.driverDetails.user.lastName} ` +
            `(${this.vehicleSummaryPipe.transform(towRequest.driverDetails.vehicle)})`;
        const message = `${driver} has completed your ${type} request. ${status === TowStatus.Delivered ?
            `Please confirm ${type === 'cargo' ? 'cargo delivery' : 'trailer unhitched'}` : 'Please rate and tip'}.`;
        this.sendTowRequestNotification(towRequest, message);
        this.sendTowRequestSMS(towRequest, message);

        await this.presentToast(`Reminder sent to ${type === 'cargo' ? 'cargo' : 'trailer'} owner!`);
    }

    onReCenterMap(): void {
        this.map?.reCenterMap();
    }

    async onCancelTowRequest(towRequest: TowRequest): Promise<void> {
        await this.cancelTowRequest(towRequest);
    }

    async onOpenChat(): Promise<void> {
        const modal = await this.modalController.create({
            component: DriverMapChatComponent,
            componentProps: {
                chat$: this.chat$,
                userId: this.user.id,
                updateChat: this.updateChat
            }
        });
        await modal.present();
        this.isChatOpen$.next(true);
        modal.onDidDismiss().then(() => this.isChatOpen$.next(false));
    }

    async onOpenTows(): Promise<void> {
        const towDetailsList$ = this.towMarkers$.pipe(
            switchMap(markers => {
                const list$: Observable<TowDetails>[] = [];
                markers.forEach(marker => {
                    const marker$ = of(marker);
                    const towDetails$ = this.getTowDetails(marker$);
                    list$.push(towDetails$);
                });
                return combineLatest(list$);
            }),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('towDetailsList', x))
        );
        this.towsModal = await this.modalController.create({
            component: DriverMapTowsComponent,
            componentProps: {
                towDetailsList$: towDetailsList$,
                viewTowDetails: this.viewTowDetails$,
                cancelTowRequest: this.cancelTowRequest$
            }
        });
        await this.towsModal.present();
    }

    private unsetTow(): void {
        this.hideTow = true;
        this.closeTow = true;
        this.noTowDetails = true;
        this.clearMarkers$.next(false);
        this.map?.clearRoute();
    }

    private archiveTowRequest(): void {
        let towRequest = this.getActiveTowRequest(this.towRequests, this.tow);
        if (!towRequest) {
            return;
        }

        towRequest = { ...towRequest, status: TowRequestStatus.Archived };
        this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest }));
    }

    private getActiveTowRequest(towRequests: TowRequest[], tow?: Tow): TowRequest | undefined {
        const towRequest = towRequests.find(x => x.tow.id === tow?.id);
        return towRequest;
    }

    private async changeStatus(status: TowStatus): Promise<void> {
        if (!this.tow) {
            return;
        }

        const driverOnWayRouteData = status === TowStatus.DriverOnWay ?
            await this.getDriverOnWayRouteData(this.tow) :
            this.tow.driverOnWayRouteData;
        const tow: Tow = { ...this.tow, status, driverOnWayRouteData };
        this.store.dispatch(TowStoreActions.saveTowRequest({ tow }));

        this.userService.updateUserCurrentLocation(this.user$);
    }

    private getParams(): void {
        const params$ = this.route.params.pipe(
            tap(x => console.log('params', x)),
            shareReplay()
        );
        this.vehicleId$ = params$.pipe(
            map(params => params?.['vehicleId']),
            distinctUntilChanged(),
            tap(x => console.log('vehicleId', x)),
            shareReplay()
        );
        this.vehicleIdTruthy$ = this.vehicleId$.pipe(
            filter(vehicleId => !!vehicleId),
            distinctUntilChanged(),
            tap(x => console.log('vehicleIdTruthy', x)),
            shareReplay()
        );
        this.towRequestId$ = params$.pipe(
            map(params => params?.['towRequestId']),
            filter(towRequestId => !!towRequestId),
            distinctUntilChanged(),
            tap(x => console.log('towRequestId', x)),
            shareReplay()
        );
        this.action$ = params$.pipe(
            map(params => params?.['action']),
            filter(action => !!action),
            distinctUntilChanged(),
            tap(x => console.log('action', x)),
            shareReplay()
        );
    }

    private getUser(): void {
        const userId$ = this.store.select(SessionStoreSelectors.getUserId);
        this.user$ = userId$.pipe(
            tap(x => console.log('userId', x)),
            switchMap(userId => this.store.select(UserStoreSelectors.getUser(userId))),
            tap(x => console.log('user', x)),
            filter(user => !!user),
            take(1),
            tap(user => this.user = user),
            shareReplay()
        );
        this.userCurrentLocation$ = this.user$.pipe(
            switchMap(user => {
                return combineLatest([
                    this.store.select(UserStoreSelectors.getUserCurrentLocation(user.id)),
                    of(user.address)
                ]);
            }),
            switchMap(([currentLocation, address]) => !!currentLocation ? of(currentLocation) : this.geoService.getGeoData(address))
        ).pipe(
            distinctUntilChanged((prev, curr) => prev.latitude === curr.latitude && prev.longitude === curr.longitude),
            tap(x => console.log('userCurrentLocation', x)),
            shareReplay()
        );
        this.pin$ = userId$.pipe(
            switchMap(userId => this.store.select(DriverStoreSelectors.getDriverActive(userId))),
            map(active => `vehicle-pin-${active ? '' : 'in'}active`),
            tap(x => console.log('pin', x)),
            distinctUntilChanged()
        );
    }

    private getVehicles(): void {
        this.vehicles$ = this.user$.pipe(
            switchMap(user => this.store.select(VehicleStoreSelectors.getVehicles(user.id))),
            tap(vehicles => {
                this.vehicles = vehicles;
                this.checkExpirationDates(vehicles);
            }),
            shareReplay()
        );
    }

    private setVehicle(): void {
        this.vehicle$ = merge(
            this.vehicleIdTruthy$.pipe(
                switchMap(id => this.store.select(VehicleStoreSelectors.getVehicle(id)))
            ),
            this.selectedVehicle$
        ).pipe(
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            map(vehicle => {
                if (!vehicle) {
                    return null;
                }

                // Check if this vehicle has an expired date
                const expirations = ExpirationDateHelper.checkExpirationDates(vehicle).filter(x => x.type === 'error');
                if (expirations.length > 0) {
                    // Note: Using subject to circunvent multiple triggers
                    this.showExpiredDatesMessage$.next({ vehicleId: vehicle.id, expirations });
                    return null;
                }

                console.log('vehicle', vehicle);
                this.vehicle = vehicle!;
                this.setHasTowRequests();
                this.hideTow = false;
                return vehicle;
            }),
            delay(0),
            shareReplay()
        );
        // Note: delay needed to avoid issue:
        // NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    }

    private setMarkers(): void {
        this.center$ = this.user$.pipe(
            switchMap(user => this.geoService.tryGetGeoData(user.address)),
            map(geoData => {
                this.center = geoData;
                return geoData;
            }),
            tap(x => console.log('center', x)),
            shareReplay()
        );
        const driverBusyTimes$ = this.user$.pipe(
            switchMap(user => this.store.select(DriverBusyTimeStoreSelectors.getDriversBusyTimes([user.id]))),
            tap(x => console.log('driversBusyTimes', x))
        );
        const otherDrivers$ = this.user$.pipe(
            map(user => user.id),
            distinctUntilChanged(),
            switchMap(userId => this.store.select(UserStoreSelectors.getOtherDriverUsers(userId)))
        );
        const tows$ = this.user$.pipe(
            switchMap(user => this.store.select(TowStoreSelectors.getTows(undefined, user.id))),
        );
        this.markers$ = combineLatest([
            tows$,
            this.mapLoaded$,
            this.clearMarkers$,
            driverBusyTimes$,
            otherDrivers$
        ]).pipe(
            filter(([_, mapLoaded]) => mapLoaded),
            map(([tows, _mapLoaded, clearMarkers, driverBusyTimes, otherDrivers]) => {
                const driverMarkers = otherDrivers.map(x => this.getDriverMarker(x));
                if (clearMarkers) {
                    this.towMarkers$.next([]);
                    this.towMarkerCount$.next(0);
                    return driverMarkers;
                }

                const activeTows = this.getActiveTows(tows);
                const nonConflictingTows = this.getNonConflictingTows(activeTows, driverBusyTimes);
                this.handlePossibleCanceledTow(nonConflictingTows);
                this.preloadUserPhotos(nonConflictingTows);
                const towMarkers = nonConflictingTows.map(x => this.getMarker(x));
                this.towMarkers$.next(towMarkers);
                this.towMarkerCount$.next(towMarkers.length);
                return [...driverMarkers, ...towMarkers];
            }),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay(),
            tap(x => console.log('markers', x))
        );
    }

    private getMarker(tow: Tow): Marker {
        const icon = `/assets/svg/${tow.trailer.type === 'Cargo' ? 'cargo' : 'trailer'}-pin.svg`;
        return {
            id: tow.id,
            geoData: tow.routeData.start,
            icon,
            zIndex: 1
        } as Marker;
    }

    private setTowDetails(): void {
        this.isReadyToShowTowDetails$.pipe(
            filter(isReadyToShowTowDetails => isReadyToShowTowDetails),
            tap(x => {
                console.log('isReadyToShowTowDetails', x);
                this.refreshTowDetails$.next(true);
            }),
            delay(RESET_DELAY),
            tap(() => this.isReadyToShowTowDetails$.next(false)) // Reset
        );
        this.refreshTowDetails$.pipe(
            filter(refreshTowDetails => refreshTowDetails),
            tap(x => {
                console.log('refreshTowDetails', x);
                this.isReadyToShowTowDetails$.next(true);
            }),
            delay(RESET_DELAY),
            tap(() => this.refreshTowDetails$.next(false)) // Reset
        );
        this.rates$ = this.store.select(RateStoreSelectors.getRates).pipe(
            filter(rates => rates?.length > 0),
            tap(x => console.log('rates', x)),
            shareReplay()
        );
        this.cancellationFee$ = combineLatest([
            this.rates$,
            this.vehicle$
        ]).pipe(
            map(([rates, vehicle]) => {
                const rate = rates.find(x => x.category === vehicle?.rateCategory && x.type === RateType.DriverCancellation);
                return rate?.cost ?? 0;
            }),
            tap(fee => this.cancellationFee = fee),
            distinctUntilChanged()
        );
        this.trailerCancellationCompensation$ = combineLatest([
            this.rates$,
            this.vehicle$
        ]).pipe(
            map(([rates, vehicle]) => {
                const rate = rates.find(x => x.category === vehicle?.rateCategory && x.type === RateType.TrailerCancellation);
                return (rate?.cost ?? 0) / 2;
            }),
            tap(fee => this.trailerCancellationCompensation = fee),
            distinctUntilChanged()
        );
        const marker$ = this.selectedMarker$.asObservable().pipe(
            filter(x => !!x),
            map(x => x!)
        );
        this.towDetails$ = this.getTowDetails(marker$);
    }

    private getTowDetails(marker$: Observable<Marker>): Observable<TowDetails> {
        const tow$ = marker$.pipe(
            filter(marker => !!marker),
            switchMap(marker => this.store.select(TowStoreSelectors.getTow(marker!.id))),
            filter(tow => !!tow),
            tap(x => console.log('setTowDetails.tow', x)),
            tap(tow => {
                // Stop if there is no activeFullTowDetails or the tow has a status of 'Started'
                if (!this.activeFullTowDetails || tow.status === TowStatus.Started) {
                    return;
                }

                // If there is an active tow details is cause the full tow details modal is open
                // so let's refresh it when the tow updates
                const towDetails = { ...this.activeFullTowDetails, tow };
                this.onViewTowDetails(towDetails);
            }),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay()
        );
        const towRequest$ = combineLatest([tow$, this.towRequests$, this.user$, this.vehicle$]).pipe(
            map(([tow, towRequests, user, vehicle]) => {
                const towRequest = towRequests.find(x => x.tow.id === tow.id && x.driverDetails.user.id === user.id &&
                    x.driverDetails.vehicle.id === vehicle?.id);
                this.towRequest = towRequest;
                return towRequest;
            }),
            tap(x => console.log('towRequest', x)),
            shareReplay()
        );
        const distancesDurations$ = combineLatest([tow$, towRequest$, this.center$]).pipe(
            switchMap(([tow, towRequest, center]) => {
                // If we have a tow request, get the data from there
                if (towRequest) {
                    const distancesDurations = UtilityHelper.getDistancesDurations(towRequest.commute, towRequest.tow.routeData);
                    return of(distancesDurations);
                }

                // Otherwise, get the data from point B to point C from the tow and calculate the commute data
                return GeoHelper.getRouteData(center, tow.routeData.start).pipe(
                    map(commute => {
                        const distancesDurations = UtilityHelper.getDistancesDurations(commute, tow.routeData);
                        return distancesDurations;
                    })
                );
            }),
            tap(x => console.log('distancesDurations', x)),
            shareReplay()
        );
        const totalDistanceDuration$ = distancesDurations$.pipe(
            map(distancesDurations => UtilityHelper.getTotalDistanceDuration(distancesDurations)),
            tap(x => console.log('totalDistanceDuration', x)),
            shareReplay()
        );
        const duration$ = totalDistanceDuration$.pipe(
            map(totalDistanceDuration => totalDistanceDuration.duration),
            tap(x => console.log('duration', x)),
            distinctUntilChanged()
        );
        const distances$ = distancesDurations$.pipe(
            map(distancesDurations => {
                return {
                    commuteDistance: distancesDurations.commute.distance,
                    towDistance: distancesDurations.tow.distance
                } as Distances;
            }),
            tap(x => console.log('distances', x)),
        );
        const state$ = tow$.pipe(
            map(tow => tow.routeData.start),
            filter(geoData => !!geoData),
            switchMap(geoData => this.geoService.getState(geoData!.latitude, geoData!.longitude)),
            filter(x => !!x?.name),
            tap(x => console.log('state', x)),
            distinctUntilKeyChanged('name'),
            shareReplay()
        );
        this.subs.add(
            state$.subscribe(state => this.store.dispatch(GasPriceStoreActions.getGasPriceRequest({ state: state.name })))
        );
        const gasPrice$ = state$.pipe(
            switchMap(state => this.store.select(GasPriceStoreSelectors.getGasPrice(state.name))),
            filter(gasPrice => !!gasPrice),
            map(gasPrice => gasPrice!),
            tap(x => console.log('gasPrice', x)),
            distinctUntilKeyChanged('name')
        );
        const payment$ = combineLatest([
            towRequest$,
            distances$,
            this.rates$,
            gasPrice$,
            duration$,
            tow$,
            this.dataService.getTimeSurgeCosts(),
            this.dataService.getTowCosts()
        ]).pipe(
            map(([towRequest, distances, rates, gasPrice, duration, tow, timeSurgeCosts, towCosts]) => {
                // If we have a tow request, get the payment from it
                if (towRequest) {
                    return towRequest.driverDetails.payment;
                }

                // Otherwise, calculate it
                const payment = CostHelper.getPayment(distances.commuteDistance, distances.towDistance, rates, gasPrice, duration,
                    tow.datetime, timeSurgeCosts, towCosts, tow.trailer);
                return payment;
            }),
            tap(x => console.log('payment', x)),
            distinctUntilKeyChanged('payment'),
            shareReplay()
        );
        const towLoading$ = this.store.select(TowStoreSelectors.getLoading).pipe(
            delay(LOADING_DELAY), // Allow some time for the spinner animation to finish
            tap(x => console.log('towLoading', x)),
        );
        const towDetails$ = combineLatest([tow$, towRequest$, distancesDurations$, totalDistanceDuration$, payment$, towLoading$,
            this.refreshTowDetails$, this.userCurrentLocation$]).pipe(
            filter(([tow, _towRequest, distancesDurations, totalDistanceDuration, payment, towLoading, refreshTowDetails]) =>
                this.canShowTowDetails(tow, distancesDurations, totalDistanceDuration, payment, towLoading, refreshTowDetails)),
            map(([tow, towRequest, distancesDurations, totalDistanceDuration, payment, _towLoading, _refreshTowDetails,
                userCurrentLocation]) => {
                const start = tow.driverOnWayRouteData?.start ?? userCurrentLocation;
                const destination = towRequest ? towRequest.tow.routeData.destination : distancesDurations.tow.destination;
                const { distance, duration } = totalDistanceDuration;
                const towDetails: TowDetails = {
                    tow,
                    payment,
                    distancesDurations,
                    towRequest,
                    start,
                    destination,
                    distance,
                    duration
                };
                this.towDetailsMap.set(tow.id, towDetails);
                return towDetails;
            }),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(towDetails => {
                this.activeTowDetails = towDetails;
                this.activeTowDetails$.next(towDetails);
            })
        );
        return towDetails$;
    }

    private setTow(): void {
        this.towRequests$ = this.user$.pipe(
            switchMap(user => combineLatest([
                this.store.select(TowRequestStoreSelectors.getTowRequestsInProgress(user.id)),
                this.store.select(TowRequestStoreSelectors.getArchivedTowRequests(user.id)),
                this.store.select(TowRequestStoreSelectors.getAvailableTowRequests(user.id))
            ])),
            map(([towRequestsInProgress, archivedTowRequests, availableTowRequests]) => {
                console.log('towRequestsInProgress', towRequestsInProgress);
                console.log('archivedTowRequests', archivedTowRequests);
                console.log('availableTowRequests', availableTowRequests);
                const archivedTowRequestsIds = archivedTowRequests.map(x => x.id);
                const temp = [...towRequestsInProgress, ...availableTowRequests];
                const towRequests = temp.filter(x => !archivedTowRequestsIds.includes(x.id));
                console.log('towRequests', towRequests);
                this.towRequests = towRequests;
                this.setHasTowRequests();
                return towRequests;
            }),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay()
        );
        this.approvedTowRequests$ = combineLatest([this.user$, this.vehicle$, this.towRequests$]).pipe(
            filter(([user, vehicle, towRequests]) => !!user && !!vehicle && !!towRequests),
            map(([user, vehicle, towRequests]) => {
                const approvedTowRequests = towRequests.filter(x =>
                    x.driverDetails.user.id === user.id &&
                    x.driverDetails.vehicle.id === vehicle!.id &&
                    x.status === TowRequestStatus.Approved);
                return approvedTowRequests;
            }),
            filter(towRequests => towRequests.length > 0),
            tap(x => console.log('approvedTowRequests', x)),
            shareReplay()
        );
        const time = 1000 * 60; // Check every minute
        const approvedTowRequestStartingSoon$ = combineLatest([
            this.approvedTowRequests$,
            timer(0, time)
        ]).pipe(
            map(([towRequests]) => this.getTowRequestStartingSoon(towRequests)),
            filter(towRequest => !!towRequest),
            map(towRequest => towRequest!),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(() => {
                this.refreshTowDetails$.next(true);
                this.hideTow = false;
            }),
            tap(x => console.log('towRequestStartingSoon', x))
        );
        this.tow$ = combineLatest([
            approvedTowRequestStartingSoon$,
            this.user$.pipe(
                switchMap(user => this.store.select(TowStoreSelectors.getTowsInProgress([TowStatus.Pending], [TowStatus.Archived],
                    undefined, user.id)))
            ),
            this.refreshTow$
        ]).pipe(
            map(([towRequest, tows, _refreshTow]) => {
                // Ensure we are looking at the tow associated with the most recent approved tow request
                const tow = tows.find(x => x.id === towRequest.tow.id);
                return { towRequest, tow };
            }),
            distinctUntilChanged((prev, curr) => {
                const towRequestEqual = ObjectHelper.areObjectsEqual(prev.towRequest, curr.towRequest);
                const towEqual = prev.tow?.status === curr.tow?.status;
                const equal = towRequestEqual && towEqual;
                return equal;
            }),
            tap(data => {
                if (data.tow) {
                    this.tow = data.tow!;
                    this.activeTowRequest = data.towRequest;
                    this.clearMarkers$.next(true);

                    // The following 4 lines enables the tow details
                    this.noTowDetails = false;
                    const marker = this.getMarker(this.tow);
                    this.selectedMarker$.next(marker);
                    this.isReadyToShowTowDetails$.next(true);

                    this.closeTow = false;
                    return;
                }

                this.tow = undefined;
                this.clearMarkers$.next(false);
            }),
            map(data => data.tow),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(tow => this.activeTow$.next(tow)),
            tap(x => console.log('tow$', x)),
            shareReplay()
        );
        this.towTruthy$ = this.tow$.pipe(
            filter(tow => !!tow),
            map(tow => tow!),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('towTruthy', x)),
            shareReplay()
        );
        this.towStatus$ = this.towTruthy$.pipe(
            map(tow => tow.status),
            distinctUntilChanged(),
            tap(x => console.log('towStatus', x)),
            shareReplay()
        );
        this.refreshTow$.pipe(
            filter(refreshTow => refreshTow),
            tap(x => console.log('refreshTow', x)),
            delay(RESET_DELAY),
            tap(() => this.refreshTow$.next(false)) // Reset
        );
        this.towDetailsTowRequest$ = combineLatest([
            this.towRequests$,
            this.towRequestId$
        ]).pipe(
            map(([towRequests, towRequestId]) => towRequests.find(x => x.id === towRequestId)),
            filter(towRequest => !!towRequest),
            map(towRequest => towRequest!),
            distinctUntilChanged((prev, curr) => prev.id === curr.id && prev.status === curr.status),
            tap(x => console.log('towDetailsTowRequest', x)),
            shareReplay()
        );
        this.chat$ = this.towTruthy$.pipe(
            switchMap(tow => this.store.select(ChatStoreSelectors.getChatByTowId(tow.id))),
            map(chat => chat ?? { messages: [] as ChatMessage[] } as Chat), // If we don't have a chat yet, create an empty one
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('chat', x)),
            delay(100),
            shareReplay()
        );
        // Note: delay needed to avoid issue:
        // NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
        this.chatUnreadMessagesCount$ = combineLatest([
            this.chat$,
            this.user$
        ]).pipe(
            switchMap(([chat, user]) => this.store.select(ChatStoreSelectors.getUnreadMessagesCount(chat.id, user.id))),
            distinctUntilChanged()
        );
        this.towUserId$ = this.towTruthy$.pipe(
            map(tow => tow.user.id),
            distinctUntilChanged()
        );
    }

    private setNotifications(): void {
        combineLatest([
            this.store.select(NotificationStoreSelectors.getToken),
            this.user$
        ]).pipe(
            filter(([token, user]) => !!token && !!user),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr))
        ).subscribe(([notificationToken, user]) => {
            user = { ...user, notificationToken };
            this.store.dispatch(UserStoreActions.saveUserRequest({ user }));
        });
    }

    private getTows(): void {
        if (!this.center) {
            return;
        }

        this.store.dispatch(TowStoreActions.getTowsNearByRequest(
            { center: this.center, radius: this.radius, excludeUserId: this.user.id, includeStatuses: ACTIVE_TOW_STATUSES }
        ));
        this.map?.setZoom(this.radius);
    }

    private setHasTowRequests(): void {
        // Note: setTimeout needed to avoid issue:
        // NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
        setTimeout(() => {
            this.hasTowRequests = this.towRequests.length > 0 && !!this.vehicle;
            this.towRequestCount = this.towRequests.length;
        });
    }

    private canShowTowDetails(tow: Tow, distancesDurations: DistancesDurations, totalDistanceDuration: DistanceDuration,
        payment: TowPayment, towLoading: boolean, refreshTowDetails: boolean): boolean {
        console.log(!!tow && !!distancesDurations && !!totalDistanceDuration && !!payment && !towLoading && refreshTowDetails,
            !!tow, !!distancesDurations, !!totalDistanceDuration, !!payment, !towLoading, refreshTowDetails);
        return !!tow && !!distancesDurations && !!totalDistanceDuration && !!payment && !towLoading && refreshTowDetails;
    }

    private async handleNoMarkers(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'No Tows Found',
            message: `There are no active tows currently needed in your area.
                You will be notified once a tow request is made.`,
            buttons: ['OK']
        });
        await alert.present();
    }

    private monitorTows(tows: Tow[]): void {
        tows.forEach(tow => {
            // Check if haven't already dispatch these 'changes' requests - we don't wanna create multiple instances
            if (this.towChangesIds.includes(tow.id)) {
                return;
            }

            this.store.dispatch(TowStoreActions.getTowChangesRequest({ id: tow.id }));
            this.towChangesIds.push(tow.id);
        });

        this.monitorChats(tows);
    }

    private monitorChats(tows: Tow[]): void {
        tows.forEach(tow => {
            // Check if haven't already dispatch these 'changes' requests - we don't wanna create multiple instances
            if (this.chatChangesTowIds.includes(tow.id)) {
                return;
            }

            this.store.dispatch(ChatStoreActions.getChatsChangesByTowIdRequest({ towId: tow.id }));
            this.chatChangesIds.push(tow.id);
        });
    }

    private async presentToast(message: string) {
        const toast = await this.toastController.create({
            message,
            icon: 'checkmark',
            duration: 3000,
            buttons: ['OK']
        });
        await toast.present();
    }

    private preloadIcons(): void {
        const vehicleIcons = ListHelper.getEnumList(VehicleIcon);
        const vehicleImgs = vehicleIcons.map(x => `/assets/svg/vehicle-${x}.svg`);

        const trailerIcons = ListHelper.getEnumList(TrailerIcon);
        const trailerImgs = trailerIcons.map(x => `/assets/svg/trailer-${x}.svg`);

        const imgs = [...vehicleImgs, ...trailerImgs];
        UtilityHelper.preloadImages(imgs);
    }

    private preloadUserPhotos(tows: Tow[]): void {
        const imgs = tows.filter(x => !!x.user.photo).map(x => x.user.photo!);
        UtilityHelper.preloadImages(imgs);
    }

    private handleTowRequestsUpdates(towRequests: TowRequest[]): void {
        const toastTowRequests = towRequests.filter(x => TRAILER_EMITTED_TOW_REQUEST_STATUSES.includes(x.status));
        toastTowRequests.forEach(async towRequest => {
            const key = `${towRequest.id}|${towRequest.status}`;

            // Check if we have already shown this notification. if so, skip
            if (this.emittedTowRequestNotifications.includes(key)) {
                return;
            }

            // Skip if tow is expired
            if (DateHelper.compareDates(towRequest.tow.datetime, '<', new Date())) {
                return;
            }

            const user = towRequest.tow.user;
            let message = `${user.firstName} ${user.lastName} has `;
            switch (towRequest.status) {
                case TowRequestStatus.Pending:
                    message += 'sent you a request!';
                    break;
                case TowRequestStatus.Approved:
                    message += 'approved the request!';

                    // Skip this toast notification if the tow status is neither "Pending" nor "Started"
                    if (this.tow && ![TowStatus.Pending, TowStatus.Started].includes(this.tow?.status)) {
                        return;
                    }
                    break;
                case TowRequestStatus.Rejected:
                    message += 'rejected the request!';
                    break;
            }

            await this.presentToast(message);
            this.emittedTowRequestNotifications.push(key);
        });

        const canceledTowRequests = towRequests.filter(x => x.status === TowRequestStatus.Canceled);
        canceledTowRequests.forEach(async towRequest => {
            await this.handleCanceledTow(towRequest);
        });
    }

    private handlePossibleCanceledTow(markers: Tow[]): void {
        const activeTowId = this.activeTowDetails?.tow.id;
        if (!activeTowId) {
            return;
        }

        const towIds = markers.map(x => x.id);
        if (towIds.includes(activeTowId)) {
            return;
        }

        // If we reached this point is cause the active tow id got canceled (and removed from the map) or it expired
        // So let's hide the driver details
        this.activeTowDetails = undefined;
        setTimeout(() => {
            this.hideTowDetails = true;
        }, DELAY + 100);
        setTimeout(() => {
            this.noTowDetails = true;
        }, DELAY * 2 + 100);
    }

    private removeExpiredTows(tows: Tow[]): Tow[] {
        const activeTows = tows.filter(x => DateHelper.compareDates(x.datetime, '>=', new Date()));
        return activeTows;
    }

    private sendTowRequestNotificationAndSMS(towRequest: TowRequest): void {
        switch (towRequest.status) {
            case TowRequestStatus.Accepted:
            case TowRequestStatus.Declined:
            case TowRequestStatus.Available:
                // Send these status notifications
                break;
            default:
                // Skip the rest
                return;
        }

        const statusText = TowRequestStatus[towRequest.status].toLowerCase();
        const type = towRequest.tow.trailer.type === 'Cargo' ? 'cargo' : 'tow';
        const driver = `${towRequest.driverDetails.user.firstName} ${towRequest.driverDetails.user.lastName} ` +
            `(${this.vehicleSummaryPipe.transform(towRequest.driverDetails.vehicle)})`;
        const message = towRequest.status === TowRequestStatus.Available ? `Driver, ${driver}, is available!` :
            `${driver} has ${statusText} your ${type} request!` +
            `${towRequest.status === TowRequestStatus.Accepted ? ' Please approve.' : ''}`;

        // Check if we already sent this message
        const towId = towRequest.tow.id;
        const messages = this.sentMessages.get(towId);
        if (messages?.includes(message)) {
            return;
        }

        this.sendTowRequestNotification(towRequest, message);
        this.sendTowRequestSMS(towRequest, message);
        this.sentMessages.set(towId, message);
    }

    private sendTowRequestNotification(towRequest: TowRequest, message: string): void {
        const userId = towRequest.tow.user.id;
        const type = towRequest.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow';
        const title = `${type} Request`;
        const notification: Notification = {
            userId,
            title,
            message,
            route: `/trailer/map/${towRequest.tow?.trailer.id}`
        };
        this.store.dispatch(NotificationStoreActions.pushNotificationRequest({ notification }));
    }

    private sendTowRequestSMS(towRequest: TowRequest, message: string): void {
        if (!towRequest.tow.user.smsOptIn) {
            return;
        }

        const sms: SMS = { message, phone: towRequest.tow.user.phone };
        this.store.dispatch(SMSStoreActions.sendSMSRequest({ sms }));
    }

    private sendTowNotificationAndSMS(tow: Tow): void {
        const type = tow.trailer.type === 'Cargo' ? 'cargo' : 'trailer';
        const driverDetails = tow.approvedRequest?.driverDetails;
        const driver = driverDetails ? `${driverDetails.user.firstName} ${driverDetails.user.lastName} ` +
            `(${this.vehicleSummaryPipe.transform(driverDetails.vehicle)})` : '';
        let message = `Driver, ${driver}, `;

        switch (tow.status) {
            case TowStatus.Canceled:
                if (tow.expired) {
                    return; // Skip if this was changed by the hourly job (expired === true)
                }

                message += 'has canceled!';
                break;
            case TowStatus.DriverOnWay:
                message += 'on the way!';
                break;
            case TowStatus.PickedUp:
                message += `${type === 'cargo' ? 'picked up' : 'hitched'} ${type}!`;
                break;
            case TowStatus.TrailerOnWay:
                message += `on the way with your ${type}!`;
                break;
            case TowStatus.Delivered:
                message += `${type === 'cargo' ? 'delivered' : 'unhitched'} ${type}!`;
                break;
            default:
                // Skip other statuses
                return;
        }

        // Check if we already sent this message
        const towId = this.activeTowRequest?.tow.id ?? '';
        const messages = [...this.sentMessages.get(towId) ?? [], ...tow.sentNotifications ?? []];
        if (messages?.includes(message)) {
            return;
        }

        // Track of sent messages for when they refresh the page and this notification had already been sent not to send it again
        const properties: Partial<Tow> = {
            sentNotifications: [...tow.sentNotifications ?? [], message]
        };
        this.store.dispatch(TowStoreActions.updateTowPropertiesRequest({ id: tow.id, properties }));

        const title = tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow';
        this.sendNotification(title, message);
        this.sendSMS(message);
        this.sentMessages.set(towId, message);
    }

    private sendNotification(title: string, message: string): void {
        const userId = this.activeTowRequest?.tow.user.id;
        if (!userId) {
            return;
        }

        const notification: Notification = {
            userId,
            title,
            message,
            route: `/trailer/map/${this.tow?.trailer.id}`
        };
        this.store.dispatch(NotificationStoreActions.pushNotificationRequest({ notification }));
    }

    private sendSMS(message: string): void {
        const phone = this.activeTowRequest?.tow.user.phone;
        if (!phone || !this.activeTowRequest?.tow.user.smsOptIn) {
            return;
        }

        const sms: SMS = { message, phone };
        this.store.dispatch(SMSStoreActions.sendSMSRequest({ sms }));
    }

    private async handleApprovedTowRequest(towRequest: TowRequest): Promise<void> {
        // Hide the notifications, tow details, and tows modals (if showing)
        await this.notificationsModal?.dismiss();
        await this.towDetailsModal?.dismiss();
        await this.towsModal?.dismiss();

        // Show tow details
        const marker = this.getMarker(towRequest.tow);
        this.onSelectedMarker(marker);

        // Expand tow details
        this.hideTowDetails = false;

        // Expand tow progress panel
        this.hideTow = false;
    }

    private promptToAcceptNotifications(): void {
        const message = 'We would like to send you notifications about new/existing tow requests and tows in progress.';
        const acceptFn = () => this.store.dispatch(NotificationStoreActions.requestPermissionRequest());
        this.notificationService.promptToAcceptNotifications(message, acceptFn);
    }

    private async promptToSetActiveVehicle(vehicles: Vehicle[], vehicleToActivate: Vehicle): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Change Active Vehicle',
            message: `Do you want to make ${this.vehicleSummaryPipe.transform(vehicleToActivate)} your active vehicle?`,
            buttons: ['No', {
                text: 'Yes',
                handler: () => this.setActiveVehicle(vehicles, vehicleToActivate)
            }]
        });
        await alert.present();
    }

    private setActiveVehicle(vehicles: Vehicle[], vehicleToActivate: Vehicle): void {
        const updatedVehicles: Vehicle[] = [{ ...vehicleToActivate, active: true }];

        // Inactivate other active vehicles
        vehicles.filter(x => x.id !== vehicleToActivate.id && x.active).forEach(x => {
            const inactiveVehicle = { ...x, active: false };
            updatedVehicles.push(inactiveVehicle);
        });

        this.store.dispatch(VehicleStoreActions.updateVehiclesRequest({ vehicles: updatedVehicles }));
    }

    private getActiveTows(tows: Tow[]): Tow[] {
        const activeTows = tows.filter(x => ACTIVE_TOW_STATUSES.includes(x.status));
        const pendingTows = activeTows.filter(x => x.status === TowStatus.Pending);
        const otherTows = activeTows.filter(x => x.status !== TowStatus.Pending);
        const nonExpiredPendingTows = this.removeExpiredTows(pendingTows);
        const aggregate = [...otherTows, ...nonExpiredPendingTows];
        return aggregate;
    }

    private cancelPayment(): void {
        if (!this.authorizedSale) {
            return;
        }

        this.store.dispatch(TransactionStoreActions.cancelRequest({ id: this.authorizedSale.id }));
    }

    private captureCancellationFee(tow: Tow): void {
        // Check if we are within the cancellation fee period
        const days = DateHelper.getDaysBetweenDates(new Date(), tow.datetime);
        if (days > environment.driverCancellationFeePeriod) {
            return;
        }

        const amount = this.cancellationFee;
        const towId = tow.id;
        const userId = this.user.id;
        const description = 'Driver Cancellation';
        const paymentType = PaymentType.DriverCancellation;
        this.store.dispatch(TransactionStoreActions.captureRequest({ amount, towId, userId, description, paymentType }));
    }

    private getTowRequestsInProgress(): void {
        this.towRequestsInProgress$ = this.user$.pipe(
            switchMap(user => this.store.select(TowRequestStoreSelectors.getTowRequestsInProgress(user.id)))
        );
    }

    private setVehiclesWithTowRequestsInProgress(towRequests: TowRequest[]): void {
        towRequests.forEach(towRequest => {
            this.vehiclesWithTowRequestsInProgress.set(towRequest.driverDetails.vehicle.id, true);
        });
    }

    private async viewTowDetails(towRequest: TowRequest, start: CurrentLocation): Promise<void> {
        const distancesDurations = UtilityHelper.getDistancesDurations(towRequest.commute, towRequest.tow.routeData);
        const tow: Tow = { ...(this.tow ?? towRequest.tow) }; // Update tow so it has the latest to status
        const towDetails: TowDetails = {
            payment: towRequest.driverDetails.payment,
            distance: towRequest.distance,
            duration: towRequest.duration,
            tow,
            distancesDurations,
            towRequest,
            start,
            destination: towRequest.tow.routeData.destination
        };
        await this.onViewTowDetails(towDetails);
    }

    private async handleTowRequestAcceptanceOrAvailable(towRequest: TowRequest): Promise<void> {
        // If they don't have any payment method, prompt to add one
        if (this.payments.length === 0) {
            const message = 'You don\'t have a payment method on file with us yet. Please add one and try again.';
            await this.promptMissingPaymentMethod(towRequest, message);
            return;
        }

        // Let's filter out the expired payment methods
        const nonExpiredPayments = this.payments.filter(x => !x.expired);

        // If they don't have a non-expired payment method, prompt to add one
        if (nonExpiredPayments.length === 0) {
            const message = 'You don\'t have a valid payment method on file with us. Please add one and try again.';
            await this.promptMissingPaymentMethod(towRequest, message);
            return;
        }

        // Check they have a default payment method. If they don't, prompt to set one
        const defaultPayment = nonExpiredPayments.find(x => x.default);
        if (!defaultPayment) {
            const message = 'You don\'t have a default payment method on file with us. Please select or add one and try again.';
            await this.promptMissingPaymentMethod(towRequest, message, 'Select/Add Payment Method', '/payment');
            return;
        }

        // If they have not created a payout account, prompt to add one
        if (!this.payout) {
            await this.showMissingDefaultPayout(towRequest);
            return;
        }

        const hasInsuranceCoverage = await this.checkTrailerInsuranceCoverages(towRequest);
        if (!hasInsuranceCoverage) {
            return;
        }

        // If we reach this point, they have a valid default payment method
        await this.showReleaseOfLiability(towRequest);
    }

    private async checkTrailerInsuranceCoverages(towRequest: TowRequest): Promise<boolean> {
        const type = towRequest.tow.trailer.type;
        if (type === 'Cargo') {
            return true;
        }

        if (TrailerHelper.trailerHasTrailerAndCargoCoverage(this.insuranceCoverages, towRequest.tow.trailer)) {
            return true;
        }

        const names = this.insuranceCoverages.filter(x => x.coversTrailerAndCargo).map(x => x.name);
        const subHeader = `No ${names.join(' or ')}`;
        const alert = await this.alertController.create({
            header: 'Warning',
            subHeader,
            message: `I understand and acknowledge that this ${type} does not carry property insurance coverage,
                and it is not required.`,
            buttons: ['Decline', {
                text: 'Accept',
                handler: async () => await this.showReleaseOfLiability(towRequest)
            }]
        });
        await alert.present();
        return false;
    }

    private async showReleaseOfLiability(towRequest: TowRequest): Promise<void> {
        towRequest = { ...towRequest, status: TowRequestStatus.Accepted };
        this.towRequest$.next(towRequest);
        const modal = await this.modalController.create({
            component: ReleaseOfLiabilityComponent,
            componentProps: {
                accept: this.acceptReleaseOfLiability$,
                experience: 'driver',
                cancellationFee: this.cancellationFee,
                trailer: towRequest.tow.trailer
            }
        });
        await modal.present();
    }

    private async promptMissingPaymentMethod(towRequest: TowRequest, subHeader: string, btnText = 'Add Payment Method',
        route = '/payment/new'): Promise<void> {
        const fee = this.currencyPipe.transform(this.cancellationFee);
        const message = `Note: A payment method is needed in case you need to cancel the request.
            A cancellation fee of ${fee} will be charged if you cancel within ${environment.driverCancellationFeePeriod} days
            of the pick-up date/time.`;
        const alert = await this.alertController.create({
            header: 'Missing Payment Method',
            subHeader,
            message,
            buttons: [{
                text: btnText,
                handler: async () => await this.closeModalsAndRedirect(route, towRequest)
            }]
        });
        await alert.present();
    }

    private async handleNoActiveVehicle(): Promise<void> {
        this.showNoActiveVehicleAlert$.next(false);

        const alert = await this.alertController.create({
            header: 'No Active Vehicle',
            message: 'You don\'t have any active vehicles. Please manage your vehicles and set one as active.',
            backdropDismiss: false,
            buttons: [{
                text: 'Manage Vehicles',
                handler: async () => {
                    await alert.dismiss();
                    this.router.navigate(['/driver']);
                }
            }]
        });
        await alert.present();
    }

    private async showExpiredDatesMessage(vehicleId: string, expirations: Expiration[]): Promise<void> {
        const errors = expirations.map(expiration => {
            const name = expiration.property.replace('ExpDate', '');
            return `${UtilityHelper.capitalize(name)} has expired.`;
        });
        const errorCount = errors.length;
        const header = `Expired Date${errorCount === 1 ? '' : 's'}`;
        const message = errorCount === 1 ? errors[0] : `<ul><li>${errors.join('</li><li>')}</li></ul>`;
        const buttons: AlertButton[] = [{
            text: 'Update Vehicle',
            handler: () => {
                const redirectRoute = encodeURI(`/vehicle/map/${vehicleId}`);
                this.router.navigate([`/driver/vehicle/${vehicleId}`, { redirectRoute }]);
            }
        }];

        const vehicleCount = this.vehicles.length;
        if (vehicleCount > 1) {
            buttons.splice(0, 0, {
                text: 'Select Other',
                role: 'cancel',
                cssClass: 'secondary'
            });
        }

        const alert = await this.alertController.create({
            header,
            message,
            buttons,
            backdropDismiss: vehicleCount > 1
        });
        alert.onDidDismiss().then(() => this.showExpiredDatesMessage$.next({ vehicleId: '', expirations: [] }));
        await alert.present();
    }

    private checkExpirationDates(vehicles: Vehicle[]): void {
        this.vehicleExpiredErrors.clear();

        vehicles.forEach(vehicle => {
            const expirations = ExpirationDateHelper.checkExpirationDates(vehicle).filter(x => x.type === 'error');
            expirations.forEach(expiration => {
                const key = `${vehicle.id}|${expiration.property}`;
                this.vehicleExpiredErrors.set(key, !!expiration.message);
            });
        });
    }

    private async showMissingDefaultPayout(towRequest: TowRequest): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Missing Payout',
            message: `In order to get paid for this and future tows, you need to have a payout account.
                Please create one and try again.`,
            buttons: [{
                text: 'Create Payout Account',
                handler: () => {
                    const route = this.getRedirectRoute(towRequest);
                    this.store.dispatch(PayoutStoreActions.createPayoutRequest({ userId: this.user.id, route }));
                }
            }]
        });
        await alert.present();
    }

    private async closeModalsAndRedirect(route: string, towRequest: TowRequest): Promise<void> {
        try {
            await this.notificationsModal?.dismiss();
            await this.towDetailsModal?.dismiss();
            await this.towsModal?.dismiss();
            await this.modalController?.dismiss();
        } catch (e) {} // On some scenarios modalController doesn't exist and it breaks when called here
        const redirectRoute = this.getRedirectRoute(towRequest);
        this.router.navigate([route, { redirectRoute }]);
    }

    private getRedirectRoute(towRequest: TowRequest): string {
        return encodeURI(`/driver/map/${towRequest.driverDetails.vehicle.id}/${towRequest.id}`);
    }

    private async handleCanceledTow(towRequest: TowRequest): Promise<void> {
        // Check if we are within the cancellation fee period
        if (!towRequest.tow.cancellationDate) {
            return;
        }

        // If the trailer cancelled the tow before the cancellation fee period, stop (don't show this alert)
        const days = DateHelper.getDaysBetweenDates(towRequest.tow.cancellationDate, towRequest.tow.datetime);
        if (days > environment.trailerCancellationFeePeriod) {
            return;
        }

        const user = towRequest.tow.user;
        const name = `${user.firstName} ${user.lastName}`;
        const compensation = this.currencyPipe.transform(this.trailerCancellationCompensation);
        const type = towRequest.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow';
        const header = `${type} Canceled`;
        const message = `${name} has canceled the ${type.toLowerCase()} request. ` +
            `You will be paid <strong>${compensation}</strong> for your inconvenience.`;
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [{
                text: 'OK',
                handler: () => {
                    towRequest = { ...towRequest, status: TowRequestStatus.Archived };
                    this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest }));
                }
            }]
        });
        await alert.present();
    }

    private getTowRequestStartingSoon(towRequests: TowRequest[]): TowRequest | undefined {
        const date = DateHelper.addMinutes(new Date(), environment.towStartingSoonTimeframe);
        const towRequest = towRequests.find(x => {
            const estimatedStartDatetime = x.estimatedDatetimes?.start ?
                DateHelper.convertTimestampToDate(x.estimatedDatetimes?.start) :
                x.tow.datetime;
            return DateHelper.compareDates(date, '>=', estimatedStartDatetime);
        });
        return towRequest;
    }

    private getNonConflictingTows(tows: Tow[], driverBusyTimes: DriverBusyTime[]): Tow[] {
        const conflictingTowIds: string[] = [];
        tows.forEach(tow => {
            const start = tow.datetime;
            const end = DateHelper.addSeconds(tow.datetime, tow.routeData.duration);
            DRIVER_BUSY_TIME_FILTER_SETS.forEach(set => {
                const filters: Filter[] = [
                    { ...set[0] as Filter, value: start },
                    { ...set[1] as Filter, value: end },
                    { field: 'towRequest.tow.id', operator: '!=', value: tow.id }
                ];
                const conflicts = ListHelper.filterList(driverBusyTimes, filters);
                if (conflicts.length > 0) {
                    conflictingTowIds.push(tow.id);
                }
            });
        });
        const nonConflictingTows = tows.filter(x => !conflictingTowIds.includes(x.id));
        return nonConflictingTows;
    }

    async cancelTowRequest(towRequest: TowRequest): Promise<void> {
        // Check if we are within the cancellation fee period
        const days = DateHelper.getDaysBetweenDates(new Date(), towRequest.tow.datetime);
        const withinCancellationFeePeriod = days <= environment.driverCancellationFeePeriod;

        const canCancelTow = TOW_IN_PROGRESS_STATUSES.includes(towRequest.tow.status) &&
            towRequest.tow.status !== TowStatus.Pending && withinCancellationFeePeriod;

        const fee = this.currencyPipe.transform(this.cancellationFee);
        const message = canCancelTow ?
            `There will be a cancellation fee of <strong>${fee}</strong>.` :
            'This request will be canceled.';
        const alert = await this.alertController.create({
            header: 'Confirm',
            subHeader: 'Are you sure?',
            message,
            buttons: [{
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary'
            }, {
                text: 'Yes',
                handler: () => {
                    if (canCancelTow) {
                        const tow = { ...towRequest.tow, status: TowStatus.Pending };
                        this.store.dispatch(TowStoreActions.saveTowRequest({ tow }));
                    }

                    towRequest = { ...towRequest, status: TowRequestStatus.Declined };
                    this.store.dispatch(TowRequestStoreActions.saveTowRequestRequest({ towRequest }));
                    this.store.dispatch(DriverBusyTimeStoreActions.deleteDriverBusyTimeRequest({
                        towRequestId: towRequest.id
                    }));
                }
            }]
        });
        await alert.present();
    }

    private async showChatLastUnreadMessage(tow: Tow, message: ChatMessage): Promise<void> {
        const date = message.creationDate.toString();
        if (this.displayedChatMessageDates.includes(date)) {
            return;
        }

        const alert = await this.alertController.create({
            header: 'New Message',
            message: `You have a new message from ${tow.user.firstName} ${tow.user.lastName}.`,
            buttons: ['Close', {
                text: 'View Message',
                handler: async () => await this.onOpenChat()
            }]
        });
        await alert.present();
        this.isChatMessageAlertOpen$.next(true);
        this.displayedChatMessageDates.push(date);
        alert.onDidDismiss().then(() => this.isChatMessageAlertOpen$.next(false));
    }

    private sendChatNotificationAndSMS(message: ChatMessage): void {
        // Check if we already sent/read this message
        const date = message.creationDate.toString();
        if (this.sentChatMessages?.includes(date) || this.trailerReadChatMessages.includes(date)) {
            return;
        }

        this.sendNotification('New Message', message.message);
        this.sendSMS(message.message);
        this.sentChatMessages.push(date);
    }

    private setTrailerReadChatMessages(messages: ChatMessage[]): void {
        const dates = messages.map(x => x.creationDate.toString());
        this.trailerReadChatMessages = ListHelper.removeDuplicatesFromList([...this.trailerReadChatMessages, ...dates]);
    }

    private async getDriverOnWayRouteData(tow: Tow): Promise<RouteData | undefined> {
        // Check if the user has approved to get the location
        const status = await firstValueFrom(GeoHelper.getLocationPromptStatus());
        if (status === 'denied') {
            return tow.driverOnWayRouteData;
        }

        const currentLocation$ = this.userService.getUserCurrentLocation();
        const currentLocation = await firstValueFrom(currentLocation$);
        const routeData$ = GeoHelper.getRouteData(currentLocation, tow.routeData.start);
        return firstValueFrom(routeData$);
    }

    private setTimer(): void {
        const towStartingSoonest$ = this.user$.pipe(
            switchMap(user => this.store.select(TowStoreSelectors.getTowStartingSoonest(user.id))),
            filter(tow => !!tow),
            map(tow => tow!),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('towStartingSoonest', x))
        );
        this.upcomingTowEstimatedStartDatetime$ = towStartingSoonest$.pipe(
            map(tow => DateHelper.convertTimestampToDate(tow.approvedRequest?.estimatedDatetimes?.start)),
            tap(x => console.log('upcomingTowEstimatedStartDatetime', x))
        );
        this.upcomingTowIsCargo$ = towStartingSoonest$.pipe(
            map(tow => tow.trailer.type === 'Cargo')
        );
    }

    private getDriverMarker(user: User): Marker {
        const icon = `/assets/svg/vehicle-pin-${user.driver?.active ? '' : 'in'}active.svg`;
        return {
            id: user.id,
            geoData: user.currentLocation,
            icon,
            unclickable: true
        } as Marker;
    }

    private getOtherDrivers(): void {
        this.store.dispatch(UserStoreActions.getUsersRequest(
            { center: this.center, radius: this.radius, excludeId: this.user.id, includeDriverProfile: true }
        ));
    }

    private deleteDriverBusyTimes(towRequestIds: string[]): void {
        towRequestIds.forEach(towRequestId => {
            this.store.dispatch(DriverBusyTimeStoreActions.deleteDriverBusyTimeRequest({ towRequestId }));
        });
    }
}
