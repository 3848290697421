import { RatingType } from '@enums/rating-type.enum';
import { Rating } from '@models/rating.model';
import { createAction, props } from '@ngrx/store';

// Get Ratings
export const getRatingsRequest = createAction(
    '[Rating] Get Ratings Request',
    props<{ userId?: string, ratingType?: RatingType }>()
);
export const getRatingsFailure = createAction(
    '[Rating] Get Ratings Failure',
    props<{ error: string }>()
);
export const getRatingsSuccess = createAction(
    '[Rating] Get Ratings Success',
    props<{ ratings: Rating[] }>()
);

// Get Rating
export const getRatingRequest = createAction(
    '[Rating] Get Rating Request',
    props<{ id: string }>()
);
export const getRatingFailure = createAction(
    '[Rating] Get Rating Failure',
    props<{ error: string }>()
);
export const getRatingSuccess = createAction(
    '[Rating] Get Rating Success',
    props<{ rating: Rating }>()
);

// Save Rating
export const saveRatingRequest = createAction(
    '[Rating] Save Rating Request',
    props<{ rating: Rating }>()
);
export const saveRatingFailure = createAction(
    '[Rating] Save Rating Failure',
    props<{ error: string }>()
);
export const saveRatingSuccess = createAction(
    '[Rating] Save Rating Success',
    props<{ rating: Rating }>()
);

// Clear Rating
export const clearRating = createAction(
    '[Rating] Clear Rating'
);
