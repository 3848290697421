import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UtilityHelper } from '@classes/utility-helper';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-bouncing-arrow',
    templateUrl: './bouncing-arrow.component.html',
    styleUrls: ['./bouncing-arrow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BouncingArrowComponent implements OnInit {
    direction = UtilityHelper.isIOS() ? 'down' : 'up';
    showBouncingArrow$!: Observable<boolean>;

    ngOnInit(): void {
        this.showBouncingArrow$ = UtilityHelper.showBouncingArrow$.asObservable();
    }
}
