<div class="summary">
    <div class="icon">
        <ion-avatar *ngIf="!displayDefaultPhoto; else defaultDriverPhoto">
            <img [src]="photo" (error)="onPhotoError()" referrerpolicy="no-referrer">
        </ion-avatar>
        <ng-template #defaultDriverPhoto>
            <mat-icon>person</mat-icon>
        </ng-template>
    </div>

    <div>
        <div class="name">{{ name }}</div>

        <div *ngIf="experience === 'driver'">
            <div class="icon-type">
                <i *ngIf="+tow.trailer.icon === 7; else icon" class="material-symbols-outlined">weight</i>
                <ng-template #icon>
                    <ion-icon [src]="'/assets/svg/trailer-' + tow.trailer.icon + '.svg'"></ion-icon>
                </ng-template>
                <div>{{ tow.trailer.type }}</div>
            </div>

            <div class="weight-length">{{ tow.trailer | trailerSummary }}</div>
        </div>

        <div *ngIf="experience === 'trailer'">
            <ion-icon class="vehicle-icon" [src]="'/assets/svg/vehicle-' + tow.approvedRequest?.driverDetails?.vehicle?.icon + '.svg'"></ion-icon><br>
            {{ tow.approvedRequest?.driverDetails?.vehicle! | vehicleSummary }}
        </div>
    </div>
</div>

<div class="details">
    <ng-container *ngIf="experience === 'driver'">
        <ion-icon class="vehicle-icon" [src]="'/assets/svg/vehicle-' + tow.approvedRequest?.driverDetails?.vehicle?.icon + '.svg'"></ion-icon><br>
        {{ tow.approvedRequest?.driverDetails?.vehicle! | vehicleSummary }}
    </ng-container>

    <ng-container *ngIf="experience === 'trailer'">
        <div class="icon-type">
            <i *ngIf="+tow.trailer.icon === 7; else icon" class="material-symbols-outlined">weight</i>
            <ng-template #icon>
                <ion-icon [src]="'/assets/svg/trailer-' + tow.trailer.icon + '.svg'"></ion-icon>
            </ng-template>
            <div>{{ tow.trailer.type }}</div>
        </div>

        <div class="weight-length">{{ tow.trailer | trailerSummary }}</div>
    </ng-container>
</div>

<app-tow-locations [driverAddress]="driverAddress" [start]="tow.routeData.start" [destination]="tow.routeData.destination"
    [isHome]="isDriverHomeAddress" driverAddressLabel="Starting Location"></app-tow-locations>

<app-tow-distances-durations *ngIf="distancesDurations" class="distance-duration" [distancesDurations]="distancesDurations"></app-tow-distances-durations>

<div class="payment">
    <div>
        <label>Payment</label>
        {{ payment | currency }}
    </div>
    <div>
        <label>Tip</label>
        {{ tip | currency }}
    </div>
    <div class="total">
        <label>Total</label>
        {{ total | currency }}
    </div>
</div>
