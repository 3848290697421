<div class="avg">
    <app-rating [rating]="avg"></app-rating>
    {{ avg | number : '1.0-2' }}
</div>

<ion-accordion-group [multiple]="true">
    <ion-accordion *ngFor="let rating of ratings" [readonly]="!(showDetails && rating.tow) && !rating.comments">
        <ng-container>
            <ion-item slot="header">
                <ion-label>
                    <app-rating [rating]="rating.rating"></app-rating>
                </ion-label>
                {{ rating.creationDate | date : 'M/d/y h:mm a' }}
            </ion-item>
            <div slot="content">
                @if (rating.comments) {
                    <p>{{ rating.comments }}</p>
                }

                @if (showDetails && rating.tow) {
                    <div class="link view-details" (click)="viewDetails = true">{{ rating.tow && rating.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow' }} Details</div>

                    <ion-modal [isOpen]="viewDetails">
                        <ng-template>
                            <ion-header>
                                <ion-toolbar color="secondary">
                                    <ion-title>{{ rating.tow && rating.tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow' }} Details</ion-title>

                                    <ion-buttons slot="end">
                                        <ion-button (click)="viewDetails = false">
                                            <i class="material-symbols-outlined">close</i>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                            </ion-header>

                            <ion-content fullscreen>
                                <main>
                                    <section>
                                        <app-tow-details experience="driver" [tow]="rating.tow" [userFormattedAddress]="userFormattedAddress"></app-tow-details>
                                    </section>
                                </main>
                            </ion-content>
                        </ng-template>
                    </ion-modal>
                }
            </div>
        </ng-container>
    </ion-accordion>
</ion-accordion-group>
