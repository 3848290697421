<ion-header>
    <ion-toolbar color="secondary">
        <div slot="start" class="info">
            <div class="trailer">
                <ng-container *ngIf="trailer$ | async as trailer">
                    <i *ngIf="+trailer.icon === 7; else icon" slot="start" class="material-symbols-outlined" matPrefix>weight</i>
                    <ng-template #icon>
                        <ion-icon slot="start" [src]="'/assets/svg/trailer-' + trailer.icon + '.svg'"></ion-icon>
                    </ng-template>
                    <div class="summary">
                        <div class="nickname ellipsis"><span>{{ trailer.nickname ?? trailer.description }}</span></div>
                        <div class="ellipsis"><span>{{ trailer | trailerSummary }}</span></div>
                    </div>
                </ng-container>
            </div>
        </div>

        <ion-buttons slot="end">
            <ng-container *ngIf="markers$ | async as markers">
                <ion-button *ngIf="markers.length > 0" class="notifications open-drivers-btn" (click)="onOpenDrivers()">
                    <div class="count">{{ markers.length }}</div>
                    <img [src]="'/assets/svg/drivers.svg'">
                </ion-button>
            </ng-container>

            <ion-button *ngIf="tow$ | async" class="notifications" (click)="onShowNotifications()">
                <div class="count">{{ (activeTowRequests$ | async)?.length }}</div>
                <i class="material-symbols-outlined" matPrefix>map</i>
            </ion-button>

            <app-header-buttons></app-header-buttons>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <main>
        <section>
            <div *ngIf="!!!(trailer$ | async)" class="overlay"></div>

            <div *ngIf="!!!(trailer$ | async)" class="trailers">
                <h3>Select a Trailer/Cargo</h3>
                <ng-container *ngIf="trailers$ | async as trailers">
                    <ion-list *ngIf="trailers.length > 0; else noTrailers">
                        <ion-item *ngFor="let trailer of trailers; let last = last" button detail
                            [class.disabled]="trailerExpiredErrors.get(trailer.id + '|registrationExpDate') || trailerExpiredErrors.get(trailer.id + '|insuranceExpDate')"
                            [lines]="last ? 'none' : 'inset'" (click)="onSelectTrailer(trailer)">
                            <i slot="start" *ngIf="+trailer.icon === 7; else icon" class="material-symbols-outlined" matPrefix>weight</i>
                            <ng-template #icon>
                                <ion-icon slot="start" [src]="'/assets/svg/trailer-' + trailer.icon + '.svg'"></ion-icon>
                            </ng-template>
                            <ion-label>
                                <div class="trailer">
                                    <div>
                                        <h2 class="ellipsis"><span>{{ trailer.nickname ?? trailer.description }}</span></h2>
                                        <div class="summary">{{ trailer | trailerSummary }}</div>
                                        <div class="messages">
                                            <div class="list-error" *ngIf="trailerExpiredErrors.get(trailer.id + '|registrationExpDate')">
                                                <ion-icon name="warning" class="icon"></ion-icon> Registration expired.
                                            </div>
                                            <div class="list-error" *ngIf="trailerExpiredErrors.get(trailer.id + '|insuranceExpDate')">
                                                <ion-icon name="warning" class="icon"></ion-icon> Insurance expired.
                                            </div>
                                        </div>
                                    </div>

                                    <ion-icon *ngIf="trailersWithTowInProgress.get(trailer.id)" slot="icon-only" name="notifications" color="success"></ion-icon>
                                </div>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                    <ng-template #noTrailers>
                        <div class="no-trailers">
                            <p>You don't have any trailers/cargo yet!</p>
                            <ion-button routerLink="/trailer/payload/new">Add New</ion-button>
                        </div>
                    </ng-template>
                </ng-container>
            </div>

            <div class="inputs" [class.hidden]="hideInputs" [class.under-header]="!!!(trailer$ | async)"
                [class.in-progress]="!!(tow$ | async) && (tow$ | async)?.status !== 6" [class.completed]="(tow$ | async)?.status === 6">
                <app-trailer-map-schedule #schedule *ngIf="showSchedule" [tow]="tow$ | async" [center]="center$ | async" [shown]="scheduleShown$ | async"
                    (datetimeChanged)="onDatetimeChanged($event)" (scheduleValid)="onScheduleValid($event)" (getDrivers)="onGetDrivers($event)"
                    (setRoute)="onSetRoute($event)" (startSet)="onStartSet($event)" (setInstructions)="onSetInstructions($event)"></app-trailer-map-schedule>

                <div *ngIf="tow$ | async as tow" class="tow-in-progress" [class.completed]="tow.status === 6">
                    <div *ngIf="user$ | async as user" [ngSwitch]="tow.status" [class.rated]="tow.status === 7">
                        <ng-container *ngSwitchCase="0"> <!-- Pending -->
                            {{ tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow' }} request in progress
                            <div class="datetime">{{ tow.datetime | date:'M/d/y h:mm a' }}</div>
                            <ion-spinner name="dots"></ion-spinner>
                            <ion-button expand="block" (click)="onShowNotifications()">View Requests</ion-button>
                            <div class="link" (click)="onStartOver()">Start Over</div>
                        </ng-container>
                        <ng-container *ngSwitchDefault> <!-- Started/DriverOnWay/PickedUp/TrailerOnWay/Delivered -->
                            <app-trailer-map-tow-in-progress [tow]="tow" [chatUnreadMessagesCount]="chatUnreadMessagesCount$ | async"
                                (cancelTow)="onCancelTow()" (completeTow)="onCompleteTow()" (viewTowDetails)="onViewTowDetails()" (openChat)="onOpenChat()"></app-trailer-map-tow-in-progress>
                        </ng-container>
                        <ng-container *ngSwitchCase="6"> <!--- Completed -->
                            <app-trailer-map-tow-completed [driverDetails]="approvedTowRequestDriverDetails$ | async" [userId]="user.id" [rates]="rates$ | async"
                                (saveRatingAndTip)="onSaveRatingAndTip($event)"></app-trailer-map-tow-completed>
                        </ng-container>
                        <ng-container *ngSwitchCase="7"> <!--- Rated -->
                            <h2>{{ tow.trailer.type === 'Cargo' ? 'Cargo' : 'Tow' }} Completed</h2>
                            <ion-icon name="checkmark-circle" color="success"></ion-icon>
                            <ion-button expand="block" (click)="onNewTow()">Close</ion-button>
                        </ng-container>
                    </div>
                </div>

                <div class="distance-duration" [class.show]="showDistanceDuration">
                    <ng-container *ngIf="routeData$ | async as routeData">
                        Trip: {{ routeData.distance | number }} mi | {{ routeData.duration | duration }}
                        <button type="button" (click)="onToggleInputs()">
                            <img class="up" src="/assets/svg/up-arrow.svg">
                            <img class="down" src="/assets/svg/down-arrow.svg">
                        </button>
                    </ng-container>
                </div>
            </div>

            <ion-button class="re-center-map-btn" shape="round" type="button" fill="clear" (click)="onReCenterMap()">
                <i class="material-symbols-outlined">my_location</i>
            </ion-button>

            <div class="driver-details" [class.hidden]="hideDriverDetails" [class.no-driver-details]="noDriverDetails">
                <app-trailer-map-driver-details [driverDetails]="driverDetails$ | async" [hide]="hideDriverDetails" [towRequest]="towRequest$ | async"
                    (toggleDriverDetails)="onToggleDriverDetails()" (sendTowRequest)="onSendTowRequest($event)" (cancelTowRequest)="onCancelTowRequest($event)"
                    (viewRatings)="onViewRatings($event)"></app-trailer-map-driver-details>
            </div>

            <app-map #map *ngIf="center$ | async as center" [center]="center" [markers]="markers$ | async" [centerMarker]="pin$ | async"
                (mapLoaded)="onMapLoaded()" (routeSet)="onRouteSet($event)" (selectedMarker)="onSelectedMarker($event)"></app-map>
        </section>
    </main>
</ion-content>
