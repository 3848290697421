import { Experience } from '@custom-types/experience.type';
import { Tow } from '@models/tow.model';
import { UtilityHelper } from './utility-helper';

export class TowHelper {
    static getPaymentTipAndTotal(tow: Tow, experience: Experience): { payment: number, tip: number, total: number } {
        const towPayment = tow.payment!;
        const payment = experience === 'trailer' ? towPayment.cost : towPayment.payment;
        const driverTip = UtilityHelper.getRoundedDecimal(towPayment.tip - towPayment.tipCreditCardFee);
        const tip = experience === 'trailer' ? towPayment.tip : driverTip;
        const total = payment + tip;
        return { payment, tip, total };
    }
}
