<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Towing Safety Checklist</ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <main>
        <section>
            <ion-checkbox *ngFor="let item of checklist$ | async" (ionChange)="onCheckboxChange($event)"
                labelPlacement="end">{{ item }}</ion-checkbox>
        </section>
    </main>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-button type="button" expand="block" [disabled]="disabled$ | async" (click)="onGo()">
        Let's Go
    </ion-button>
</ion-footer>
