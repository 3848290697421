import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Experience } from '@custom-types/experience.type';
import { environment } from '@environments/environment';
import { ModalController } from '@ionic/angular';
import { Trailer } from '@models/trailer.model';

@Component({
    selector: 'app-release-of-liability',
    templateUrl: './release-of-liability.component.html',
    styleUrls: ['./release-of-liability.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleaseOfLiabilityComponent implements OnInit {
    @Input() experience!: Experience;
    @Input() cancellationFee!: number;
    @Input() trailer!: Trailer;
    @Output() accept: EventEmitter<void>;

    form!: FormGroup;
    days!: number;

    constructor(
        private formBuilder: FormBuilder,
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            accept: new FormControl<boolean>(false, Validators.requiredTrue)
        });

        this.days = this.experience === 'trailer' ?
            environment.trailerCancellationFeePeriod :
            environment.driverCancellationFeePeriod;
    }

    onAccept(): void {
        this.accept.emit();
        this.modalController.dismiss();
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
