import { ObjectHelper } from '@classes/object-helper';
import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { clearSession } from './session-store/actions';
import { State } from './state';

const INIT_ACTION = '@ngrx/store/init';
const UPDATE_ACTION = '@ngrx/store/update-reducers';

const mergeReducer = (state: State, rehydratedState: State, action: Action): State => {
    if ((action.type === INIT_ACTION || action.type === UPDATE_ACTION) && rehydratedState) {
        state = ObjectHelper.mergeObject(state, rehydratedState);

        // Set all loading properties to false
        // Note: could have gotten saved as true in the storage if a refresh took place prior the saving completed
        const keys = Object.keys(state);
        keys.forEach(key => {
            (state as any)[key].loading = false;
        });
    }

    return state;
};

function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            'allowedVehicle',
            'chat',
            'data',
            'disbursement',
            'driver',
            'driverBusyTime',
            'earning',
            'email',
            'gas-price',
            'notification',
            'payment',
            'payout',
            'rate',
            'rating',
            'session',
            'sms',
            'support',
            'towRequest',
            'tow',
            'trailer',
            'transaction',
            'user',
            'vehicle'
        ],
        rehydrate: true,
        mergeReducer,
        storage: sessionStorage
    })(reducer);
}

function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        if (action.type === clearSession.type) {
            state = undefined;
        }

        return reducer(state, action);
    };
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, clearState];
