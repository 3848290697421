<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Details</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <div class="wrapper">
        <div class="icon">
            <ion-avatar *ngIf="!displayDefaultPhoto; else defaultDriverPhoto">
                <img [src]="tow.approvedRequest?.driverDetails?.user?.photo" (error)="onPhotoError()" referrerpolicy="no-referrer">
            </ion-avatar>
            <ng-template #defaultDriverPhoto>
                <mat-icon>person</mat-icon>
            </ng-template>
            <div class="name">{{ tow.approvedRequest?.driverDetails?.user?.firstName }} {{ tow.approvedRequest?.driverDetails?.user?.lastName }}</div>
        </div>

        <div class="rating">
            <app-no-rating *ngIf="!tow.approvedRequest?.driverDetails?.user?.driver?.averageRating; else ratingComponent"></app-no-rating>
            <ng-template #ratingComponent>
                <app-rating *ngIf="tow.approvedRequest?.driverDetails?.user?.driver?.averageRating" [rating]="tow.approvedRequest?.driverDetails?.user?.driver?.averageRating ?? 0"></app-rating>
            </ng-template>
            <div class="link" (click)="onViewRatings()">{{ tow.approvedRequest?.driverDetails?.user?.driver?.averageRating | number:'1.0-2' }}</div>
        </div>

        <div class="tows">Completed Tows: <span>{{ tow.approvedRequest?.driverDetails?.user?.driver?.completedTows | number }}</span></div>

        <div class="summary" *ngIf="tow.approvedRequest?.driverDetails?.vehicle as vehicle">
            <ion-icon [src]="'/assets/svg/vehicle-' + vehicle?.icon + '.svg'"></ion-icon>
            <app-vehicle-policy class="policy" [vehicle]="vehicle"></app-vehicle-policy>
            <div>
                {{ vehicle | vehicleSummary }}<br>
                {{ vehicle.licensePlate }} {{ vehicle.licensePlateState }} -
                {{ vehicle.driveType }} {{ vehicle.engine }} {{ vehicle.engineSize }}
                <div class="twa">TWA {{ vehicle.towingCapacity | number }} lbs</div>
                {{ tow.datetime | date:'M/d/y h:mm a' }}
            </div>
            <div class="link instructions" fill="clear" *ngIf="tow.instructions" (click)="onShowInstructions()">
                Instructions
            </div>
        </div>

        <app-tow-locations [driverAddress]="(tow.driverOnWayRouteData ?? tow.approvedRequest?.commute)?.start?.address" [start]="tow.routeData.start" [destination]="tow.routeData.destination" experience="trailer"></app-tow-locations>

        <app-tow-distances-durations class="distance-duration" [distancesDurations]="distancesDurations" experience="trailer"></app-tow-distances-durations>

        <div class="cost">
            <label>Total</label>
            <div class="success">
                <span>$</span>{{ tow.approvedRequest?.driverDetails?.payment?.cost | number:'1.2-2' }}
            </div>
        </div>
    </div>
</ion-content>
